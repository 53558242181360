export const setAllProjects = (project) => {
  return {
    type: "SET_ALL_PROJECTS",
    project: project,
  };
};

export const getAllProjects = (projects) => {
  return {
    type: "GET_ALL_PROJECTS",
  };
};
