import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { validateUserJWTToken } from "./api";
import { app } from "./config/firebase.config";

import {
  Signup,
  Signin,
  MainContainer,
  Dashboard,
  Portfolio,
  ProjectDetails,
  Scanning,
  Scanning2,
  StartScanning,
  GitHubScanning,
  ContractScanning,
  ScannReport,
  DataTable,
  TopProject,
  DataTableDetails,
  ProjectPie,
  ScanningHistory,
  ProjectHealth,
  HbBar,
  ScanningTable,
  MainLoader,
  Overview,
  HoriBar,
  StartScanningExisting,
  GitHubScanningEx,
  ContractScanningEx,
  GeneratingReport,
  UserProfile,
  Modal,
  ViewAllProjects,
  IssueDetails,
  HbBarReport,
  HeadScanning,
  ProjectDetailsGroup,
  ProjectDetailsGroupSingle,
  ProjectPieGroupSingle,
  IssueDetailsGroup,
  ProjectPieSingle,
  Testing,
  Chains,
  LineChart,
  NegativeLine,
  SemiCircle,
  FullCircle,
  TeamCarousel,
  ProjectBarGraph,
  InfluencersTable,
  SecurityAuditTable,
  PackedBubble,
  TokenDueDiligence,
  UnlockInformationTable,
  LbrMarketTable,
  TokenPriceGraph,
  MarketCapChart,
  RevueGraph,
  FDVGraph,
  VolumeGraph,
  LiquidityGraph,
  TokenDueCircle,
  UpdateLiquidityGraph,
  OverallRishGraph,
  TokenContractInfo,
  StakingGraph,
  StableCoingGraph,
  ElineChart,
  TokenVestingChart,
  WalletEntanglement,
  EbarGraph,
  EnegativeBar,
  StableCoin,
  EUSDMaxSuppy,
  EUSDMintGraph,
  Staking,
  StakingPoolTable,
  LBR,
  HealthCheck,
  TokenUnlockCircle,
  StableTokenGraph,
  StableMarketCap,
  StableCoinVolume,
  ViewAllProjectAlert,
} from "./components";
import { setUserDetails } from "./context/actions/userActions";

const App = () => {
  const firebaseAuth = getAuth(app);
  const dispatch = useDispatch();

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((cred) => {
      if (cred) {
        cred.getIdToken().then((token) => {
          validateUserJWTToken(token).then((data) => {
            dispatch(setUserDetails(data));
          });
        });
      }
    });
  }, []);

  return (
    <div className="w-screen flex flex-col bg-primary">
      <main className="w-full">
        <Routes>
          <Route path="/*" element={<MainContainer />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/ProjectDetails" element={<ProjectDetails />} />
          <Route path="/Scanning" element={<Scanning />} />
          <Route path="/Scanning" element={<Scanning2 />} />
          <Route path="/StartScanning" element={<StartScanning />} />
          <Route path="/GitHubScanning" element={<GitHubScanning />} />
          <Route path="/ContractScanning" element={<ContractScanning />} />
          <Route path="/ScannReport" element={<ScannReport />} />
          <Route path="/DataTable" element={<DataTable />} />
          <Route path="/TopProject" element={<TopProject />} />
          <Route path="/DataTableDetails" element={<DataTableDetails />} />
          <Route path="/ProjectPie" element={<ProjectPie />} />
          <Route path="/ScanningHistory" element={<ScanningHistory />} />
          <Route path="/ProjectHealth" element={<ProjectHealth />} />
          <Route path="/HbBar" element={<HbBar />} />
          <Route path="/ScanningTable" element={<ScanningTable />} />
          <Route path="/MainLoader" element={<MainLoader />} />
          <Route path="/Overview" element={<Overview />} />
          <Route path="/HoriBar" element={<HoriBar />} />
          <Route path="/LineChart" element={<LineChart />} />
          <Route
            path="/StartScanningExisting"
            element={<StartScanningExisting />}
          />
          <Route path="/GitHubScanningEx" element={<GitHubScanningEx />} />
          <Route path="/ContractScanningEx" element={<ContractScanningEx />} />
          <Route path="/GeneratingReport" element={<GeneratingReport />} />
          <Route path="/UserProfile" element={<UserProfile />} />
          <Route path="/Modal" element={<Modal />} />

          <Route path="/ViewAllProjects" element={<ViewAllProjects />} />
          <Route path="/IssueDetails" element={<IssueDetails />} />
          <Route path="/HbBarReport" element={<HbBarReport />} />
          <Route path="/HeadScanning" element={<HeadScanning />} />
          <Route
            path="/ProjectPieGroupSingle"
            element={<ProjectPieGroupSingle />}
          />
          <Route
            path="/ProjectDetailsGroupSingle"
            element={<ProjectDetailsGroupSingle />}
          />
          <Route
            path="/ProjectDetailsGroup"
            element={<ProjectDetailsGroup />}
          />
          <Route path="/IssueDetailsGroup" element={<IssueDetailsGroup />} />
          <Route path="/ProjectPieSingle" element={<ProjectPieSingle />} />
          <Route path="/Testing" element={<Testing />} />
          <Route path="/Chains" element={<Chains />} />
          <Route path="/NegativeLine" element={<NegativeLine />} />
          <Route path="/SemiCircle" element={<SemiCircle />} />
          <Route path="/FullCircle" element={<FullCircle />} />
          <Route path="/TeamCarousel" element={<TeamCarousel />} />
          <Route path="/ProjectBarGraph" element={<ProjectBarGraph />} />
          <Route path="/InfluencersTable" element={<InfluencersTable />} />
          <Route path="/SecurityAuditTable" element={<SecurityAuditTable />} />
          <Route path="/PackedBubble" element={<PackedBubble />} />
          <Route path="/TokenDueDiligence" element={<TokenDueDiligence />} />
          <Route path="/LbrMarketTable" element={<LbrMarketTable />} />
          <Route path="/TokenPriceGraph" element={<TokenPriceGraph />} />
          <Route path="/MarketCapChart" element={<MarketCapChart />} />
          <Route path="/RevueGraph" element={<RevueGraph />} />
          <Route path="/FDVGraph" element={<FDVGraph />} />
          <Route path="/VolumeGraph" element={<VolumeGraph />} />
          <Route path="/LiquidityGraph" element={<LiquidityGraph />} />
          <Route path="/TokenDueCircle" element={<TokenDueCircle />} />
          <Route path="/OverallRishGraph" element={<OverallRishGraph />} />
          <Route path="/TokenContractInfo" element={<TokenContractInfo />} />
          <Route path="/StableCoingGraph" element={<StableCoingGraph />} />
          <Route path="/StakingGraph" element={<StakingGraph />} />
          <Route path="/ElineChart" element={<ElineChart />} />
          <Route path="/TokenVestingChart" element={<TokenVestingChart />} />
          <Route path="/WalletEntanglement" element={<WalletEntanglement />} />
          <Route path="/EbarGraph" element={<EbarGraph />} />
          <Route path="/EnegativeBar" element={<EnegativeBar />} />
          <Route path="/StableCoin" element={<StableCoin />} />
          <Route path="/EUSDMaxSuppy" element={<EUSDMaxSuppy />} />
          <Route path="/EUSDMintGraph" element={<EUSDMintGraph />} />
          <Route path="/Staking" element={<Staking />} />
          <Route path="/StakingPoolTable" element={<StakingPoolTable />} />
          <Route path="/HealthCheck" element={<HealthCheck />} />
          <Route path="/LBR" element={<LBR />} />
          <Route path="/StableMarketCap" element={<StableMarketCap />} />
          <Route path="/StableTokenGraph" element={<StableTokenGraph />} />
          <Route path="/StableCoinVolume" element={<StableCoinVolume />} />
          <Route path="/TokenUnlockCircle" element={<TokenUnlockCircle />} />
          <Route
            path="/ViewAllProjectAlert"
            element={<ViewAllProjectAlert />}
          />
          <Route
            path="/UpdateLiquidityGraph"
            element={<UpdateLiquidityGraph />}
          />
          <Route
            path="/UnlockInformationTable"
            element={<UnlockInformationTable />}
          />
        </Routes>
      </main>
    </div>
  );
};

export default App;
