import React, { useState, useEffect } from "react";
import { MdKeyboardArrowRight, MdInfo } from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainLoader from "./MainLoader";
import Footer from "./Footer";
import privacy from "../img/privacy_tip.png";
import invest from "../img/invest@2x.png";
import ethereum from "../img/eth.png";
import Staking from "../img/Staking@2x.png";
import StableCoin from "../img/StableCoin@2x.png";
import alarm from "../img/alarm.png";
import twitter from "../img/twitter@2x-1.png";
import copy from "../img/copy-grey.png";
import Etherscan from "../img/etherscan@2x.png";
import website from "../img/web@2x.png";
import LineChart from "../charts/LineChart";
import Header from "./Header";
import SemiCircle from "../charts/SemiCircle";
import FullCircle from "../charts/FullCircle";
import TeamCarousel from "./TeamCarousel";
import ProjectBarGraph from "../charts/ProjectBarGraph";
import InfluencersTable from "./InfluencersTable";
import SecurityAuditTable from "./SecurityAuditTable";
import MarketCapChart from "../charts/MarketCapChart";
import RevueGraph from "../charts/RevueGraph";
import FDVGraph from "../charts/FDVGraph";
import VolumeGraph from "../charts/VolumeGraph";
import LiquidityGraph from "../charts/LiquidityGraph";
import TokenDueCircle from "../charts/TokenDueCircle";
import UpdateLiquidityGraph from "../charts/UpdateLiquidityGraph";
import StakingGraph from "../charts/StakingGraph";
import StableCoingGraph from "../charts/StableCoingGraph";
import { Tooltip } from "react-tooltip";
import ElineChart from "../charts/ElineChart";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  critical: `critical`,
  moderate: `moderate`,
  minor: `minor`,
  informational: `informational`,
  healthoptimization: `health-Opimization`,
};

const ProjectDetails = () => {
  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [stepLine, setStepLine] = useState(1);
  const [coinDetails, setCoinDetails] = useState([]);
  const [marketCap, setMarketcap] = useState();
  const [tokenPrice, setTokenPrice] = useState([]);
  const [marketCapRise, setMarketCapRise] = useState([]);
  const [feeGraphData, setfeeGraphData] = useState([]);
  const [revenueGraphData, setRenueGraphData] = useState([]);
  const [tokenGraphData, setTokenGraphData] = useState([]);
  const [marketCapGraph, setMarketCapGraph] = useState([]);
  const [totalVolume, setTotalVolume] = useState([]);
  const [maxSupply, setMaxSupply] = useState();
  const [circulatingSupply, setCirculatingSupply] = useState();
  const [coinCoinDetails, setCoinProjectDetails] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [liquidity, setLiquidity] = useState([]);
  const [links, setLinks] = useState([]);
  const [tokenDough, setTokenDough] = useState([]);
  const [issueValue, setIssueValue] = useState([]);
  const [stalkingGraph, setStakingGraph] = useState([]);
  const [issueStalking, setIssueStalking] = useState([]);
  const [stableCoinGraph, setStableCoinGraph] = useState([]);
  const [issueStableCoin, setIssueStableCoin] = useState([]);
  const [news, setNews] = useState([]);
  const [site, setSite] = useState([]);
  const [latestNewsUpdate, setLatestNewsUpdate] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const myEmail = sessionStorage.getItem("email");

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
    fetchTokenData(myEmail);
    fetchNewsData(myEmail);
    fetchLatestNewsData(myEmail);
    fetchSecurityData(myEmail);
  }, []);

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("getHeaderForProject", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        console.log("**Header**");
        console.log(res.data);
        setCoinProjectDetails(res.data);
        setCoinDetails(res.data.COINGECKO);
        setProjectName(res.data.COINGECKO.id);
        setMarketcap(res.data.MARKET_CAP);
        setTokenPrice(res.data.COINGECKO.market_data.current_price.usd);
        setCirculatingSupply(res.data.COINGECKO.market_data.circulating_supply);
        setMarketCapRise(res.data.COINGECKO.market_data.price_change_24h);
        setMaxSupply(res.data.COINGECKO.market_data.max_supply);
        setfeeGraphData(res.data.FEEGRAPH.totalDataChart);
        setTokenGraphData(res.data.MARKET_VOL_PRICE_GRAPH.prices);
        setMarketCapGraph(res.data.MARKET_VOL_PRICE_GRAPH.market_caps);
        setRenueGraphData(res.data.REVENUEGRAPH.totalDataChart);
        setTotalVolume(res.data.MARKET_VOL_PRICE_GRAPH.total_volumes);
        setLiquidity(res.data.TVL_GRAPH_DATA);
        setLinks(res.data.COINGECKO.links.homepage);
        setSite(res.data.COINGECKO.links.blockchain_site);
      });
  };

  const fetchTokenData = async (myEmail) => {
    axios
      .get("PROJECT_DESCRIPTION_GROUP", {
        params: {
          USEID: "pritomabhijit@gmail.com",
          PROJECT_ID: "320230810225017",
        },
      })
      .then((res) => {
        // console.log("Project Description group");
        // console.log(res.data);
        setTokenDough(res.data.TOKEN_DATA.BAR_VALUES_PROF);
        setIssueValue(res.data.TOKEN_DATA.BAR_VALUES);
        setStakingGraph(res.data.STAKING_DATA.BAR_VALUES_PROF);
        setIssueStalking(res.data.STAKING_DATA.BAR_VALUES);
        setStableCoinGraph(res.data.STABLECOIN_DATA.BAR_VALUES_PROF);
        setIssueStableCoin(res.data.STABLECOIN_DATA.BAR_VALUES);
      });
  };

  const fetchNewsData = async (myEmail) => {
    axios
      .get("getLastestSpotonchain", {
        params: {
          PAGE_NUMBER: "20",
        },
      })
      .then((res) => {
        // console.log("From news page");
        // console.log(res.data);
        setNews(res.data);
      });
  };

  const fetchLatestNewsData = async (myEmail) => {
    axios
      .get("getCoinNewsData", {
        params: {
          COIN_NAME: "web3 and crypto",
        },
      })
      .then((res) => {
        console.log("From Latest news page");
        console.log(res.data);
        setLatestNewsUpdate(res.data.results);
      });
  };

  const fetchSecurityData = async (myEmail) => {
    axios
      .get("getAuditReport", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("From Audit page");
        // console.log(res.data);
        setAuditData(res.data);
        setIsLoading(false);
      });
  };

  function ratingOverview() {
    setStep(1);
  }
  function projectInfo() {
    setStep(2);
  }
  function latestNews() {
    setStep(3);
  }

  function audit() {
    setStep(4);
  }

  function updateTokenPrice() {
    setStepLine(1);
  }

  function updateMarketCap() {
    setStepLine(2);
  }

  function updateFdv() {
    setStepLine(3);
  }

  function updateVolume() {
    setStepLine(4);
  }

  function updateLiquidity() {
    setStepLine(5);
  }

  function updateFee() {
    setStepLine(6);
  }

  function updateRevenue() {
    setStepLine(7);
  }

  function updateTvl() {
    setStepLine(8);
  }

  function convertTimestampToMonthAndYear(timestamp) {
    const dateObject = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    // Get the month as a string (e.g., "January", "February", etc.)
    const month = dateObject.toLocaleString("default", { month: "long" });

    // Get the year as a 4-digit number
    const year = dateObject.getFullYear();

    // Format the result as "Month Year" (e.g., "August 2023")
    const formattedDate = `${month} ${year}`;

    return formattedDate;
  }

  function convertStringToObject(inputString) {
    try {
      return JSON.parse(inputString);
    } catch (error) {
      console.error("Error converting string to object:", error);
      return null; // Return null in case of an error
    }
  }

  function formatDate(inputDate) {
    // Split the input date into date and time parts
    const [datePart, timePart] = inputDate.split(" ");

    // Split the date part into year, month, and day
    const [year, month, day] = datePart.split("-");

    // Convert month number to month name
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[parseInt(month) - 1];

    // Create the formatted date string
    const formattedDate = `${day} ${monthName} ${year}`;

    return formattedDate;
  }

  function formatNumberWithCommas(number) {
    const parts = number.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const handleModalClick = async (email, projectName) => {
    const myEmail = email;
    const id = projectName;

    setButtonDisabled(true);
    toast("Project added to your portfolio successfully.", {
      position: toast.POSITION.BOTTOM_CENTER,
      // autoClose: 10000,
      theme: "colored",
      toastClassName: "custom-toast",
    });
    await axios
      .get("updateUserProfile", {
        params: {
          USEID: myEmail,
          PROJECTID: id,
        },
      })
      .then((res) => {
        console.log(res);
        setButtonDisabled(false);
      });
  };

  return (
    <div>
      <Header />

      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          Project Dashboard
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">
            {coinDetails.name !== undefined ? coinDetails.name : ""}
          </span>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-full rounded-lg">
          <div className="mb-5 ml-5 flex">
            <p className="project-details-summary">Project Summary</p>
            <div className="flex flex-1 justify-end project-details-title-new cursor-pointer">
              {isButtonDisabled === false ? (
                <p
                  className="mr-5 font-extrabold cursor-pointer text-sky-400"
                  onClick={() => handleModalClick(myEmail, projectName)}
                >
                  Add to portfolio
                </p>
              ) : (
                <p className="mr-5 font-extrabold text-gray-400">
                  Add to portfolio
                  <ToastContainer limit={1} />
                </p>
              )}
            </div>
          </div>

          <div className="project-details-border"></div>

          <div className="ml-5 project-small-title">
            <p> Project Name</p>
          </div>

          <div className="ml-5 flex mb-5">
            <div className="flex gap-2 project-details-title">
              <img
                src={
                  coinDetails.image?.large !== undefined
                    ? coinDetails.image?.large
                    : ""
                }
                alt=""
                className="w-8"
              />
              {coinDetails.name !== undefined ? coinDetails.name : ""}
            </div>
            <span className="projectlbr flex">
              {coinDetails.symbol && coinDetails.symbol !== undefined
                ? coinDetails.symbol.toUpperCase()
                : ""}
            </span>

            <div className="flex flex-1 justify-end project-details-supported-chain">
              Supported Chain
            </div>
          </div>

          <div className="flex">
            <div className="projectlsd">LSD</div>
            <div className="flex flex-1 justify-end">
              <img src={ethereum} alt="" className="chain-eth -mt-5" />
            </div>
          </div>

          <div className="flex gap-4 mt-10 pl-5">
            <div className="w-1/3">
              <div className="token-price">Token Price</div>
              <div className="flex">
                <div className="flex token-price-value">
                  ${tokenPrice && tokenPrice}
                </div>

                {marketCapRise && marketCapRise > 0 ? (
                  <p className="token-rising-value">
                    +{marketCapRise * 100 && (marketCapRise * 100).toFixed(2)}%
                  </p>
                ) : (
                  <p className="token-down-value">
                    {marketCapRise &&
                      marketCapRise &&
                      (marketCapRise * 100).toFixed(2)}
                    %
                  </p>
                )}
              </div>

              <div>
                <div className="flex mt-3">
                  <div className="w-1/2 flex">
                    <div className="market-cap"></div>
                    <div className="ml-2 token-price">Market Cap</div>
                  </div>
                  <div className="w-1/2">
                    ${marketCap && formatNumberWithCommas(marketCap)}
                  </div>
                </div>

                <div className="flex mt-3">
                  <div className="w-1/2 flex">
                    <div className="fully-diluted"></div>
                    <div className="ml-2 token-price">
                      Fully Diluted Valuation
                    </div>
                  </div>
                  <div className="w-1/2">
                    $
                    {coinDetails?.market_data?.fully_diluted_valuation?.usd &&
                      formatNumberWithCommas(
                        coinDetails?.market_data?.fully_diluted_valuation?.usd
                      )}
                  </div>
                </div>

                <div className="flex mt-3">
                  <div className="w-1/2 flex">
                    <div className="trading-volume"></div>
                    <div className="ml-2 token-price">24h Trading Volume</div>
                  </div>
                  <div className="w-1/2">
                    $
                    {coinDetails?.market_data?.total_volume.usd &&
                      formatNumberWithCommas(
                        coinDetails?.market_data?.total_volume.usd
                      )}
                  </div>
                </div>

                <div className="flex mt-3">
                  <div className="w-1/2 flex">
                    <div className="lbr"></div>
                    <div className="ml-2 token-price">LBR Liquidity</div>
                  </div>
                  <div className="w-1/2">
                    $ {formatNumberWithCommas(circulatingSupply * tokenPrice)}
                  </div>
                </div>

                <div className="flex mt-3">
                  <div className="w-1/2 flex">
                    <div className="fee"></div>
                    <div className="ml-2 token-price flex">
                      Fees (annualized){" "}
                      <MdInfo
                        className="mt-1 ml-2"
                        title="Hello"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Text will come here"
                      />
                    </div>
                    <Tooltip id="my-tooltip" />
                  </div>
                  <div className="flex">
                    ${" "}
                    {coinCoinDetails.FEE30 &&
                      formatNumberWithCommas(coinCoinDetails.FEE30)}
                  </div>
                </div>

                {/* <div className="flex mt-3">
                  <div className="w-1/2 flex">
                    <div className="revenue"></div>
                    <div className="ml-2 token-price flex">
                      Revenue (annualized){" "}
                      <MdInfo
                        className="mt-1 ml-2"
                        title="Hello"
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Text will come here"
                      />
                    </div>
                    <Tooltip id="tooltip" />
                  </div>
                  <div className="w-1/2">
                    ${" "}
                    {coinCoinDetails.REVENUE30 &&
                      formatNumberWithCommas(coinCoinDetails.REVENUE30)}
                  </div>
                </div> */}
              </div>
            </div>

            <div className="w-2/3">
              <div className="flex w-full">
                <div className="flex ml-8" onClick={updateTokenPrice}>
                  <div
                    className={
                      stepLine === 1
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 1
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    Token Price
                  </div>
                </div>

                <div className="flex ml-3" onClick={updateMarketCap}>
                  <div
                    className={
                      stepLine === 2
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 2
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    Mcap
                  </div>
                </div>

                <div className="flex ml-3" onClick={updateFdv}>
                  <div
                    className={
                      stepLine === 3
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 3
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    FDV
                  </div>
                </div>

                <div className="flex ml-3" onClick={updateVolume}>
                  <div
                    className={
                      stepLine === 4
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 4
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    24h Volume
                  </div>
                </div>

                {/* <div className="flex ml-3" onClick={updateLiquidity}>
                  <div
                    className={
                      stepLine === 5
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 5
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    TVL
                  </div>
                </div> */}

                <div className="flex ml-3" onClick={updateTvl}>
                  <div
                    className={
                      stepLine === 8
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 8
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    LBR Liquidity
                  </div>
                </div>

                {/* <div className="flex ml-3" onClick={updateFee}>
                  <div
                    className={
                      stepLine === 6
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 6
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    Fees
                  </div>
                </div> */}

                <div className="flex ml-3" onClick={updateRevenue}>
                  <div
                    className={
                      stepLine === 7
                        ? "flex token-active"
                        : "flex token-inactive"
                    }
                  ></div>
                  <div
                    className={
                      stepLine === 7
                        ? "line-token-name-active cursor-pointer"
                        : "line-token-name cursor-pointer"
                    }
                  >
                    Revenus
                  </div>
                </div>

                <div className="flex ml-3">
                  <div className="flex-1 line-token-name">Daily</div>
                </div>

                <div className="flex ml-3">
                  <div className="flex-1 line-token-name">Weekly</div>
                </div>

                <div className="flex ml-3">
                  <div className="flex-1 line-token-name">Monthly</div>
                </div>
              </div>

              <div className={`${stepLine === 1 ? "block" : "hidden"} `}>
                {tokenGraphData.length === 0 ? (
                  ""
                ) : (
                  <ElineChart TokenGraph={tokenGraphData} />
                )}
              </div>

              <div className={`${stepLine === 2 ? "block" : "hidden"}`}>
                {marketCapGraph.length === 0 ? (
                  ""
                ) : (
                  <MarketCapChart MarketCapGraph={marketCapGraph} />
                )}
              </div>

              <div className={`${stepLine === 3 ? "block" : "hidden"}`}>
                {tokenGraphData.length === 0 ? (
                  ""
                ) : (
                  <FDVGraph
                    Revenue={tokenGraphData === undefined ? [] : tokenGraphData}
                    maxSupply={maxSupply === undefined ? 100000000 : maxSupply}
                  />
                )}
              </div>

              <div className={`${stepLine === 4 ? "block" : "hidden"}`}>
                {totalVolume.length === 0 ? (
                  ""
                ) : (
                  <VolumeGraph
                    VolumeGraph={totalVolume === undefined ? [] : totalVolume}
                  />
                )}
              </div>

              {/* <div className={`${stepLine === 5 ? "block" : "hidden"}`}>
                  {liquidity.length === 0 ? (
                    ""
                  ) : (
                    <LiquidityGraph
                      Liquidity={liquidity === undefined ? [] : liquidity}
                    />
                  )}
                </div> */}

              {/* <div className={`${stepLine === 6 ? "block" : "hidden"}`}>
                  {feeGraphData.length === 0 ? (
                    ""
                  ) : (
                    <LineChart
                      FeeGraph={feeGraphData === null ? [] : feeGraphData}
                    />
                  )}
                </div> */}

              <div className={`${stepLine === 7 ? "block" : "hidden"}`}>
                {revenueGraphData.length === 0 ? (
                  ""
                ) : (
                  <RevueGraph
                    Revenue={revenueGraphData === null ? [] : revenueGraphData}
                  />
                )}
              </div>

              <div className={`${stepLine === 8 ? "block" : "hidden"}`}>
                {tokenGraphData.length === 0 ? (
                  ""
                ) : (
                  <UpdateLiquidityGraph
                    Revenue={tokenGraphData === undefined ? [] : tokenGraphData}
                    circulating={
                      circulatingSupply === undefined
                        ? 100000000
                        : circulatingSupply
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* menu */}
      <div className="flex gap-10 pl-20 mr-10 drop-shadow-md">
        <ul className="flex items-center gap-8">
          <li
            className={
              step === 1 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={ratingOverview}
          >
            <Link to={""}>Rating Overview</Link>
            <div className={step === 1 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 2 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={projectInfo}
          >
            <Link to={""}>Project Info</Link>
            <div className={step === 2 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 3 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={latestNews}
          >
            <Link to={""}>Latest News</Link>
            <div className={step === 3 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 4 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={audit}
          >
            <Link to={""}>Security Audit Report</Link>
            <div className={step === 4 ? "active" : ""}></div>
          </li>
        </ul>
      </div>
      {/* end of menu */}

      <div className={`${step === 1 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Project Safety Level</p>
            </div>

            <div className="project-details-border"></div>

            <div className="flex gap-4 mt-10 -ml-28">
              <div className="w-1/3">
                <SemiCircle />
              </div>

              <div className="w-2/3">
                <div className="w-full">
                  <div className="flex gap-2">
                    <div className="flex">
                      <img src={privacy} alt="" />
                    </div>
                    <div className="privacy-title">
                      No team profile available
                    </div>
                  </div>

                  <div className="flex gap-2 mt-2">
                    <div className="flex">
                      <img src={privacy} alt="" />
                    </div>
                    <div className="privacy-title">
                      The project's code repository has zero or almost no
                      commits.
                    </div>
                  </div>

                  <div className="flex gap-2 mt-2">
                    <div className="flex">
                      <img src={privacy} alt="" />
                    </div>
                    <div className="privacy-title">
                      There is a very low amount of new search results (in last
                      28days) for this project.
                    </div>
                  </div>

                  <div className="flex gap-2 mt-2">
                    <div className="flex">
                      <img src={privacy} alt="" />
                    </div>
                    <div className="privacy-title">
                      This project is new, it was released on which is less than
                      three months old.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of 2nd section */}

        {/* 3rd section */}
        <div className="flex">
          <div className="w-1/2 bg-white px-2 py-4 rounded-lg drop-shadow-md pl-10 ml-20">
            <div className="flex gap-4">
              <img src={invest} alt="" className="w-12" />
              <div className="invest-name">
                <Link to={"/TokenDueDiligence"}>
                  Invest: Token Due Diligence
                </Link>
              </div>
              <div className="invest-critical">Critical</div>
            </div>
            <div className="invest-desc">
              Latest smart contract security report with real-time token metrics{" "}
              <br />
              vesting and market information
            </div>

            <div className="flex mt-5">
              <div className="w-1/2">
                <TokenDueCircle issueD={tokenDough == null ? [] : tokenDough} />
              </div>
              <div className="w-1/2">
                {issueValue.map((item, index) => (
                  <div className="flex gap-2 mt-3" key={index}>
                    <div
                      className={`${
                        item.TITLE === "High"
                          ? style.critical
                          : item.TITLE === "Medium"
                          ? style.moderate
                          : item.TITLE === "Low"
                          ? style.minor
                          : item.TITLE === "Informational"
                          ? style.informational
                          : ""
                      }`}
                    ></div>
                    <div className="critical-title">
                      {item.TITLE === "High"
                        ? "Critical"
                        : item.TITLE === "Medium"
                        ? "Moderate"
                        : item.TITLE === "Low"
                        ? "Minor"
                        : item.TITLE === "Informational"
                        ? "Informational"
                        : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-1/2 bg-white px-2 py-4 rounded-lg drop-shadow-md mr-20 ml-5">
            <div className="flex gap-4">
              <img src={Staking} alt="" className="w-12" />
              <div className="invest-name">
                <Link to={"/Staking"}>Staking</Link>
              </div>
              <div className="invest-critical">Critical</div>
            </div>
            <div className="invest-desc">
              Latest staking pool information and smart contract security report
            </div>

            <div className="flex mt-5">
              <div className="w-1/2">
                <StakingGraph
                  stalking={stalkingGraph == null ? [] : stalkingGraph}
                />
              </div>
              <div className="w-1/2">
                {issueStalking.map((item, index) => (
                  <div className="flex gap-2 mt-3" key={index}>
                    <div
                      className={`${
                        item.TITLE === "High"
                          ? style.critical
                          : item.TITLE === "Medium"
                          ? style.moderate
                          : item.TITLE === "Low"
                          ? style.minor
                          : item.TITLE === "Informational"
                          ? style.informational
                          : ""
                      }`}
                    ></div>
                    <div className="critical-title">
                      {item.TITLE === "High"
                        ? "Critical"
                        : item.TITLE === "Medium"
                        ? "Moderate"
                        : item.TITLE === "Low"
                        ? "Minor"
                        : item.TITLE === "Informational"
                        ? "Informational"
                        : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* end of 3rd section */}

        {/* 4th section */}
        <div className="flex mt-5">
          <div className="w-1/2 bg-white px-2 py-4 rounded-lg drop-shadow-md pl-10 ml-20">
            <div className="flex gap-4">
              <img src={StableCoin} alt="" className="w-12" />
              <div className="invest-name">
                <Link to={"/StableCoin"}>Stablecoin</Link>
              </div>
              <div className="invest-critical">Critical</div>
            </div>
            <div className="invest-desc">
              Real-time stablecoin metrics and health status with the latest
              smart contract security report
            </div>

            <div className="flex mt-5">
              <div className="w-1/2">
                <StableCoingGraph
                  stableCoin={stableCoinGraph == null ? [] : stableCoinGraph}
                />
              </div>
              <div className="w-1/2">
                {issueStableCoin.map((item, index) => (
                  <div className="flex gap-2 mt-3" key={index}>
                    <div
                      className={`${
                        item.TITLE === "High"
                          ? style.critical
                          : item.TITLE === "Medium"
                          ? style.moderate
                          : item.TITLE === "Low"
                          ? style.minor
                          : item.TITLE === "Informational"
                          ? style.informational
                          : ""
                      }`}
                    ></div>
                    <div className="critical-title">
                      {item.TITLE === "High"
                        ? "Critical"
                        : item.TITLE === "Medium"
                        ? "Moderate"
                        : item.TITLE === "Low"
                        ? "Minor"
                        : item.TITLE === "Informational"
                        ? "Informational"
                        : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-1/2 bg-white px-2 py-4 rounded-lg drop-shadow-md mr-20 ml-5"></div>
        </div>
        {/* end of 4th section */}

        {/* 5th section */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Project Alert</p>
              <div className="flex flex-1 justify-end project-details-title-new cursor-pointer">
                <img src={alarm} alt="" />
                <Link to={"/ViewAllProjectAlert"}>View All Alert</Link>
              </div>
            </div>
            <div className="project-details-border"></div>

            {/* news start from here */}

            {Object.keys(news).map((key, index) => (
              <div className="flex mb-10" key={index}>
                <div className="w-40">
                  <p className="project-alert-date">
                    {news[key].publish_time &&
                      // new Date(news[key].update_time).getFullYear()}
                      convertTimestampToMonthAndYear(news[key].publish_time)}
                    {/* 18 JUN 2023 */}
                  </p>
                </div>
                <div className="vertifcal-line"></div>
                <div className="w-full">
                  <div className="pl-10">
                    <div className="project-alert-title">
                      {news[key].title && news[key].title}
                    </div>
                    <div className="project-alert-desc">
                      {/* {Object(news[key].content).map((keyy, indexx) => (
                        <div key={indexx}>
                          <p>{keyy}</p>
                        </div>
                      ))} */}

                      {/* {convertStringToObject(news[key].content).map(
                        (itemss) => (
                          <p>{itemss.children.map((hello) => hello.text)}</p>
                        )
                      )} */}

                      {convertStringToObject(news[key].description).map(
                        (itemss) => (
                          <p>{itemss.children.map((hello) => hello.text)}</p>
                        )
                      )}

                      {/* {JSON.stringify(news[key].content)} */}
                    </div>

                    <div className="project-alert-highlight w-full">
                      <div className="flex mt-2">
                        <div className="flex flex-1 project-alert-highlight-title">
                          Highlight
                        </div>
                        <div className="project-alert-datedetails">
                          {/* 18 JUN 2023 10:00AM UTC */}
                          {news[key].update_time &&
                            convertTimestampToMonthAndYear(
                              news[key].update_time
                            )}
                        </div>
                      </div>

                      <div>
                        <div className="mt-5">
                          <span className="project-alert-purchased">
                            {news[key].txn_data_details.title_prefix
                              ? news[key].txn_data_details.title_prefix
                              : ""}
                          </span>
                          <span className="project-alert-dollar">
                            {news[key].txn_data_details.title
                              ? news[key].txn_data_details.title
                              : ""}
                          </span>
                        </div>
                      </div>

                      <div className="project-alert-total-token">
                        Total token Transfter
                      </div>

                      <div className="flex mt-2 gap-10">
                        {/* {news[key].txn_data_details.token_amounts.length} */}
                        {news[key].txn_data_details.token_amounts.length >= 1
                          ? news[key].txn_data_details.token_amounts.map(
                              (items) => (
                                <div className="flex">
                                  <img
                                    src={items.url}
                                    alt=""
                                    className="w-5 h-5 flex"
                                  />
                                  <p className="project-alert-project-name-dollar flex">
                                    {items.amount}
                                    <span className="project-alert-lbr ml-2">
                                      {items.token}
                                    </span>
                                  </p>
                                </div>
                              )
                            )
                          : "Can not iterate"}
                      </div>
                    </div>

                    {news[key].tags.map((newsItm) => (
                      <div className="flex">
                        <div className="flex project-alert-invest">
                          <p className="flex flex-1">{newsItm.tag}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            {/* end of one news */}
          </div>
        </div>
      </div>
      {/* end of 5th section */}

      {/* Project Info */}
      <div className={`${step === 2 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Information</p>
            </div>

            <div className="project-details-border"></div>

            <div className="flex mt-5 ml-5">
              <div className="w-1/2">
                <div className="project-info-title">Protocol Information</div>
                <p className="project-info-desc">
                  {coinDetails.description?.en}
                </p>

                <div className="project-info-category">
                  Category:{" "}
                  <span className="project-infor-name-category">
                    {coinDetails.categories?.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </span>
                </div>

                <div className="project-info-category">
                  Audits:
                  <span className="project-infor-name-category">
                    Security Audit Database
                  </span>
                </div>

                <div className="project-info-category flex">
                  <img src={twitter} alt="" className="flex w-5" />
                  <span className="project-infor-name-category">
                    <Link>Lybra Finance</Link>
                  </span>

                  <img src={Etherscan} alt="" className="flex w-5 ml-10" />
                  <span className="project-infor-name-category">
                    <Link to={site[0]}>Lybra Finance Token</Link>
                  </span>

                  <img src={Etherscan} alt="" className="flex w-5 ml-10" />
                  <span className="project-infor-name-category">
                    <Link to={site[1]}>Lybra Finance Address</Link>
                  </span>
                </div>

                <div className="project-info-category flex">
                  <img src={website} alt="" className="flex w-5" />
                  <span className="project-infor-name-category">
                    <Link to={links[0]}>Website</Link>
                  </span>

                  {/* <img src={whitepaper} alt="" className="flex w-5 ml-20" />
                  <span className="project-infor-name-category">
                    <Link>Whitepaper</Link>
                  </span> */}
                </div>
              </div>

              <div className="w-1/2">
                <div className="project-info-title">Token Information</div>
                <p className="flex">
                  <span className="project-info-address flex">Address:</span>
                  <span className="project-info-details-address">
                    {coinDetails.contract_address}
                  </span>
                  <Link className="ml-2">
                    <img src={copy} alt="" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Team Background</p>
            </div>
            <div className="project-details-border"></div>
            <div>
              <TeamCarousel />
            </div>
          </div>
        </div>

        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">
                Token Liquidity Analysis
              </p>
            </div>
            <div className="project-details-border"></div>

            <div className="flex mt-5">
              <div className="w-1/4">
                <div>
                  <FullCircle />
                </div>
              </div>
              <div className="w-3/4">
                <p className="project-info-liquidity">
                  Total Liquidity : 419.0 K
                </p>

                <div className="flex gap-2 mt-10">
                  <div className="moderate flex"></div>
                  <div className="critical-title">Token Invest</div>
                  <div className="project-info-token-percentage ml-20">40%</div>
                </div>

                <div className="flex gap-2 mt-4">
                  <div className="minor flex"></div>
                  <div className="critical-title">Token Stake</div>
                  <div className="project-info-token-percentage ml-20">40%</div>
                </div>

                <div className="flex gap-2 mt-4">
                  <div className="token-trade flex"></div>
                  <div className="critical-title">Token Trade</div>
                  <div className="project-info-token-percentage ml-20">40%</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Funding History</p>
            </div>
            <div className="project-details-border"></div>

            <div className="mt-5 bar-graph-container">
              <ProjectBarGraph />
            </div>
          </div>
        </div>

        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">
                Top Lybra Finance Influencers
              </p>
            </div>
            <div className="project-details-border"></div>
            <InfluencersTable />
          </div>
        </div>

        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Project Milestones</p>
            </div>
            <div className="project-details-border"></div>
            <div className="w-full">
              <p className="milestone-title">Q1 2023 Milestone</p>
              <div className="grid grid-cols-3">
                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Open source contracts for review</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Community Growth</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Testnet on zkSync the bring the Hype</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>
                    Social Media Establishment and looking for Whale adoption
                  </div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Audit</div>
                </div>
              </div>
            </div>

            <div className="w-full mt-10">
              <p className="milestone-title">Q2 2023 Milestone</p>
              <div className="grid grid-cols-3">
                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>LBR Public Sale</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Live on Ethereum</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Community Call</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Collaborate with a reputable audit firm</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Audit</div>
                </div>
              </div>
            </div>

            <div className="w-full mt-10">
              <p className="milestone-title">Q3 2023 Milestone</p>
              <div className="grid grid-cols-3">
                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input
                      type="radio"
                      checked="checked"
                      value=""
                      className="mt-1"
                    />
                  </div>
                  <div>Multi-sig Safe</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>Layer Zero</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>Live on Arbitrum</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>Lending Protocols</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>Omnichain</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>Explore DeFi Strategy</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>Live on Arbitrum</div>
                </div>

                <div className="flex gap-2 ml-5 mt-5">
                  <div>
                    <input type="radio" selected value="" className="mt-1" />
                  </div>
                  <div>
                    Develop more functions according to Lybra DAO community
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of Project info */}

      {/* Latest news */}
      <div className={`${step === 3 ? "block" : "hidden"}`}>
        <div className="gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5">
              <p className="project-details-summary">Latest News</p>
            </div>
            <div className="project-details-border"></div>

            {/* news start from here */}

            {latestNewsUpdate &&
              latestNewsUpdate?.map((itm) => (
                <div className="flex mb-10">
                  <div style={{ width: "15%" }} className="flex">
                    <p className="project-alert-date mt-10">
                      {formatDate(itm.pubDate)}
                    </p>
                    <div className="flex vertifcal-line"></div>
                  </div>

                  <div className="flex" style={{ width: "60%" }}>
                    <div>
                      <p className="latest-news-title">{itm.title}</p>
                      <p className="latest-news-desc">{itm.description}</p>

                      <div className="flex mt-2">
                        {itm.keywords?.map((indx, ky) => (
                          <p className="project-alert-project-name-dollar flex">
                            {indx}
                            <span className="project-alert-lbr ml-3"></span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div style={{ width: "30%" }} className="flex">
                    <div>
                      <img
                        src={
                          itm.image_url && itm.image_url !== null
                            ? itm.image_url
                            : ""
                        }
                        alt=""
                        style={{ width: "350px", borderRadius: "20px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}

            {/* end of one news */}
          </div>
        </div>
      </div>
      {/* end of Latest news */}

      {/* Security Audit */}
      <div className={`${step === 4 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className="bg-white px-2 py-4 w-full rounded-lg">
            <SecurityAuditTable
              AuditData={auditData === null ? [] : auditData}
            />
          </div>
        </div>
      </div>
      {/* end of security audit */}

      <Footer />
    </div>
  );
};

export default ProjectDetails;
