import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { motion } from "framer-motion";
import { organizations, role } from "../utlis/data";
import { saveUsers } from "../utlis/firebaseFunctions";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { app } from "../config/firebase.config";
import Logo from "../img/logo.png";
import Logof from "../img/logof.png";
import { async } from "@firebase/util";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [fields, setField] = useState(false);
  const [roles, setRole] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);

  const saveDetails = () => {
    try {
      const data = {
        id: `${Date.now()}`,
        name: name,
        email: email,
        password: password,
        roles: roles,
        organization: organization,
      };
      saveUsers(data);

      setField(true);
      setMsg("Succefully sign up");
      setAlertStatus("success");
      setTimeout(() => {
        setField(false);
        // clearData();
      }, 4000);
    } catch (error) {
      console.log(error);

      setField(true);
      setMsg("Error saving data");
      setAlertStatus("danger");
      setTimeout(() => {
        setField(false);
      }, 4000);
    }
  };

  // const clearData = () => {
  //   setName(""),
  //     setEmail(""),
  //     setPassword(""),
  //     setOrganization("Select Organization"),
  //     setRole("Select Role");
  // };

  // const signUowithEmail = async () => {
  //   if (name === "" || email === "" || password === "") {
  //     //alert function
  //   } else {
  //     if (password === confirm_password) {
  //       await createUserWithEmailAndPassword(
  //         firebaseAuth,
  //         name,
  //         email,
  //         password
  //       );

  //       // console.log("Equal");
  //     } else {
  //       //alert function
  //     }
  //   }
  // };

  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full" id="home">
      <div className="py-2 flex-1 flex flex-col items-start bg-gradient-to-r from-purple-900 to-neutral-900">
        <img
          src={Logo}
          className="py-10 flex items-center justify-center relative left-52"
          alt="logo"
        />
        <p className="text-lg text-white py-10 ml-28">
          Experience in Scanning Web3 Smart Contract
        </p>
        <p className="text-xs text-white ml-28 mr-10">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit
          dolores itaque esse delectus, sit illo, explicabo, consectetur facere
          reiciendis placeat ipsum nesciunt eum quam! Illum voluptas rem aut
          ratione laboriosam.
        </p>

        <p className="text-xs text-white ml-28 mr-10 py-10">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit
          dolores itaque esse delectus, sit illo, explicabo
        </p>

        <p className="text-xm text-white mt-28 px-52">Powered by</p>
        <img
          src={Logof}
          className="py-4 flex items-center justify-center relative left-52"
          alt="logo"
        />
      </div>

      <div>
        <h3 className="flex ml-24 font-bold text-2xl">Nice to see you again</h3>
        <div className="py-2 flex-1 flex items-center relative pt-24">
          <div className="w-full flex items-center justify-center">
            <div className="w-[90%] md:w-[75%] border border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center gap-4">
              <p className="font-extrabold text-3xl text-gray-600">Signup</p>
              {fields && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={`w-full p-2 rounded-lg text-center text-lg font-semibold ${
                    alertStatus === "danger"
                      ? "bg-red-400 text-red-800"
                      : "bg-emerald-400 text-emerald-800"
                  }`}
                >
                  {msg}
                </motion.p>
              )}

              <div className="w-full py-2 border-gray-300 flex items-center gap-2">
                <input
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  className="w-full rounded py-2 outline-none border-none px-4 text-gray-400"
                />
              </div>

              <div className="w-full py-2 border-gray-300 flex items-center gap-2">
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="w-full rounded py-2 outline-none border-none px-4 text-gray-400"
                />
              </div>

              <div className="w-full py-2 border-gray-300 flex items-center gap-2">
                <input
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="w-full rounded py-2 outline-none border-none px-4 text-gray-400"
                />
              </div>

              <div className="w-full py-2 border-gray-300 flex items-center gap-2">
                <input
                  type="password"
                  required
                  value={confirm_password}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  className="w-full rounded py-2 outline-none border-none px-4 text-gray-400"
                />
              </div>

              <div className="w-full">
                <select
                  onChange={(e) => setOrganization(e.target.value)}
                  className="w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4"
                >
                  <option value="organization" className="bg-white">
                    Select Organization
                  </option>

                  {organizations &&
                    organizations.map((item) => (
                      <option key={item.id} value={item.urlParamName}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="w-full">
                <select
                  onChange={(el) => setOrganization(el.target.value)}
                  className="w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4"
                >
                  <option value="roles" className="bg-white">
                    Select Role
                  </option>

                  {role &&
                    role.map((items) => (
                      <option key={items.id} value={items.urlParamName}>
                        {items.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="w-full flex items-center">
                <button
                  type="button"
                  className="w-full border-none outline-none bg-gradient-to-r from-purple-900 to-neutral-900 text-white rounded-lg py-3 text-lg"
                  onClick={saveDetails}
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
