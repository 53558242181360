import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const TokenVestingChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      renderChart();
    }
  }, []);

  const renderChart = () => {
    const chart = echarts.init(chartRef.current);

    const data = [
      {
        name: "Jan",
        series1: 120,
        series2: 50,
        series3: 30,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
      {
        name: "Feb",
        series1: 200,
        series2: 70,
        series3: 40,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
      {
        name: "Mar",
        series1: 150,
        series2: 80,
        series3: 50,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
      {
        name: "Apr",
        series1: 80,
        series2: 100,
        series3: 60,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
      {
        name: "May",
        series1: 70,
        series2: 120,
        series3: 70,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
      {
        name: "Jun",
        series1: 110,
        series2: 140,
        series3: 80,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
      {
        name: "Jul",
        series1: 130,
        series2: 160,
        series3: 90,
        series4: 30,
        series5: 30,
        series6: 30,
        series7: 30,
      },
    ];
    const options = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [
          "Mining Pool",
          "Ecosystem Incentives",
          "Protocol Treasury",
          "Team",
          "IDO",
          "Advisors",
          "LP Reserve",
        ],
      },
      xAxis: {
        type: "category",
        data: data.map((item) => item.name),
      },
      yAxis: {
        type: "value",
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: [
        {
          name: "Mining Pool",
          type: "line",
          areaStyle: { color: "#80FFDB" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },
          stack: "total",
          data: data.map((item) => item.series1),
          label: {
            show: false,
          },
        },
        {
          name: "Ecosystem Incentives",
          type: "line",
          areaStyle: { color: "#5AEDC9" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },
          stack: "total",
          data: data.map((item) => item.series2),
          label: {
            show: false,
          },
        },
        {
          name: "Protocol Treasury",
          type: "line",
          areaStyle: { color: "#48BFE3" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },

          stack: "total",
          data: data.map((item) => item.series3),
          label: {
            show: false,
          },
        },
        {
          name: "Team",
          type: "line",
          areaStyle: { color: "#E0AAFF" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },

          stack: "total",
          data: data.map((item) => item.series3),
          label: {
            show: false,
          },
        },
        {
          name: "IDO",
          type: "line",
          areaStyle: { color: "#C77DFF" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },
          stack: "total",
          data: data.map((item) => item.series3),
          label: {
            show: false,
          },
        },
        {
          name: "Advisors",
          type: "line",
          areaStyle: { color: "#9D4EDD" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },

          stack: "total",
          data: data.map((item) => item.series3),
          label: {
            show: false,
          },
        },
        {
          name: "LP Reserve",
          type: "line",
          areaStyle: { color: "#5A189A" },
          lineStyle: {
            color: "rgba(0, 0, 0, 0)",
          },

          stack: "total",
          data: data.map((item) => item.series3),
          label: {
            show: false,
          },
        },
      ],
    };
    chart.setOption(options);
  };
  return <div ref={chartRef} className="ProjectDetailsGraph" />;
};

export default TokenVestingChart;
