import React from "react";
import jake from "../img/jake.png";
import ethereum from "../img/eth.png";
import download from "../img/download-blue.png";
import uniswap from "../img/Uniswap.png";
import bitmart from "../img/bitmart.png";
import bingx from "../img/bingx.png";
import dodo from "../img/dodo.png";

const LbrMarketTable = (exchangeData) => {
  let Data = exchangeData.data;
  // if (Data.length !== 0) {
  //   console.log("from exchange table");
  //   console.log(Data);
  // }

  // console.log("from exchange table");
  // console.log(Data);
  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            #
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Exchange
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Pair
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Pool
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Price
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            24H Change
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            24H Volume
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            24H Vol Change
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Share
          </th>
        </tr>
      </thead>
      <tbody>
        {Data.map((item, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex">
                <img
                  src={
                    item.hasOwnProperty("tickers")
                      ? item?.tickers[0].market.logo
                      : ""
                  }
                  alt=""
                  className="w-5 h-5 mt-1"
                />
                <p className="influ-name">{item?.name && item?.name}</p>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              {/* {item?.tickers[0].base && item?.tickers[0].base === "LBR"
                ? "LBR"
                : "LBR"}
              /
              {item?.tickers[0].target && item?.tickers[0].target === "USDT"
                ? "USDT"
                : "USDT"} */}
              LBR/USDT
            </td>
            <td className="px-6 py-4 whitespace-nowrap">-</td>
            <td className="px-6 py-4 whitespace-nowrap">
              $
              {item.hasOwnProperty("tickers")
                ? item.tickers[0].converted_last.usd
                : ""}
            </td>
            <td className="px-6 py-4 whitespace-nowrap">-</td>
            <td className="px-6 py-4 whitespace-nowrap">
              {item.hasOwnProperty("tickers")
                ? item?.tickers[0].converted_volume.usd
                : ""}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">-</td>
            <td className="px-6 py-4 whitespace-nowrap">-</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LbrMarketTable;
