import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const options = {
  chart: {
    type: "column",
  },
  title: {
    text: "Column Chart with Negative Values",
  },
  xAxis: {
    categories: ["Apples", "Bananas", "Oranges"],
  },
  yAxis: {
    title: {
      text: "Fruit Count",
    },
  },
  series: [
    {
      name: "Positive Values",
      data: [5, 3, 4],
    },
    {
      name: "Negative Values",
      data: [-2, -4, -1],
    },
  ],
};

const NegativeLine = () => {
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default NegativeLine;
