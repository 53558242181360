import React, { useState } from "react";

const Modal = () => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const MyModal = () => {
    return (
      <>
        <div className="modal-wraper" onClick={closeModal}></div>
        <div className="modal-container">
          <h2>Project</h2>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore
            ipsam neque porro. Alias magni atque labore enim sit eius, quibusdam
            totam ut neque rem molestiae nesciunt, ex, similique nisi nostrum!
          </p>
          <button onClick={() => setShowModal(false)} className="btn">
            Add to Portfolio
          </button>
        </div>
      </>
    );
  };
  return (
    <>
      <button onClick={() => setShowModal(true)}>Open Modal</button>
      {showModal && <MyModal onClick={closeModal} />}
    </>
  );
};
export default Modal;
