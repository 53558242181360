import React, { useState } from "react";
import { MdFiberManualRecord } from "react-icons/md";
import Avatar from "../img/avatar.png";
import { CgProfile } from "react-icons/cg";
import { motion } from "framer-motion";
import logoDashboard from "../img/logoDashboard.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { app } from "../config/firebase.config";
import { setUserNull } from "../context/actions/userActions";
import UserProfile from "./UserProfile";

const Header = () => {
  const user = useSelector((state) => state.user);
  const [isMenu, setIsMenu] = useState(false);
  const firebaseAuth = getAuth(app);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signout = () => {
    firebaseAuth
      .signOut()
      .then(() => {
        dispatch(setUserNull());
        sessionStorage.removeItem("email");
        navigate("/signin", { replace: true });
      })
      .catch((err) => console.log(err));
  };

  return (
    <header className="z-10 w-screen py-2 px-10 bg-gradient-to-r bg-white">
      {/* Desktop & Tablet */}
      <div className="hidden md:flex w-full h-full items-center justify-between pr-1 pl-4">
        <Link to={"/Dashboard"} className="flex items-center gap-2">
          <img src={logoDashboard} className="object-cover" alt="logo" />
        </Link>

        <div className="-ml-32 text-indigo-900 font-extrabold">
          <ul className="flex items-center gap-8">
            <li className="menu-active">
              <Link to={"/Dashboard"}>Dashboard</Link>
              <div className="active"></div>
            </li>
            <li className="menu-deactive">
              <Link to={"/Portfolio"}>Portfolio</Link>
            </li>
            <li className="menu-deactive">Scanning</li>
          </ul>
        </div>

        {/* user details */}
        {user ? (
          <>
            <div
              className="relative cursor-pointer"
              onMouseEnter={() => setIsMenu(true)}
            >
              <div className="w-12 h-12 rounded-full shadow-md cursor-pointer overflow-hidden flex items-center justify-center">
                <motion.img
                  className="w-full h-full object-cover"
                  src={user?.picture ? user?.picture : Avatar}
                  whileHover={{ scale: 1.15 }}
                  referrerPolicy="no-referrer"
                />
              </div>

              {isMenu && (
                <motion.div
                  onMouseLeave={() => setIsMenu(false)}
                  className="px-5 py-4 bg-white text-black rounded-md absolute top-12 right-0 flex flex-col gap-4 w-300 mt-5 main-header"
                >
                  <div className="flex">
                    <div className="flex">
                      <div>
                        <img
                          src={user?.picture ? user?.picture : Avatar}
                          alt=""
                          className="w-14 h-14 rounded-full"
                        />
                      </div>
                      <div className="px-3">
                        <div className="text-gray-900">{user.name}</div>
                        <div className="text-xs text-gray-500">
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </div>

                  <Link
                    to={"/UserProfile"}
                    className="hover:text-violet-300 text-gray-700 w-full flex gap-2"
                  >
                    <MdFiberManualRecord className="text-md mt-2 profile" />{" "}
                    User Profile
                  </Link>

                  <p
                    className="hover:text-violet-300 text-gray-700 w-full flex gap-2"
                    onClick={signout}
                  >
                    <MdFiberManualRecord className="text-md mt-2 profile" />{" "}
                    Logout
                  </p>
                </motion.div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
};

export default Header;
