import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import DataTable from "./DataTable";
import MainLoader from "./MainLoader";
import { motion } from "framer-motion";

const Dashboard = () => {
  const [statsData, setStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // const myEmail = sessionStorage.getItem("email");
    // console.log(myEmail);
    //const myEmail = "pritomabhijit@gmail.com";
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const email = "pritomabhijit@gmail.com";
    console.log(email);
    await axios
      .get("getDashboradForCoins", {
        params: {
          USEID: email,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setStatsData(res.data);
        // console.log(statsData);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="py-0">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
      </div>
      <div className="bg-white mb-20 ml-20 mr-20 mt-10 rounded-lg drop-shadow">
        <h3 className="mb-2 ml-5 project-details-summary pt-5">
          Project Dashboard
        </h3>
        <p className="dashboard-desc">
          Securat.ai provides an in-depth real-time analysis on the hottest Web
          3 projects in the market.
        </p>
        <DataTable newLog={statsData == null ? [] : statsData} />
      </div>
      <Footer />
    </div>
    // Footer
  );
};

export default Dashboard;
