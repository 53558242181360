import React, { useState } from "react";
import {
  MdCloudUpload,
  MdKeyboardBackspace,
  MdInfo,
  MdDocumentScanner,
} from "react-icons/md";
import { GoMarkGithub } from "react-icons/go";
import { Link } from "react-router-dom";
import { Footer, GitHubScanningEx, HeadScanning } from "../components";
import { addGitProject } from "../api";
import { useSelector } from "react-redux";
import { Overview, Spinner } from "../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const style = {
  inactiveError: `w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4`,
  activeError: `w-full py-2 border border-rose-500 focus:border-gray-50 rounded text-base text-gray-400 px-4`,
};

const GitHubScanning = () => {
  const user = useSelector((state) => state.user);
  const [ProjectName, setProjectName] = useState("");
  const [GitLink, setGitLink] = useState("");
  const [newId, setNewId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [noLine, setNoLine] = useState(0);
  const [noProject, setNoProject] = useState(0);
  const [totalVul, setTotalVul] = useState(0);
  const [high, setHigh] = useState(0);
  const myEmail = sessionStorage.getItem("email");
  const [overview, setOverview] = useState([]);
  const [projectValue, setProjectValue] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const submitGitAddress = (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const Dateid = Date.now();
      setNewId(Dateid);
      const mail = user.email;
      setUserEmail(mail);

      const data = {
        GitLink: GitLink,
        ProjectName: ProjectName,
        id: Dateid,
        email: myEmail,
      };

      addGitProject(data).then((res) => {
        setGitLink(null);
        setProjectName(null);
        sessionStorage.setItem("id", Dateid);
        navigate(`/GeneratingReport`);
      });

      axios
        .get("REPORT", {
          params: {
            USEID: myEmail,
            PROJECT_ID: Dateid,
          },
        })
        .then((res) => {
          setNoLine(res.data.NOLINES);
          setNoProject(res.data.PROJECT_MONITORED);
          setTotalVul(res.data.TOTAL);
          setHigh(res.data.STATS);
          setOverview(res.data);
        });
    }
  };

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const validateInputs = () => {
    axios
      .get("ISPROJECTNAMEEXITS", {
        params: {
          USEID: myEmail,
          TYPE: "GIT",
          PROJECT_NAME: ProjectName,
        },
      })
      .then((res) => {
        setProjectValue(res.data.STATUS === true);
        console.log("*******1");
        console.log(res.data);
        console.log(projectValue);
      });

    const errors = {};
    let newValue = ProjectName.trim();
    let newValueLink = GitLink.trim();
    let startWith = newValueLink.startsWith("https://github.com");
    let endWith = newValueLink.endsWith(".git");
    if (ProjectName == "") {
      errors.ProjectName = "This field is required";
    } else if (newValue.length === 0) {
      errors.ProjectName = "This field is required";
    } else if (!projectValue) {
      errors.ProjectName = "This project is already exist";
    } else if (GitLink == "") {
      errors.GitLink = "This field is required";
    } else if (!startWith) {
      errors.GitLink =
        "This is a invalid link. It is recommended to use the HTTPS GitHub (.git) cloning link of the repository";
    } else if (!endWith) {
      errors.GitLink =
        "This is a invalid link. It is recommended to use the HTTPS GitHub (.git) cloning link of the repository";
    } else if (newValueLink.length === 0) {
      errors.GitLink = "This field is required";
    } else if (!isValidURL(GitLink)) {
      errors.GitLink = "Provided link is not valid";
    }
    return errors;
  };

  return (
    <div>
      <HeadScanning />
      <div className="flex pl-20 pt-10">
        <div className="w-2/3">
          <div className="flex pl-20 gap-4 mb-10 text-xl">
            <Link to={"/Scanning"}>
              <MdKeyboardBackspace className="text-2xl" />
            </Link>
            Smart Contract Scanning
          </div>

          <div className="flex ml-20">
            <div className="text-gray-500 flex gap-2">
              1. Choose Scanning Type <MdInfo className="text-xl" />
            </div>
          </div>

          <div className="flex mt-2">
            <div className="flex flex-1 ml-20 gap-2">
              <Link
                to={"/StartScanning"}
                className="bg-gray-300 w-64 text-white px-5 py-3 rounded-md flex gap-2"
              >
                <MdCloudUpload className="text-xl" />
                Upload Contract File
              </Link>

              <Link
                to={"/GitHubScanning"}
                className=" text-white w-56  px-5 py-3 rounded-md flex gap-2 profile-button"
              >
                <GoMarkGithub className="flex text-xl" />
                GitHub Repository
              </Link>

              <Link
                to={"/ContractScanning"}
                className="text-white w-56 bg-gray-300 px-5 py-3 rounded-md flex gap-2"
              >
                <MdDocumentScanner className="flex text-xl" />
                Contract Address
              </Link>
            </div>
          </div>

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">
              2. Enter Project Name
            </div>
          </div>

          <div className=" ml-20 flex gap-2">
            <Link
              to={"/GitHubScanning"}
              className="flex px-2 py-2 text-white rounded-lg profile-button"
            >
              New Project
            </Link>

            <Link
              to={"/GitHubScanningEx"}
              className="flex px-2 py-2 bg-gray-300 text-white rounded-lg ml-3"
            >
              Existing Project
            </Link>
          </div>

          <div className="flex flex-1 w-7/8 ml-20 mt-5">
            <input
              type="text"
              name="ProjectName"
              value={ProjectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Project Name"
              className={`${
                errors.ProjectName ? style.activeError : style.inactiveError
              }`}
            />
          </div>
          {errors.ProjectName && (
            <div className="text-red-600 pl-20">{errors.ProjectName}</div>
          )}

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">
              3. Link to GitHub Repository
            </div>
          </div>

          <div className="flex flex-1 w-7/8 ml-20 mt-5">
            <input
              type="text"
              value={GitLink}
              onChange={(e) => setGitLink(e.target.value)}
              placeholder="GitHub Repository Link"
              className={`${
                errors.GitLink ? style.activeError : style.inactiveError
              }`}
            />
          </div>
          {errors.GitLink && (
            <div className="text-red-600 pl-20">{errors.GitLink}</div>
          )}

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">Remarks</div>
          </div>
          <div className="ml-20 mt-2 text-gray-700 text-sm">
            <ul className="ml-10 mr-10">
              <li className="list-disc">
                Ensure the link is to a GitHub repository containing Solidity
                (.sol) files. It is recommended to use the HTTPS GitHub (.git)
                cloning link of the repository.
              </li>
              <li className="list-disc">
                Verify if the repository is public, for private repositories,
                please integrate your GitHub from the Integrations tab.
              </li>
            </ul>
          </div>
          <div className="ml-20 mr-20 mb-20 text-white py-3 rounded-lg text-center mt-5 profile-button">
            <button onClick={submitGitAddress}>Start Scanning</button>
          </div>
        </div>
        <div className="w-1/3">
          <Overview view={overview == null ? [] : overview} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default GitHubScanning;
