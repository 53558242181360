import React from "react";
import { Doughnut } from "react-chartjs-2";

const OverallRishGraph = (issueD) => {
  let IssueD = issueD.issueD;
  console.log(IssueD);
  if (IssueD.length === 0) {
    const constData = [
      { name: "No data", number: 0 },
      { name: "No data", number: 0 },
      { name: "No data", number: 0 },
      { name: "No data", number: 0 },
    ];
    IssueD = constData;
  }

  var arr = [];
  for (var i = 0; i < IssueD.length; i++) {
    if (IssueD[i].TITLE === "High") {
      arr = arr.concat(["#E42528"]);
    } else if (IssueD[i].TITLE === "Medium") {
      arr = arr.concat(["#38BCD7"]);
    } else if (IssueD[i].TITLE === "Low") {
      arr = arr.concat(["#16C683"]);
    } else if (IssueD[i].TITLE === "Informational") {
      arr = arr.concat(["#B7B8BD"]);
      console.log(arr);
    }
  }

  if (arr.length === 0) {
    arr = [
      "#E42528",
      "#2F72F5",
      "#16C683",
      "#3C1B66",
      "rgba(255, 159, 64, 0.2)",
    ];
  }

  var data = {
    labels: IssueD?.map((x) => x.TITLE),
    datasets: [
      {
        label: `Vulneralities Available`,
        data: IssueD?.map((x) => x.value),
        backgroundColor: arr,
        borderColor: arr,
        borderWidth: 1,
      },
    ],
  };
  var options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 60,
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0].x;
      const yYoor = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.font = "bolder 30px DM Sans";
      ctx.fillStyle = "#3C1B66";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        `${
          (data.datasets[0].data[0] === undefined
            ? 0
            : data.datasets[0].data[0]) +
          (data.datasets[0].data[1] === undefined
            ? 0
            : data.datasets[0].data[1]) +
          (data.datasets[0].data[2] === undefined
            ? 0
            : data.datasets[0].data[2]) +
          (data.datasets[0].data[3] === undefined
            ? 0
            : data.datasets[0].data[3])
        }`,
        xCoor,
        yYoor - 15
      );
      ctx.font = "14px DM Sans";
      ctx.fillStyle = "#656565";
      ctx.fillText("Issues", xCoor, yYoor + 20);
    },
  };
  return <Doughnut data={data} options={options} plugins={[textCenter]} />;
};

export default OverallRishGraph;
