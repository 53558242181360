import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../config/firebase.config";

export const saveUsers = async (data) => {
  await setDoc(doc(firestore, "users", `${Date.now()}`), data, { merge: true });
};

// Saving new Item
export const saveItem = async (data) => {
  await setDoc(doc(firestore, "project", `${data.id}`), data, {
    merge: true,
  });
};

//get the data
export const getAllData = async () => {
  const items = await getDocs(query(collection(firestore, "project")));
  return items.docs.map((doc) => doc.data());
};

//get the data
export const getAllStats = async () => {
  const stats = await getDocs(query(collection(firestore, "STATS_INFO")));
  return stats.docs.map((doc) => doc.data());
};

//get the data
export const getAllIssue = async () => {
  const issue = await getDocs(query(collection(firestore, "ISSUE_INFO")));
  return issue.docs.map((doc) => doc.data());
};
