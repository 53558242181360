const projectReducers = (state = null, action) => {
  switch (action.type) {
    case "GET_ALL_PROJECTS":
      return state;

    case "SET_ALL_PROJECTS":
      return action.project;

    default:
      return state;
  }
};

export default projectReducers;
