import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const RevueGraph = (Revenue) => {
  let fee = Revenue.Revenue;
  console.log("Fee");
  console.log(fee);
  const date = new Date(1682380800).getFullYear();
  console.log(date);

  const limitedData = fee.slice(0, 5);
  const chartRef = useRef(null);

  function convertTimestampToMonthAndYear(timestamp) {
    const date = new Date(timestamp);
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  useEffect(() => {
    if (chartRef.current) {
      renderChart();
    }
  }, []);

  const renderChart = () => {
    const chartMap = echarts.init(chartRef.current);
    const options = {
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: limitedData?.map((x) => convertTimestampToMonthAndYear(x[0])),
        splitLine: { show: true, interval: 6 },
        show: true,
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
        show: true,
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
          handleIcon: "circle",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
          height: 10, // Custom height of the dataZoom
          // bottom: 20,
          handleSize: 20, // Adjust the handle size
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
            shadowColor: "transparent",
          },
          moveHandleStyle: {
            color: "",
          },
          emphasis: {
            moveHandleStyle: {
              color: "",
            },
          },
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
          },
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
        },
      ],
      series: [
        {
          symbol: "rect", // Set the symbol to circle (you can use other symbols)
          symbolSize: 8,
          itemStyle: {
            color: "rgba(101, 54, 163, 1)", // Change the color of the symbols
          },
          type: "line",
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(101, 54, 163, .2)", // Start color
                },
                {
                  offset: 1,
                  color: "rgba(101, 54, 163, 0)", // End color
                },
              ],
            },
          },
          data: limitedData?.map((x) => x[1]),
        },
      ],
      lineStyle: {
        color: "rgba(101, 54, 163, 1)", // Change the color of the upper line
        shadowBlur: 5, // Set the shadow blur radius
        shadowColor: "rgba(101, 54, 163, 1)", // Shadow color
      },
    };

    chartMap.setOption(options);
  };

  return (
    <div>
      <div ref={chartRef} className="ProjectDetailsGraph" />
    </div>
  );
};

export default RevueGraph;
