import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(LineElement, LinearScale, CategoryScale, PointElement);

const TokenPriceGraph = (TokenGraph) => {
  let fee = TokenGraph.TokenGraph;

  //console.log(fee);

  // var arr = [];
  for (var i = 0; i < fee.length; i++) {
    // const timestamp = fee[i][0];
    const dateObj = new Date(fee[i][0]);
    const year = new Date(fee[i][0]).getFullYear();
    const month = dateObj.getMonth() + 1;
    // console.log(month);
    //console.log(year);

    // let indexToModify = i;
    // let elementIndexToReplace = 0;
    // let newValue = year;

    // let newFee = fee.map((arr) => [...arr]);

    // let innerArrayToModify = newFee[indexToModify];
    // innerArrayToModify[elementIndexToReplace] = newValue;
    // newFee[indexToModify] = innerArrayToModify;
    // let finalArrayOfArrays = newFee.map((arr) => [...arr]);
    // console.log(finalArrayOfArrays);
  }

  var data = {
    labels: fee?.map((x) => new Date(x[0]).getFullYear()),
    datasets: [
      {
        label: "Sample Line Chart",
        data: fee?.map((x) => x[1]),
        fill: false, // Disable filling area under the line
        borderColor: "#531B66", // Line color
        tension: 0.4, // Smoothing of the line (0 to 1)
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="w-full">
      <Line data={data} options={options} />
    </div>
  );
};

export default TokenPriceGraph;
