import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const LineChart = (FeeGraph) => {
  let fee = FeeGraph.FeeGraph;
  // console.log("from fee graph");
  // console.log(fee);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      renderChart();
    }
  }, []);

  const renderChart = () => {
    const chartMap = echarts.init(chartRef.current);
    const options = {
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        data: fee?.map((x) => new Date(x[0]).getFullYear()),
      },
      yAxis: {
        type: "value",
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: [
        {
          type: "line",
          areaStyle: {
            color: "rgba(0, 128, 255, 0.3)",
          },
          data: fee?.map((x) => x[1]),
        },
      ],
    };

    chartMap.setOption(options);
  };

  return (
    <div>
      <div ref={chartRef} style={{ width: "1000px", height: "400px" }} />
    </div>
  );
};

export default LineChart;
