import React from "react";

const Chains = (chain) => {
  const allChain = chain.chain;
  console.log(allChain);
  return (
    <div className="flex">
      {allChain.map((item) =>
        item === "Ethereum" ? (
          <p key={item} className="flex">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fethereum.png?alt=media&token=f1a3a298-1b27-43c9-b604-485e3486fde3"
              alt=""
              className="w-5"
            />
          </p>
        ) : item === "Arbitrum" ? (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Farbitrum.png?alt=media&token=781a3a89-f6d0-4d32-9cd0-71c1259c6f5b"
            alt=""
            className="w-5 ml-1"
          />
        ) : item === "Polygon" ? (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fpolygon.png?alt=media&token=1dbe750b-4273-432b-9af1-c61de0a00e13"
            alt=""
            className="w-5 ml-1"
          />
        ) : item === "Optimism" ? (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2FOptimism.png?alt=media&token=0a5dab10-4082-4d7a-900f-2bdfa84f97d9"
            alt=""
            className="w-5 ml-1"
          />
        ) : item === "Avalanche" ? (
          <img
            src="https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Favalanche.png?alt=media&token=c9012e03-cc2e-4779-9127-4a130b9d3af1"
            alt=""
            className="w-5 ml-1"
          />
        ) : (
          ""
        )
      )}
    </div>
  );
};

export default Chains;
