import React, { useState, useEffect } from "react";
import { MdOutlineCopyright, MdKeyboardArrowRight } from "react-icons/md";
import MyModal from "./ShowModal";
import MainLoader from "./MainLoader";
import { motion } from "framer-motion";
import axios from "axios";
import { Link } from "react-router-dom";
import HeaderPortfolio from "./HeaderPortfolio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  enable: `px-6 py-2 rounded-3xl font-bold`,
  disable: `w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4`,
};

const ViewAllProjects = () => {
  const [showModal, setShowModal] = useState(false);
  const [allData, setAllData] = useState([]);
  const closeModal = () => setShowModal(false);
  const [singleData, setSingleData] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const id = sessionStorage.getItem("id");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const myEmail = sessionStorage.getItem("email");
  const [parentData, setParentData] = useState(Math.random());
  const onChildData = parentData;
  console.log("onChildData");
  console.log(onChildData);

  const handleChildData = (data) => {
    setParentData(data);
    console.log(parentData);
  };

  const ethereum =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fethereum.png?alt=media&token=f1a3a298-1b27-43c9-b604-485e3486fde3";

  const polygon =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fpolygon.png?alt=media&token=c2461d2a-0f86-4015-a895-126412b2314d";

  const binance =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fbinance.png?alt=media&token=4075ccab-bd14-4204-bc54-ca074adf8d97";

  const avalance =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Favalanche.png?alt=media&token=c9012e03-cc2e-4779-9127-4a130b9d3af1";

  const arbitram =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Farbitrum.png?alt=media&token=781a3a89-f6d0-4d32-9cd0-71c1259c6f5b";

  const fantom =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Ffantom.png?alt=media&token=52379a3f-5a8a-4a10-8f15-8de0c30eb066";

  const optimism =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2FOptimism.png?alt=media&token=0a5dab10-4082-4d7a-900f-2bdfa84f97d9";

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    axios
      .get("IMP_PROJECT_LIST", {
        params: {
          USEID: myEmail,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setIsLoading(false);
      });
  }, [parentData]);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
  }, []);

  const handleModalClick = async (param, paramKey, paramChain) => {
    if (paramChain.length === 1) {
      setButtonDisabled(true);
      toast("Project added to your portfolio successfully.", {
        position: toast.POSITION.BOTTOM_CENTER,
        // autoClose: 10000,
        theme: "colored",
        toastClassName: "custom-toast",
      });
      console.log(paramKey);
      console.log(paramChain[0].CHAIN);
      const newId = paramChain[0].PROJECTID;
      const project = paramKey;
      console.log(paramChain[0].PROJECTID);
      await axios
        .get("ADD_IMP_PROJECT", {
          params: {
            USERID: myEmail,
            PROJECT_ID: newId,
            INPUT_PROJECT_NAME: project,
          },
        })
        .then((res) => {
          console.log(res);
          setButtonDisabled(false);
        });
      setParentData(Math.random());
    } else {
      setSingleData(param);
      setProjectName(paramKey);
      console.log(projectName);
      setShowModal(true);
      // onChildData(Math.random());
    }
  };

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    await axios
      .get("IMP_PROJECT_LIST", {
        params: {
          USEID: myEmail,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setIsLoading(false);
      });
  };

  let ordered = allData;

  if (allData === undefined || allData === null) {
    ordered = allData;
  } else {
    ordered = Object.keys(allData)
      .sort()
      .reduce((obj, key) => {
        obj[key] = allData[key];
        return obj;
      }, {});
  }

  return (
    <div>
      <HeaderPortfolio />
      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          <Link to={"/Portfolio"}>Portfolio</Link>{" "}
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="text-indigo-700 ">Project Details</span>
        </div>
      </div>

      <div className="grid grid-cols-3 px-20 py-5 top-project gap-5">
        {/* {Object.keys(allData)?.map((key, index) => ( */}
        {Object.keys(ordered)?.map((key, index) => (
          <div className=" bg-white px-2 py-4 rounded-md" key={index}>
            <div className="flex flex-1 mb-5 border-b-2">
              <p className="flex flex-1 items-start gap-2 pb-3">
                <img src={ordered[key].icon} className="w-5 h-5" alt="" />
                <span>{key}</span>
              </p>
              {ordered[key].ALLSELECTED === "NO" ? (
                isButtonDisabled === false ? (
                  <p
                    className="mr-5 font-extrabold cursor-pointer text-sky-400"
                    onClick={() =>
                      handleModalClick(
                        ordered[key],
                        key,
                        ordered[key].CHAIN_LIST
                      )
                    }
                  >
                    Add to portfolio
                  </p>
                ) : (
                  <p className="mr-5 font-extrabold text-gray-400">
                    Add to portfolio
                    <ToastContainer limit={1} />
                  </p>
                )
              ) : (
                <p className="mr-5 font-extrabold text-gray-400">
                  Added to portfolio
                </p>
              )}
              {showModal && (
                <MyModal
                  closeModal={closeModal}
                  param={singleData}
                  paramKey={projectName}
                  childData={parentData}
                  onChildData={handleChildData}
                />
              )}
            </div>

            <div className="flex flex-1 h-24">
              <p className="flex flex-1 items-start gap-2 px-5 top-project-desc">
                {allData[key].DESCRIPTION}
              </p>
            </div>

            <div className="pt-3 ml-5">
              <p className="supported-chain">Supported Chain</p>

              <div className="flex">
                {allData[key].CHAIN_LIST.map((item) => (
                  <div className="pt-2" key={item.CHAIN}>
                    {item.CHAIN === "Ethereum" ? (
                      <img src={ethereum} className="w-5 ml-1" alt="" />
                    ) : item.CHAIN === "Polygon" ? (
                      <img src={polygon} className="w-5 ml-1" alt="" />
                    ) : item.CHAIN === "Binance" ? (
                      <img src={binance} className="w-5 ml-1" alt="" />
                    ) : item.CHAIN === "Avalanche" ? (
                      <img src={avalance} className="w-5 ml-1" alt="" />
                    ) : item.CHAIN === "Arbitrum" ? (
                      <img src={arbitram} className="w-5 ml-1" alt="" />
                    ) : item.CHAIN === "Fantom" ? (
                      <img src={fantom} className="w-5 ml-1" alt="" />
                    ) : item.CHAIN === "Optimism" ? (
                      <img src={optimism} className="w-5 ml-1" alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="py-5 text-white bg-gradient-to-r from-purple-900 to-neutral-900">
        <p className="text-center flex fle-1 gap-2 items-center justify-center">
          <MdOutlineCopyright />
          Securat.ai | 2023
        </p>
      </div> */}
    </div>
  );
};

export default ViewAllProjects;
