import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const WalletEntanglement = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const data = [
      { name: "Location A", value: [55, 9, 56] }, // [AQI, PM2.5, PM10]
      { name: "Location B", value: [25, 11, 24] },
      { name: "Location C", value: [56, 7, 32] },
      { name: "Location D", value: [33, 10, 56] },
      { name: "Location E", value: [42, 13, 43] },
      { name: "Location F", value: [10, 1, 40] },
      { name: "Location G", value: [10, 20, 56] }, // [AQI, PM2.5, PM10]
      { name: "Location H", value: [20, 10, 35] },
      { name: "Location I", value: [50, 70, 10] },
      { name: "Location J", value: [30, 5, 30] },
      { name: "Location K", value: [25, 10, 40] },
      { name: "Location L", value: [19, 15, 20] },

      { name: "Location M", value: [5, 30, 50] }, // [AQI, PM2.5, PM10]
      { name: "Location N", value: [25, 8, 14] },
      { name: "Location O", value: [50, 30, 10] },
      { name: "Location P", value: [33, 10, 20] },
      { name: "Location Q", value: [20, 10, 30] },
      { name: "Location R", value: [30, 10, 40] },
      { name: "Location S", value: [40, 20, 56] }, // [AQI, PM2.5, PM10]
      { name: "Location T", value: [30, 50, 40] },
      { name: "Location U", value: [40, 60, 50] },
      { name: "Location V", value: [60, 50, 50] },
      { name: "Location W", value: [75, 30, 40] },
      { name: "Location X", value: [29, 30, 20] },
    ];
    const options = {
      xAxis: {
        show: false, // Hide the x-axis
      },
      yAxis: {
        show: false, // Hide the x-axis
      },
      series: [
        {
          type: "scatter",
          data: data.map((item) => ({
            name: item.name,
            value: item.value.slice(1), // Exclude AQI for plotting
          })),
          symbolSize: (data) => Math.sqrt(data[0]) * 10, // Use sqrt of PM2.5 for symbol size
          itemStyle: {
            color: (data) => {
              // Set color based on AQI value
              const aqi = data.data.value[0];
              if (aqi <= 50) return "green";
              else if (aqi <= 100) return "yellow";
              else if (aqi <= 150) return "orange";
              else return "red";
            },
          },
        },
      ],
    };

    chart.setOption(options);
  }, []);
  return (
    <div>
      <div ref={chartRef} style={{ width: "1400px", height: "400px" }} />
    </div>
  );
};

export default WalletEntanglement;
