import React from "react";
import { Doughnut } from "react-chartjs-2";

const HealthCheck = () => {
  const data = {
    labels: ["Data 1", "Data 2"],
    datasets: [
      {
        data: [50, 50, 50],
        backgroundColor: ["#CB553E", "#38BCD7", "#21E49A"],
        circumference: 180,
        rotation: 270,
        borderWidth: 0, // Set border width to zero
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    plugins: {
      legend: {
        display: false, // Hide the labels
      },
    },
    cutout: 50,
  };
  return <Doughnut data={data} options={options} />;
};

export default HealthCheck;
