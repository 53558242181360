import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const EnegativeBar = (tvl) => {
  let fee = tvl.tvl;
  const limitedData = fee.slice(0, 7);

  function convertTimestampToMonthAndYear(timestamp) {
    const dateObject = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    // Get the month as a string (e.g., "January", "February", etc.)
    const month = dateObject.toLocaleString("default", { month: "long" });

    const year = dateObject.getFullYear();

    // Format the result as "Month Year" (e.g., "August 2023")
    const formattedDate = `${month} ${year}`;

    return formattedDate;
  }

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      renderChart();
    }
  }, []);

  const renderChart = () => {
    const chart = echarts.init(chartRef.current);
    const options = {
      tooltip: {},
      xAxis: {
        type: "category",
        data: limitedData.map((item) =>
          convertTimestampToMonthAndYear(item.date)
        ),
        splitLine: { show: true, interval: 6 },
        show: true,
      },
      yAxis: {
        type: "value", // Use 'value' for vertical bar chart
        // axisLine: {
        //   show: false, // Hide the yAxis line
        // },
        // axisTick: {
        //   show: false, // Hide the yAxis tick
        // },
        splitLine: { show: false },
        show: true,
      },

      series: [
        {
          type: "bar",
          data: limitedData.map((item) => item.totalLiquidityUSD),
          barCategoryGap: "50%", // Adjust the gap between bars
          label: {
            show: false,
            position: "top", // Show the label on top of the bars
            formatter: (params) =>
              params.value < 0 ? -params.value : params.value, // Display absolute values
          },
          itemStyle: {
            color: (params) => (params.value < 0 ? "#CB553E" : "#21E49A"), // Set different colors for negative and positive bars
          },
        },
      ],
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
          handleIcon: "circle",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
          height: 10, // Custom height of the dataZoom
          // bottom: 20,
          handleSize: 20, // Adjust the handle size
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
            shadowColor: "transparent",
          },
          moveHandleStyle: {
            color: "",
          },
          emphasis: {
            moveHandleStyle: {
              color: "",
            },
          },
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
          },
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
        },
      ],
    };
    chart.setOption(options);
  };

  return <div ref={chartRef} className="TokenbarGraph" />;
};

export default EnegativeBar;
