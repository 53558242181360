import { getApp, getApps, initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyAWsCxN_WqwobPD0JiSUplKQrnqEGlkMKI",
//   authDomain: "securat-8b754.firebaseapp.com",
//   projectId: "securat-8b754",
//   storageBucket: "securat-8b754.appspot.com",
//   messagingSenderId: "422189971318",
//   appId: "1:422189971318:web:42d0531a8ea4eb77c5ac57",
// };

const firebaseConfig = {
  apiKey: "AIzaSyASr7pliEMscwyKOn1T30VEbDZ46vGNbVY",
  authDomain: "securatapp.firebaseapp.com",
  projectId: "securatapp",
  storageBucket: "securatapp.appspot.com",
  messagingSenderId: "381272094077",
  appId: "1:381272094077:web:77628746edfee08f924814",
  measurementId: "G-ZTW4FMFWVT",
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { app, firestore, storage };
