import { React } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Chains from "./Chains";
const defaultTheme = createTheme();

const DataTable = (newLog) => {
  const navigate = useNavigate();
  var NewLog = newLog.newLog;

  console.log("Coin from Dashboard");
  console.log(NewLog);

  const viewDetails = (id, geckoId) => {
    sessionStorage.setItem("id", id);
    sessionStorage.setItem("geckoId", geckoId);
    console.log(geckoId);
    console.log(id);
    navigate(`/ProjectDetails`);
  };

  function convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "k"
      : Math.abs(Number(labelValue));
  }

  return (
    <div className="mt-10">
      <ThemeProvider theme={defaultTheme}>
        <MaterialTable
          columns={[
            {
              title: "Project Name",
              field: "name",
              render: (rowData) => {
                return rowData.name === "Lybra Finance" ? (
                  <p
                    onClick={() => viewDetails(rowData.id, rowData.gecko_id)}
                    className="flex gap-2 cursor-pointer"
                    style={{
                      color: "#281616",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    <img
                      src={rowData.logo}
                      style={{ width: 20, height: 20, borderRadius: "50%" }}
                      alt=""
                    />
                    {rowData.name}
                  </p>
                ) : (
                  <p
                    className="flex gap-2"
                    style={{
                      color: "#281616",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    <img
                      src={rowData.logo}
                      style={{ width: 20, height: 20, borderRadius: "50%" }}
                      alt=""
                    />
                    {rowData.name}
                  </p>
                );
              },
              cellStyle: {
                width: "20%",
              },
            },
            {
              title: "Category",
              field: "category",
              render: (rowData) => {
                return rowData.category !== undefined ? (
                  <p
                    style={{
                      color: "#3C1B66",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.category}
                  </p>
                ) : (
                  <p></p>
                );
              },
            },

            {
              title: "1D",
              field: "change_1d",
              render: (rowData) => {
                return rowData.change_1d > 0 ? (
                  <p
                    style={{
                      color: "#21E49A",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.change_1d !== undefined
                      ? `+ ${rowData.change_1d.toFixed(2)}%`
                      : null}
                  </p>
                ) : (
                  <p
                    style={{
                      color: "#CB553E",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.change_1d !== null
                      ? `${rowData.change_1d.toFixed(2)}%`
                      : null}
                  </p>
                );
              },
              cellStyle: {
                width: "10%",
              },
            },
            {
              title: "7D",
              field: "change_7d",
              render: (rowData) => {
                return rowData.change_7d > 0 ? (
                  <p
                    style={{
                      color: "#21E49A",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.change_7d !== undefined
                      ? `+ ${rowData.change_7d.toFixed(2)}%`
                      : null}
                  </p>
                ) : (
                  <p
                    style={{
                      color: "#CB553E",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.change_7d !== undefined
                      ? `${rowData.change_7d.toFixed(2)}%`
                      : null}
                  </p>
                );
              },
              cellStyle: {
                width: "10%",
              },
            },
            {
              title: "TVL",
              field: "tvl",
              render: (rowData) => {
                return rowData.tvl > 0 ? (
                  <p
                    style={{
                      color: "#8D8D8D",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.tvl !== undefined
                      ? `$${convertToInternationalCurrencySystem(
                          rowData.tvl.toFixed(2)
                        )}`
                      : null}
                  </p>
                ) : (
                  <p></p>
                );
              },
            },
            {
              title: "Market Cap",
              field: "mcap",
              render: (rowData) => {
                return rowData.mcap > 0 ? (
                  <p
                    style={{
                      color: "#8D8D8D",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                    }}
                  >
                    {rowData.mcap !== undefined
                      ? `$${convertToInternationalCurrencySystem(
                          rowData.mcap.toFixed(2)
                        )}`
                      : null}
                  </p>
                ) : (
                  <p></p>
                );
              },
            },
            {
              title: "Supported Chain",
              field: "chains",
              render: (rowData) => {
                return <Chains chain={rowData.chains} />;
              },
            },
          ]}
          data={NewLog}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontFamily: "DM Sans",
              color: "#656565",
              fontSize: "14px",
            },
            showTitle: false,
            pageSize: 10,
          }}
          style={{
            background: "white",
            paddingRight: "20px",
            paddingLeft: "20px",
            fontFamily: "DM Sans",
            fontWeight: "500",
            color: "#281616",
            // borderRadius: "16px",
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default DataTable;
