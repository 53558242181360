import React, { useState, useEffect } from "react";
import Header from "./Header";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdInfo,
  MdArrowForward,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import lybra from "../img/lybrafinance@2x.png";
import MainLoader from "./MainLoader";
import ethereum from "../img/eth.png";
import copy from "../img/copy-grey.png";
import image17 from "../img/image17.png";
import NegativeLine from "../charts/NegativeLine";
import FullCircle from "../charts/FullCircle";
import ProjectBarGraph from "../charts/ProjectBarGraph";
import UnlockInformationTable from "./UnlockInformationTable";
import LbrMarketTable from "./LbrMarketTable";
import OverallRishGraph from "../charts/OverallRishGraph";
import TokenVestingChart from "../charts/TokenVestingChart";
import EbarGraph from "../charts/EbarGraph";
import EnegativeBar from "../charts/EnegativeBar";
import SemiCircle from "../charts/SemiCircle";
import privacy from "../img/privacy_tip.png";
import { Tooltip } from "react-tooltip";
import EUSDMaxSuppy from "../charts/EUSDMaxSuppy";
import EUSDTable from "./EUSDTable";
import EUSDMintGraph from "../charts/EUSDMintGraph";
import HealthCheck from "../charts/HealthCheck";
import { width } from "@mui/system";
import StableCoingGraph from "../charts/StableCoingGraph";
import StableTokenGraph from "../charts/StableTokenGraph";
import StableMarketCap from "../charts/StableMarketCap";
import StableCoinVolume from "../charts/StableCoinVolume";
const style = {
  critical: `critical`,
  moderate: `moderate`,
  minor: `minor`,
  informational: `informational`,
  healthoptimization: `health-Opimization`,

  high: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  low: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
};

const StableCoin = () => {
  const [step, setStep] = useState(1);
  const [stepLine, setStepLine] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [tradingVolumeGraph, setTradingVolumeGraph] = useState([]);
  const [tvlGraph, setTvlGraph] = useState([]);
  const [descStatus, setDescStatus] = useState({});
  const [overallRisk, setOverallRisk] = useState([]);
  const [stableCoins, setStableCoin] = useState([]);
  const [stableCoinGraph, setStableCoinGraph] = useState([]);
  const [marketCap, setMarketCap] = useState([]);
  const [stableCoinVolume, SetStableCoinVolume] = useState([]);
  const [emint, setEmint] = useState([]);
  const [stableCoinheaderData, setStableCoinheaderData] = useState([]);
  const [eUSDData, setEUSDData] = useState([]);
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);
  const [issue, setIssue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchTokenData();
    fetchDataGraph();
    fetchStatbleCoinData();
    fetchEUSDData();
    fetchStatbleCoinHeaderData();
    fetchEMintDData();
  }, []);

  const fetchTokenData = async (myEmail) => {
    axios
      .get("PROJECT_DESCRIPTION_GROUP", {
        params: {
          USEID: "pritomabhijit@gmail.com",
          PROJECT_ID: "320230810225017",
        },
      })
      .then((res) => {
        // console.log(res.data);
        setProjectDetails(res.data);
        setOverallRisk(res.data.STABLECOIN_DATA.BAR_VALUES_PROF);

        axios
          .get("GETPROJECTSDETAILSGROUP", {
            params: {
              USEID: myEmail,
              PROJECT_ID: "20230810221652",
            },
          })
          .then((res) => {
            console.log("Issue list");
            console.log(res.data);
            setIssue(res.data.ISSUES.ISSUES_SORT);
            setDescStatus(res.data.ISSUES.DESC_STATUS);
            setIsLoading(false);
          });
      });
  };

  const fetchDataGraph = async () => {
    axios
      .get("getHeaderForProject", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("***12**");
        // console.log(res.data);
        setTradingVolumeGraph(res.data.TVL_GRAPH_DATA);
        setTvlGraph(res.data.TVL_GRAPH_DATA_INFLOW);
      });
  };

  const fetchStatbleCoinData = async () => {
    axios
      .get("getMarketDataStableCoin", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("From Stable Coin");
        // console.log(res.data);
        setStableCoin(res.data.EXCHANGEDATA_STALE_COIN);
      });
  };

  const fetchStatbleCoinHeaderData = async () => {
    axios
      .get("getHeaderForProjectStableCoin", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        console.log("From Stable Coin Header data");
        console.log(res.data);
        setStableCoinheaderData(res.data);
        setStableCoinGraph(res.data.MARKET_VOL_PRICE_GRAPH.prices);
        setMarketCap(res.data?.MARKET_VOL_PRICE_GRAPH?.market_caps);
        SetStableCoinVolume(res.data.MARKET_VOL_PRICE_GRAPH.total_volumes);
      });
  };

  const fetchEUSDData = async () => {
    axios
      .get("geteUSDMaxSupply", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("From EUSD Data");
        // console.log(res.data);
        setEUSDData(res.data.eUSDMAX_DATA);
      });
  };

  const fetchEMintDData = async () => {
    setIsLoading(true);
    axios
      .get("getBurnMint", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        console.log("From EUMint Data");
        console.log(res.data);
        setEmint(res.data.DUNEDATA);
        setIsLoading(false);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  function ratingOverview() {
    setStep(1);
  }
  function projectInfo() {
    setStep(2);
  }
  function latestNews() {
    setStep(3);
  }

  function updateTokenPrice() {
    setStepLine(1);
  }

  function updateMarketCap() {
    setStepLine(2);
  }

  function updateVolume() {
    setStepLine(3);
  }

  function audit() {
    setStep(4);
  }

  function formatNumberWithCommas(number) {
    const parts = number.toFixed(2).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return (
    <div>
      <Header />
      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          Project Dashboard
          <MdKeyboardArrowRight className="text-2xl" />
          {projectDetails.NAME}
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">
            Stablecoin (
            {stableCoinheaderData?.COINGECKO?.name === undefined
              ? ""
              : stableCoinheaderData?.COINGECKO?.name}
            )
          </span>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-full rounded-lg">
          <div className="mb-5 ml-5 flex">
            <p className="project-details-summary">
              Stablecoin (
              {stableCoinheaderData?.COINGECKO?.name === undefined
                ? ""
                : stableCoinheaderData?.COINGECKO?.name}
              )
            </p>
          </div>

          <div className="project-details-border"></div>

          <div className="ml-5 flex mb-5">
            <div className="flex gap-2 project-details-title">
              <img
                src={
                  stableCoinheaderData?.COINGECKO?.image?.small &&
                  stableCoinheaderData?.COINGECKO?.image?.small
                }
                alt=""
                className="w-8"
              />
              {stableCoinheaderData?.COINGECKO?.name === undefined
                ? ""
                : stableCoinheaderData?.COINGECKO?.name}
            </div>

            <span className="projectlbr flex">
              <img src={ethereum} alt="" className="w-5 h-5 ml-10" />
            </span>
            <span>
              {stableCoinheaderData?.COINGECKO?.contract_address &&
                stableCoinheaderData?.COINGECKO?.contract_address}
            </span>
            <span>
              <img src={copy} alt="" className="ml-3" />
            </span>

            <div className="flex flex-1 justify-end project-details-supported-chain">
              Supported Chain
            </div>
          </div>

          <div className="flex">
            <div className="token-due-desc" style={{ width: "70%" }}>
              <p>
                {stableCoinheaderData?.COINGECKO?.description?.en &&
                  stableCoinheaderData?.COINGECKO?.description?.en}
              </p>
            </div>
            <div className="flex flex-1 justify-end">
              {projectDetails.CHAINLIST?.map((item, index) => (
                <div key="index">
                  {item === "Ethereum" ? (
                    <img src={ethereum} alt="" className="chain-eth -mt-5" />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* menu */}
      <div className="flex gap-10 pl-20 mr-10 drop-shadow-md">
        <ul className="flex items-center gap-8">
          <li
            className={
              step === 1 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={ratingOverview}
          >
            <Link to={""}>Smart Contract Security</Link>
            <div className={step === 1 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 2 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={projectInfo}
          >
            <Link to={""}>Token Info</Link>
            <div className={step === 2 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 3 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={latestNews}
          >
            <Link to={""}>Collateralization</Link>
            <div className={step === 3 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 4 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={audit}
          >
            <Link to={""}>Liquidity Pools Price</Link>
            <div className={step === 4 ? "active" : ""}></div>
          </li>
        </ul>
      </div>
      {/* end of menu */}

      <div className={`${step === 1 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Smart Contracts Info</p>
            </div>

            <div className="project-details-border"></div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">Contract Type</p>
              <p className="token-due-smart-contract-token">Token Contract</p>
            </div>

            <div className="project-details-border"></div>

            <div className="flex">
              <div className="token-contract-title">Token Contract Address</div>
              <div className=" flex flex-1 justify-end">
                <button onClick={toggleAccordion}>
                  {isExpanded ? (
                    <MdKeyboardArrowUp className="token-contract-arrow" />
                  ) : (
                    <MdKeyboardArrowDown className="token-contract-arrow" />
                  )}
                </button>
              </div>
            </div>

            <div>
              {isExpanded && (
                <div className=" mt-5">
                  {projectDetails.TOKEN_CONTRACTS?.map((item, index) => (
                    <div key="index" className="mt-5">
                      <div className="flex">
                        <div className="flex token-contract-details">
                          {item.PROJECT_PLATFORM === "Ethereum" ? (
                            <img src={ethereum} alt="" className="w-5 h-5" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="ml-2">
                          <Link to={"/TokenContractInfo"}>
                            {item.PROJECT_NAME}
                          </Link>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="flex token-contract-address">
                          {item.PROJECT_ADDRESS}
                        </div>
                        <div className="ml-2">
                          <img
                            src={copy}
                            alt=""
                            className="mt-1 cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="project-details-border"></div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">Latest Version</p>
              <p className="token-due-smart-latest-scan">v0.5.3</p>
            </div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">
                Latest Scanning Time Stamp
              </p>
              <p className="token-due-smart-latest-scan">
                10 FEB 2023 10:00AM UTC
              </p>
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* Overall Risk */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Overall Risk</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                <div className="w-1/4">
                  <OverallRishGraph
                    issueD={overallRisk == null ? [] : overallRisk}
                  />
                </div>
                <div className="w-1/4">
                  {overallRisk.map((item, index) => (
                    <div key="index">
                      <div className="flex gap-2 mt-3">
                        <div
                          className={`${
                            item.TITLE === "High"
                              ? style.critical
                              : item.TITLE === "Medium"
                              ? style.moderate
                              : item.TITLE === "Low"
                              ? style.minor
                              : item.TITLE === "Informational"
                              ? style.informational
                              : ""
                          }`}
                        ></div>
                        <div className="critical-title">
                          {item.TITLE} ({item.value})
                        </div>
                        <div className="token-diligence-info">
                          <MdInfo />
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="flex gap-2 mt-4">
                    <div className="moderate flex"></div>
                    <div className="critical-title">Moderate (1)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <div className="minor flex"></div>
                    <div className="critical-title">Minor (7)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <div className="informational flex"></div>
                    <div className="critical-title">Informational (8)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}

        {/* Issue Report */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Issue Report</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              {Object.keys(issue).map((key, indexxx) => (
                <div className="mb-4 mr-10 rounded-lg">
                  <div
                    key={indexxx}
                    className="text-lg cursor-pointer issue-title"
                    onClick={() => toogleAccordionxx(indexxx)}
                  >
                    <div className="flex item-start">
                      <h2 className="issue-main-sub-title flex">{key}</h2>
                      <div
                        className={`${
                          descStatus[key][1] === "High"
                            ? style.high
                            : descStatus[key][1] === "Medium"
                            ? style.med
                            : descStatus[key][1] === "Low"
                            ? style.low
                            : descStatus[key][1] === "Informational"
                            ? style.info
                            : descStatus[key][1] === "Optimization"
                            ? style.obti
                            : ""
                        }`}
                      >
                        {descStatus[key][1] === "High"
                          ? "Critical"
                          : descStatus[key][1] === "Medium"
                          ? "Moderate"
                          : descStatus[key][1] === "Low"
                          ? "Minor"
                          : descStatus[key][1] === "Informational"
                          ? "Informational"
                          : descStatus[key][1] === "Optimization"
                          ? "Optimization"
                          : ""}
                      </div>
                    </div>
                    <div className="issue-main-desc">
                      {descStatus
                        ? descStatus[key]
                          ? descStatus[key][0]
                          : ""
                        : ""}
                    </div>
                  </div>
                  <div className="issue-button">
                    <Link to={"/IssueDetails"}>Click to view the details</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* end of Issue Report */}
      </div>

      <div className={`${step === 2 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="ml-5 flex mb-5">
              <div className="flex gap-2 project-details-title">
                <img
                  src={
                    stableCoinheaderData?.COINGECKO?.image?.small &&
                    stableCoinheaderData?.COINGECKO?.image?.small
                  }
                  alt=""
                  className="w-8"
                />
                {stableCoinheaderData?.COINGECKO?.name &&
                  stableCoinheaderData?.COINGECKO?.name}
              </div>
              <span className="projectlbr flex">
                {stableCoinheaderData?.COINGECKO?.name &&
                  stableCoinheaderData?.COINGECKO?.name}
              </span>

              <div className="flex flex-1 justify-end project-details-supported-chain">
                Supported Chain
              </div>
            </div>
            <div className="flex">
              <div className="projectlsd">Stablecoin</div>
              <div className="flex flex-1 justify-end">
                <img src={ethereum} alt="" className="chain-eth -mt-5" />
              </div>
            </div>

            <div className="flex gap-4 mt-10 pl-5">
              <div className="w-1/3">
                <div className="token-price">Token Price</div>
                <div className="flex">
                  <div className="flex token-price-value">
                    $
                    {
                      stableCoinheaderData?.COINGECKO?.market_data
                        ?.current_price?.usd
                    }
                  </div>
                  {stableCoinheaderData?.COINGECKO?.market_data
                    ?.price_change_24h &&
                  stableCoinheaderData?.COINGECKO?.market_data
                    ?.price_change_24h > 0 ? (
                    <p className="token-rising-value">
                      +
                      {stableCoinheaderData?.COINGECKO?.market_data
                        ?.price_change_24h * 100 &&
                        stableCoinheaderData?.COINGECKO?.market_data
                          ?.price_change_24h * 100}
                    </p>
                  ) : (
                    <p className="token-down-value">
                      {stableCoinheaderData?.COINGECKO?.market_data
                        ?.price_change_24h &&
                        stableCoinheaderData?.COINGECKO?.market_data
                          ?.price_change_24h &&
                        (
                          stableCoinheaderData?.COINGECKO?.market_data
                            ?.price_change_24h * 100
                        ).toFixed(2)}
                      %
                    </p>
                  )}
                </div>

                <div>
                  <div className="flex">
                    <div className="w-1/2 flex">
                      <div className="market-cap flex"></div>
                      <div className="ml-2 token-price">Market Cap</div>
                    </div>

                    <div className="w-1/2">
                      $
                      {stableCoinheaderData?.COINGECKO?.market_data?.market_cap
                        ?.usd &&
                        formatNumberWithCommas(
                          stableCoinheaderData?.COINGECKO?.market_data
                            ?.market_cap?.usd
                        )}
                    </div>
                  </div>

                  <div className="flex mt-3">
                    <div className="w-1/2 flex">
                      <div className="lbr"></div>
                      <div className="ml-2 token-price">Circulating Supply</div>
                    </div>

                    <div className="1/2">
                      $
                      {stableCoinheaderData?.COINGECKO?.market_data
                        ?.circulating_supply &&
                        formatNumberWithCommas(
                          stableCoinheaderData?.COINGECKO?.market_data
                            ?.circulating_supply
                        )}
                    </div>
                  </div>

                  <div className="flex mt-3">
                    <div className="w-1/2 flex">
                      <div className="trading-volume"></div>
                      <div className="ml-2 token-price">24h Trading Volume</div>
                    </div>
                    <div className="w-1/2">
                      $
                      {stableCoinheaderData?.COINGECKO?.market_data
                        ?.total_volume.usd &&
                        formatNumberWithCommas(
                          stableCoinheaderData?.COINGECKO?.market_data
                            ?.total_volume.usd
                        )}
                    </div>
                  </div>

                  <div className="flex mt-3">
                    <div className="w-1/2 flex">
                      <div className="fully-diluted"></div>
                      <div className="ml-2 token-price flex">
                        Fully Diluted Valuation
                        <MdInfo
                          className="mt-1 ml-2"
                          title="Hello"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Text will come here"
                        />
                      </div>
                      <Tooltip id="my-tooltip" />
                    </div>
                    <div className="w-1/2">
                      ${" "}
                      {stableCoinheaderData?.COINGECKO?.market_data
                        ?.fully_diluted_valuation?.usd &&
                        formatNumberWithCommas(
                          stableCoinheaderData?.COINGECKO?.market_data
                            ?.fully_diluted_valuation?.usd
                        )}
                    </div>
                  </div>

                  <div className="flex mt-3">
                    <div className="w-1/2 flex">
                      <div className="fee"></div>
                      <div className="ml-2 token-price flex">
                        Max Supply
                        <MdInfo
                          className="mt-1 ml-2"
                          title="Hello"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Text will come here"
                        />
                      </div>
                      <Tooltip id="my-tooltip" />
                    </div>
                    <div className="w-1/2">
                      {stableCoinheaderData?.COINGECKO?.market_data
                        ?.max_supply === null
                        ? ""
                        : `$${stableCoinheaderData?.COINGECKO?.market_data?.max_supply}`}
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-2/3">
                <div className="flex w-full">
                  <div className="flex ml-8" onClick={updateTokenPrice}>
                    <div
                      className={
                        stepLine === 1
                          ? "flex token-active"
                          : "flex token-inactive"
                      }
                    ></div>
                    <div
                      className={
                        stepLine === 1
                          ? "line-token-name-active cursor-pointer"
                          : "line-token-name cursor-pointer"
                      }
                    >
                      Token Price
                    </div>
                  </div>

                  <div className="flex ml-3" onClick={updateMarketCap}>
                    <div
                      className={
                        stepLine === 2
                          ? "flex token-active"
                          : "flex token-inactive"
                      }
                    ></div>
                    <div
                      className={
                        stepLine === 2
                          ? "line-token-name-active cursor-pointer"
                          : "line-token-name cursor-pointer"
                      }
                    >
                      Mcap
                    </div>
                  </div>

                  <div className="flex ml-3" onClick={updateVolume}>
                    <div
                      className={
                        stepLine === 3
                          ? "flex token-active"
                          : "flex token-inactive"
                      }
                    ></div>
                    <div
                      className={
                        stepLine === 3
                          ? "line-token-name-active cursor-pointer"
                          : "line-token-name cursor-pointer"
                      }
                    >
                      24h Volume
                    </div>
                  </div>

                  <div className="flex ml-3">
                    <div className="flex-1 line-token-name">Daily</div>
                  </div>

                  <div className="flex ml-3">
                    <div className="flex-1 line-token-name">Weekly</div>
                  </div>

                  <div className="flex ml-3">
                    <div className="flex-1 line-token-name">Monthly</div>
                  </div>
                </div>
                <div className="w-full">
                  <div className={`${stepLine === 1 ? "block" : "hidden"}`}>
                    {stableCoinGraph?.length === 0 ? (
                      ""
                    ) : (
                      <StableTokenGraph
                        data={
                          stableCoinGraph === undefined ? [] : stableCoinGraph
                        }
                      />
                    )}
                  </div>

                  <div className={`${stepLine === 2 ? "block" : "hidden"}`}>
                    {marketCap.length === 0 ? (
                      ""
                    ) : (
                      <StableMarketCap
                        data={marketCap === undefined ? [] : marketCap}
                      />
                    )}
                  </div>

                  <div className={`${stepLine === 3 ? "block" : "hidden"}`}>
                    {stableCoinVolume.length === 0 ? (
                      ""
                    ) : (
                      <StableCoinVolume
                        data={
                          stableCoinVolume === undefined ? [] : stableCoinVolume
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* TVL Analysis */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">
                eUSD Mint and Burn Ratio
              </p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                {emint.length === 0 ? "" : <EUSDMintGraph data={emint} />}
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}
      </div>

      <div className={`${step === 3 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Collateralization</p>
            </div>
            <div className="project-details-border"></div>

            <div className="flex gap-4 mt-10 -ml-10">
              <div className="w-1/3" style={{ height: "150px" }}>
                <HealthCheck />
              </div>

              <div className="w-2/3">
                <div className="w-full">
                  <div className="flex gap-2">
                    <div className="flex">
                      <div className="healthCheck"></div>
                    </div>
                    <div className="privacy-title">
                      Average Collateral Ratio
                    </div>
                  </div>
                  <p className="stableccoin-desc">
                    1 eUSD is backed by at least $1.5 worth of ETH/stETH as
                    collateral, ensuring stability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* Token Unlock Progress */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">eUSD Max Supply</p>
            </div>

            <div className="project-details-border"></div>
            <div className=" px-2 py-4 rounded-lg drop-shadow-md pl-10">
              <div className="flex mt-5">
                <div className="w-full">
                  {eUSDData.length === 0 ? (
                    ""
                  ) : (
                    <EUSDMaxSuppy
                      data={
                        eUSDData === null
                          ? []
                          : eUSDData === undefined
                          ? []
                          : eUSDData
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of Token Unlock Progress */}
      </div>

      {/* Market Prices */}
      <div className={`${step === 4 ? "block" : "hidden"}`}>
        {/* Issue Report */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">eUSD market</p>
            </div>
            <div className="project-details-border"></div>

            {stableCoins.length === 0 ? (
              ""
            ) : (
              <EUSDTable
                data={
                  stableCoins === null
                    ? []
                    : stableCoins === undefined
                    ? []
                    : stableCoins
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* End of Market prices */}
    </div>
  );
};

export default StableCoin;
