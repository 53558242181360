export const organizations = [
  {
    id: 1,
    name: "Tezpur",
    urlParamName: "Tezpur",
  },
  {
    id: 2,
    name: "BEAM +",
    urlParamName: "BEAM +",
  },
  {
    id: 3,
    name: "Hong Kong",
    urlParamName: "Hong Kong",
  },
];

export const role = [
  {
    id: 1,
    name: "Investor",
    urlParamName: "Investor",
  },
  {
    id: 2,
    name: "Developer",
    urlParamName: "Developer",
  },
];

export const networks = [
  {
    id: 1,
    name: "Ethereum",
    urlParamName: "Ethereum",
  },
  {
    id: 2,
    name: "Polygon",
    urlParamName: "Polygon",
  },
  {
    id: 3,
    name: "Binance Smart Chain",
    urlParamName: "Binance Smart Chain",
  },
];
