import React, { useState, useEffect } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardBackspace,
  MdFileCopy,
  MdCloudDownload,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProjectPie from "./ProjectPie";
import axios from "axios";
import ScanningHistory from "./ScanningHistory";
import ProjectHealth from "./ProjectHealth";
import { useNavigate } from "react-router-dom";
import MainLoader from "./MainLoader";
import HbBar from "./HbBar";
import HeaderScanning from "./HeaderScanning";
import Footer from "./Footer";
import ethereum from "../img/eth.png";
import arbitrum from "../img/arbitrum.png";
import avalanche from "../img/avalanche.png";
import fantom from "../img/fantom.png";
import optimism from "../img/Optimism.png";
import polygon1 from "../img/polygon1.png";
const style = {
  high: `bg-[#E6D2D2] rounded-md text-[#E42528] text-center flex pl-2 w-[15%] text-details-issue `,
  medium: `bg-[#E8F2FF] rounded-md text-[#2F72F5] text-center flex pl-3 w-[20%] text-details-issue `,
  low: `bg-[#D0E2DC] rounded-md text-[#16C683] text-center flex pl-3 w-[12%] text-details-issue`,
  information: `bg-[#E0E0E1] rounded-md text-[#5A5A5A] text-center flex pl-3 w-[24%] text-details-issue `,
  optimization: `bg-[#D7D1DA] rounded-md text-white text-[#4D1C71] flex pl-3 w-[25%] text-details-issue `,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
  displayBlock: `display:block`,
  displayNone: `display:none`,
};

const ProjectDetailsGroup = () => {
  const navigate = useNavigate();
  const id = sessionStorage.getItem("id");
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  // Group
  const [projectDetailsGroup, setProjectDetailsGroup] = useState([]);
  const [step, setStep] = useState(1);
  const [chainList, setChainList] = useState([]);
  const [totalData, SetTotalData] = useState({});
  const [tokenData, SetTokenData] = useState({});
  const [projectName, setProjectName] = useState(0);
  const [icon, SetIcon] = useState(0);
  const [projectDesc, setProjectDesc] = useState(0);
  const [functionalData, SetFunctionalData] = useState({});
  const [totalBarValues, setTotalBarValues] = useState([]);
  const [tokenBarValues, setTokenBarValues] = useState([]);
  const [functionalBarValues, setFunctionalBarValues] = useState([]);
  const [tokenHsbar, setTokenHsbar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [totalTrailer, setTotalTrailer] = useState([]);
  const [tokenTrailer, SetTokenTrailer] = useState([]);
  const [functinalTrailer, SetFunctinalTrailer] = useState([]);
  const [totalHsbar, setTotalHsbar] = useState([]);

  const [scanTotal, setScanTotal] = useState([]);
  const [scanToken, setScanToken] = useState([]);
  const [scanFunctional, setScanFunctional] = useState([]);
  const [functionalHsbar, SetFunctionalHsbar] = useState([]);

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchDataGroup(myEmail);
  }, []);

  const fetchDataGroup = async (myEmail) => {
    setIsLoading(true);
    // const id = "320230618160937";
    // const id = "320230618225405";
    console.log(id);
    const platform = "Ethereum";
    axios
      .get("PROJECT_DESCRIPTION_GROUP", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log("**Group**");
        console.log(res.data);
        setProjectDetailsGroup(res.data);
        setProjectName(res.data.NAME);
        setProjectDesc(res.data.DESCIPTION);
        SetTokenData(res.data.TOKEN_DATA);
        SetTotalData(res.data.TOTAL_DATA);
        SetIcon(res.data.TOTAL_DATA.ICON);
        SetFunctionalData(res.data.FUNCTION_DATA);
        SetTokenTrailer(res.data.TOKEN_DATA.TAILOR_ISSUES);
        setTotalTrailer(res.data.TOTAL_DATA.TAILOR_ISSUES);
        SetFunctinalTrailer(res.data.FUNCTION_DATA.TAILOR_ISSUES);
        setScanTotal(res.data.TOTAL_DATA.SCAN_INFO_TOKEN);
        setScanToken(res.data.TOKEN_DATA.SCAN_INFO_TOKEN);
        setScanFunctional(res.data.FUNCTION_DATA.SCAN_INFO_FUN);
        setTotalBarValues(res.data.TOTAL_DATA.BAR_VALUES);
        setFunctionalBarValues(res.data.FUNCTION_DATA.BAR_VALUES);
        setTokenHsbar(res.data.TOKEN_DATA.BAR_VALUES_PROF);
        SetFunctionalHsbar(res.data.FUNCTION_DATA.BAR_VALUES_PROF);
        setTotalHsbar(res.data.TOTAL_DATA.BAR_VALUES_PROF);
        setChainList(res.data.CHAINLIST);
        setIsLoading(false);
      });
  };

  const viewDetails = (newid) => {
    sessionStorage.setItem("id", newid);
    sessionStorage.setItem("projectName", projectName);
    sessionStorage.setItem("projectDesc", projectDesc);
    sessionStorage.setItem("icon", icon);
    navigate(`/ProjectDetailsGroupSingle`);
    console.log(newid);
    console.log(projectName);
  };

  function handleNext() {
    setStep(1);
  }
  function handleToken() {
    setStep(2);
  }
  function handleFunctional() {
    setStep(3);
  }
  return (
    <div>
      <HeaderScanning />
      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          {id.startsWith("2") ? "Portfolio" : "Scanning"}{" "}
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">Project Details</span>
        </div>

        {/* <div className="flex items-end">
          <Link
            to=""
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 gap-2 text-white rounded-lg px-10 py-3 text-lg profile-button"
          >
            <MdCloudDownload className="text-2xl flex" />
            Download Report
          </Link>
        </div> */}
      </div>

      <div className="flex pl-20 gap-4 mt-3">
        <Link to={"/Portfolio"}>
          <MdKeyboardBackspace className="text-2xl" />
        </Link>
        Project Details
      </div>

      <div className="flex gap-10 px-20 mr-10 py-5 drop-shadow-md">
        <div className=" bg-white py-4 w-full rounded-lg project-summary-section">
          <div className="mb-5 ml-5 project-details-summary">
            Project Summary
            <div className="project-details-border"></div>
          </div>
          <div className="ml-5 flex mb-5">
            <div className="flex flex-1 gap-2 project-details-title">
              <img
                src={totalData.ICON !== undefined ? totalData.ICON : ""}
                alt={projectDetailsGroup.NAME}
              />
              {projectDetailsGroup.NAME}
            </div>

            <div className="justify-end mr-12 project-details-chain">
              <p className="">Other Supported Chain</p>

              <div className="flex gap-1">
                {chainList.map((item, index) => (
                  <div>
                    {item === "Arbitrum" ? <img src={arbitrum} alt="" /> : ""}
                    {item === "Avalanche" ? <img src={avalanche} alt="" /> : ""}
                    {item === "Ethereum" ? <img src={ethereum} alt="" /> : ""}
                    {item === "Fantom" ? <img src={fantom} alt="" /> : ""}
                    {item === "Optimism" ? <img src={optimism} alt="" /> : ""}
                    {item === "Polygon" ? <img src={polygon1} alt="" /> : ""}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="ml-5 project-small-title mb-2">
            <p> Project Description</p>
          </div>

          <div className="ml-5 flex mb-10 project-group-desc">
            {projectDetailsGroup.DESCIPTION
              ? projectDetailsGroup.DESCIPTION
              : null}
          </div>
        </div>
      </div>

      {/* menu */}

      <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md">
        <ul className="flex items-center gap-8">
          <li
            style={{ cursor: "pointer" }}
            className={step === 1 ? "menu-active-group" : "menu-deactive-group"}
            onClick={handleNext}
          >
            General
            <div
              className={step === 1 ? "active-group" : "menu-deactive-group"}
            ></div>
          </li>
          <li
            style={{ cursor: "pointer" }}
            className={step === 2 ? "menu-active-group" : "menu-deactive-group"}
            onClick={handleToken}
          >
            Token Contract
            <div
              className={step === 2 ? "active-group" : "menu-deactive-group"}
            ></div>
          </li>
          <li
            style={{ cursor: "pointer" }}
            className={step === 3 ? "menu-active-group" : "menu-deactive-group"}
            onClick={handleFunctional}
          >
            Functional Contract
            <div
              className={step === 3 ? "active-group" : "menu-deactive-group"}
            ></div>
          </li>
        </ul>
      </div>

      {/* end of menu */}

      <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
          <div className="flex flex-1">
            <p className="flex flex-1 items-start gap-2 pb-3 ml-5 project-details-summary">
              Smart Contracts Info
            </p>
            <p>
              {/* <p
                className="mr-5 cursor-pointer report-button"
                onClick={() => viewDetails(id)}
              >
                View Contract Report
              </p> */}
            </p>
          </div>
          <div className="project-details-border -mt-2"></div>

          <div className="ml-5 project-small-title">
            <p> Contract Type</p>
          </div>

          <div className="ml-5 project-details-group-title flex mb-5">
            <h3 className="text-2xl flex flex-1 gap-2">General</h3>
          </div>

          <div className="ml-5 project-small-title">
            <p> Token Type</p>
          </div>

          <div className="ml-5 project-details-group-title flex mb-5">
            <h3 className="text-2xl flex flex-1 gap-2">ERC 20</h3>
          </div>

          <div className="project-details-border"></div>

          <div className={`${step === 1 ? "block" : "hidden"}`}>
            {Object.keys(projectDetailsGroup).map((key, index) => (
              <div
                // className="ml-5 project-small-title mb-3"
                key={index}
                className={`text-lg cursor-pointer issue-title`}
                onClick={() => toogleAccordion(index)}
              >
                <h2
                  className={`token-contract-title accordion === index ? "active-issue" : ""`}
                >
                  <div>
                    {key === "TOKENS_CONTRACTS"
                      ? "Token Contract Address"
                      : key === "FUNCTIONAL_CONTRACTS"
                      ? `Functional Contract Address`
                      : ""}
                  </div>
                  <div className="flex item-start">
                    {/* <div className="flex cursor-pointer issue-details-title">
                    {key}
                  </div> */}
                    <div className="flex ml-auto text-right group-details-arrow">
                      {key === "TOKENS_CONTRACTS" ? (
                        <MdKeyboardArrowDown />
                      ) : key === "FUNCTIONAL_CONTRACTS" ? (
                        <MdKeyboardArrowDown />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </h2>
                <div
                  className={accordion === index ? "active-issue" : "inactive"}
                >
                  {Object.keys(projectDetailsGroup[key]).map((keyy, indexx) => (
                    <div className="p-2 mb-4 mr-10 rounded-lg">
                      <div
                        key={indexx}
                        className="text-lg cursor-pointer issue-title"
                        onClick={() => toogleAccordionx(indexx)}
                      >
                        <h2
                          className={
                            accordionx === indexx ? "active-issue" : ""
                          }
                        >
                          <div className="flex flex-1 item-start">
                            <div className="flex flex-1 cursor-pointer group-details-title">
                              {
                                projectDetailsGroup[key][keyy]
                                  .PROJECT_DESCRIPTION
                              }
                            </div>
                          </div>
                          <div className="flex">
                            <div
                              className=" flex flex-1 group-details-sub-title"
                              onClick={() =>
                                viewDetails(
                                  projectDetailsGroup[key][keyy].SUB_PROJECT_ID
                                )
                              }
                            >
                              {projectDetailsGroup[key][keyy].PROJECT_ADDRESS}
                              {/* {projectDetailsGroup[key][keyy].SUB_PROJECT_ID} */}
                            </div>
                            <div className="mr-10">
                              <MdFileCopy />
                            </div>
                          </div>
                        </h2>
                      </div>
                    </div>
                  ))}
                </div>
                <div />
              </div>
            ))}
            {/* End of Token Contract Address */}
          </div>

          <div className={`${step === 2 ? "block" : "hidden"}`}>
            {Object.keys(projectDetailsGroup).map((key, index) => (
              <div
                // className="ml-5 project-small-title mb-3"
                key={index}
                className={`text-lg cursor-pointer issue-title`}
                onClick={() => toogleAccordion(index)}
              >
                <h2
                  className={`token-contract-title accordion === index ? "active-issue" : ""`}
                >
                  <div>
                    {key === "TOKENS_CONTRACTS" ? "Token Contract Address" : ""}
                  </div>
                  <div className="flex item-start">
                    {/* <div className="flex cursor-pointer issue-details-title">
                    {key}
                  </div> */}
                    <div className="flex ml-auto text-right group-details-arrow">
                      {key === "TOKENS_CONTRACTS" ? (
                        <MdKeyboardArrowDown />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </h2>
                <div
                  className={accordion === index ? "active-issue" : "inactive"}
                >
                  {Object.keys(projectDetailsGroup[key]).map((keyy, indexx) => (
                    <div className="p-2 mb-4 mr-10 rounded-lg">
                      <div
                        key={indexx}
                        className="text-lg cursor-pointer issue-title"
                        onClick={() => toogleAccordionx(indexx)}
                      >
                        <h2
                          className={
                            accordionx === indexx ? "active-issue" : ""
                          }
                        >
                          <div className="flex flex-1 item-start">
                            <div className="flex flex-1 cursor-pointer group-details-title">
                              {
                                projectDetailsGroup[key][keyy]
                                  .PROJECT_DESCRIPTION
                              }
                            </div>
                          </div>
                          <div className="flex">
                            <div
                              className=" flex flex-1 group-details-sub-title"
                              onClick={() =>
                                viewDetails(
                                  projectDetailsGroup[key][keyy].SUB_PROJECT_ID
                                )
                              }
                            >
                              {projectDetailsGroup[key][keyy].PROJECT_ADDRESS}
                            </div>
                            <div className="mr-10">
                              <MdFileCopy />
                            </div>
                          </div>
                        </h2>
                      </div>
                    </div>
                  ))}
                </div>
                <div />
              </div>
            ))}
            {/* End of Token Contract Address */}
          </div>

          <div className={`${step === 3 ? "block" : "hidden"}`}>
            {Object.keys(projectDetailsGroup).map((key, index) => (
              <div
                // className="ml-5 project-small-title mb-3"
                key={index}
                className={`text-lg cursor-pointer issue-title`}
                onClick={() => toogleAccordion(index)}
              >
                <h2
                  className={`token-contract-title accordion === index ? "active-issue" : ""`}
                >
                  <div>
                    {key === "FUNCTIONAL_CONTRACTS"
                      ? `Functional Contract Address`
                      : ""}
                  </div>
                  <div className="flex item-start">
                    {/* <div className="flex cursor-pointer issue-details-title">
                    {key}
                  </div> */}
                    <div className="flex ml-auto text-right group-details-arrow">
                      {key === "FUNCTIONAL_CONTRACTS" ? (
                        <MdKeyboardArrowDown />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </h2>
                <div
                  className={accordion === index ? "active-issue" : "inactive"}
                >
                  {Object.keys(projectDetailsGroup[key]).map((keyy, indexx) => (
                    <div className="p-2 mb-4 mr-10 rounded-lg">
                      <div
                        key={indexx}
                        className="text-lg cursor-pointer issue-title"
                        onClick={() => toogleAccordionx(indexx)}
                      >
                        <h2
                          className={
                            accordionx === indexx ? "active-issue" : ""
                          }
                        >
                          <div className="flex flex-1 item-start">
                            <div className="flex flex-1 cursor-pointer group-details-title">
                              {
                                projectDetailsGroup[key][keyy]
                                  .PROJECT_DESCRIPTION
                              }
                            </div>
                          </div>
                          <div className="flex">
                            <div
                              className=" flex flex-1 group-details-sub-title"
                              onClick={() =>
                                viewDetails(
                                  projectDetailsGroup[key][keyy].SUB_PROJECT_ID
                                )
                              }
                            >
                              {projectDetailsGroup[key][keyy].PROJECT_ADDRESS}
                            </div>
                            <div className="mr-10">
                              <MdFileCopy />
                            </div>
                          </div>
                        </h2>
                      </div>
                    </div>
                  ))}
                </div>
                <div />
              </div>
            ))}
            {/* End of Token Contract Address */}
          </div>
        </div>

        {/* Total Project overview*/}
        <div className={`${step === 1 ? "block" : "hidden"}`}>
          <div className=" bg-white px-2 w-full py-4 rounded-lg">
            <div className="project-details-summary ml-5">
              Overview
              <div className="project-details-border"></div>
            </div>

            <div className="ml-5 text-gray-500">
              <p> Project Name</p>
            </div>

            <div className="ml-5 text-violet-900 flex mb-5">
              <h3 className="text-2xl flex flex-1 gap-2 project-details-title">
                <img
                  src={totalData.ICON !== undefined ? totalData.ICON : ""}
                  alt={projectDetailsGroup.NAME}
                  className="w-8"
                />
                {projectDetailsGroup.NAME}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Number of Smart Contracts</p>
              <h3 className="flex flex-1 gap-2 project-details-title">
                {totalData.NO_CONTRACT_TOKEN}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Completed Scan</p>
              <h3 className="flex flex-1 gap-2 project-details-title">
                {totalData.NO_OF_SCAN}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Risk Level</p>
              <p
                className={`${
                  totalData.RISK === "High"
                    ? style.high
                    : totalData.RISK === "Informational"
                    ? style.information
                    : totalData.RISK === "Medium"
                    ? style.medium
                    : totalData.RISK === "Low"
                    ? style.low
                    : totalData.RISK === "Optimization"
                    ? style.optimization
                    : ""
                }`}
              >
                {totalData.RISK}
              </p>
            </div>

            <div className="ml-5"></div>

            <div className="ml-5 project-small-title">
              <p>No. of Issues</p>
              <h3 className="flex flex-1 gap-2 project-details-title mb-2">
                {totalData.TOTAL !== undefined ? totalData.TOTAL : 0}
              </h3>
            </div>

            <div className="ml-5 mr-5">
              <HbBar hbar={totalHsbar == null ? [] : totalHsbar} />
            </div>
          </div>
        </div>
        {/* end total overview*/}

        {/* Token Project overview*/}
        <div className={`${step === 2 ? "block" : "hidden"}`}>
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="project-details-summary ml-5">
              Overview (Token)
              <div className="project-details-border"></div>
            </div>

            <div className="ml-5 text-gray-500">
              <p> Project Name</p>
            </div>

            <div className="ml-5 text-violet-900 flex mb-5">
              <h3 className="text-2xl flex flex-1 gap-2 project-details-title">
                <img
                  src={totalData.ICON !== undefined ? totalData.ICON : ""}
                  alt={projectDetailsGroup.NAME}
                  className="w-8"
                />
                {projectDetailsGroup.NAME}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Number of Smart Contracts</p>
              <h3 className="flex flex-1 gap-2 project-details-title">
                {tokenData.NO_CONTRACT_TOKEN}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Completed Scan</p>
              <h3 className="flex flex-1 gap-2 project-details-title">
                {tokenData.NO_OF_SCAN}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Risk Level</p>
              <p
                className={`${
                  tokenData.RISK === "High"
                    ? style.high
                    : tokenData.RISK === "Informational"
                    ? style.information
                    : tokenData.RISK === "Medium"
                    ? style.medium
                    : tokenData.RISK === "Low"
                    ? style.low
                    : tokenData.RISK === "Optimization"
                    ? style.optimization
                    : ""
                }`}
              >
                {tokenData.RISK}
              </p>
            </div>

            <div className="ml-5"></div>

            <div className="ml-5 project-small-title">
              <p>No. of Issues</p>
              <h3 className="flex flex-1 gap-2 project-details-title mb-2">
                {tokenData.TOTAL !== undefined ? tokenData.TOTAL : 0}
              </h3>
            </div>

            <div className="ml-5 mr-5">
              <HbBar hbar={tokenHsbar == null ? [] : tokenHsbar} />
            </div>
          </div>
        </div>
        {/* end Token overview*/}

        {/* Functional Project overview*/}
        <div className={`${step === 3 ? "block" : "hidden"}`}>
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="project-details-summary ml-5">
              Overview (Functional)
              <div className="project-details-border"></div>
            </div>

            <div className="ml-5 text-gray-500">
              <p> Project Name</p>
            </div>

            <div className="ml-5 text-violet-900 flex mb-5">
              <h3 className="text-2xl flex flex-1 gap-2 project-details-title">
                <img
                  src={totalData.ICON !== undefined ? totalData.ICON : ""}
                  alt={projectDetailsGroup.NAME}
                  className="w-8"
                />
                {projectDetailsGroup.NAME}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Number of Smart Contracts</p>
              <h3 className="flex flex-1 gap-2 project-details-title">
                {functionalData.NO_CONTRACT_FUN}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Completed Scan</p>
              <h3 className="flex flex-1 gap-2 project-details-title">
                {functionalData.NO_OF_SCAN}
              </h3>
            </div>

            <div className="ml-5 mb-5">
              <p className="project-small-title"> Risk Level</p>
              <p
                className={`${
                  functionalData.RISK === "High"
                    ? style.high
                    : functionalData.RISK === "Informational"
                    ? style.information
                    : functionalData.RISK === "Medium"
                    ? style.medium
                    : functionalData.RISK === "Low"
                    ? style.low
                    : functionalData.RISK === "Optimization"
                    ? style.optimization
                    : ""
                }`}
              >
                {functionalData.RISK}
              </p>
            </div>

            <div className="ml-5"></div>

            <div className="ml-5 project-small-title">
              <p>No. of Issues</p>
              <h3 className="flex flex-1 gap-2 project-details-title mb-2">
                {functionalData.TOTAL !== undefined ? functionalData.TOTAL : 0}
              </h3>
            </div>

            <div className="ml-5 mr-5">
              <HbBar hbar={functionalHsbar == null ? [] : functionalHsbar} />
            </div>
          </div>
        </div>
        {/* end Functional overview*/}
      </div>
      {/* General*/}
      <div className={`${step === 1 ? "block" : "hidden"}`}>
        <div className={`flex gap-10 px-20 py-5 mr-10 drop-shadow-md -mt-2`}>
          <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
            <div className="flex">
              <div className="flex flex-1 ml-5 project-details-summary">
                Overall Risk
              </div>
              <div className="flex flex-1 items-start">
                {totalData.BAR_VALUES?.map((item, index) => (
                  <div className="flex" key={index}>
                    <div
                      className={`${
                        item.TITLE === "High"
                          ? style.healthhigh
                          : item.TITLE === "Medium"
                          ? style.healthmedium
                          : item.TITLE === "Low"
                          ? style.healthlow
                          : item.TITLE === "Informational"
                          ? style.healthinformational
                          : item.TITLE === "Optimization"
                          ? style.healthoptimization
                          : ""
                      }`}
                    ></div>
                    <div className="health-title">{item.TITLE}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="project-details-border"></div>

            <div className="flex">
              <ProjectPie issueD={totalData === null ? [] : totalData} />
              <div className="total-project-details">{totalData.TOTAL}</div>
            </div>

            <div className="project-details-border w-[98%]"></div>
            <div className="flex ml-6 technical-risk-title">
              Technical Risk Item
            </div>

            <div className="flex w-full">
              <div className="flex">
                <ProjectHealth
                  trilers={totalTrailer === null ? [] : totalTrailer}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ScanningHistory scanning={scanTotal == null ? [] : scanTotal} />
        </div>
      </div>
      {/* end of General */}
      {/* Token*/}
      <div className={`${step === 2 ? "block" : "hidden"}`}>
        <div className={`flex gap-10 px-20 py-5 mr-10 drop-shadow-md -mt-2`}>
          <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
            <div className="flex">
              <div className="flex flex-1 ml-5 project-details-summary">
                Overall Risk
              </div>
              <div className="flex flex-1 items-start">
                {tokenData.BAR_VALUES?.map((item, index) => (
                  <div className="flex" key={index}>
                    <div
                      className={`${
                        item.TITLE === "High"
                          ? style.healthhigh
                          : item.TITLE === "Medium"
                          ? style.healthmedium
                          : item.TITLE === "Low"
                          ? style.healthlow
                          : item.TITLE === "Informational"
                          ? style.healthinformational
                          : item.TITLE === "Optimization"
                          ? style.healthoptimization
                          : ""
                      }`}
                    ></div>
                    <div className="health-title">{item.TITLE}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="project-details-border"></div>

            <div className="flex">
              {step === 2 ? (
                <ProjectPie issueD={tokenData === null ? [] : tokenData} />
              ) : (
                ""
              )}
              <div className="total-project-details">{tokenData.TOTAL}</div>
            </div>

            <div className="project-details-border w-[98%]"></div>
            <div className="flex ml-6 technical-risk-title">
              Technical Risk Item
            </div>

            <div className="flex w-full">
              <div className="flex">
                <ProjectHealth
                  trilers={tokenTrailer === null ? [] : tokenTrailer}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ScanningHistory scanning={scanToken == null ? [] : scanToken} />
        </div>
      </div>
      {/* end of Token */}

      {/* Functional*/}
      <div className={`${step === 3 ? "block" : "hidden"}`}>
        <div className={`flex gap-10 px-20 py-5 mr-10 drop-shadow-md -mt-2`}>
          <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
            <div className="flex">
              <div className="flex flex-1 ml-5 project-details-summary">
                Overall Risk
              </div>
              <div className="flex flex-1 items-start">
                {functionalData.BAR_VALUES?.map((item, index) => (
                  <div className="flex" key={index}>
                    <div
                      className={`${
                        item.TITLE === "High"
                          ? style.healthhigh
                          : item.TITLE === "Medium"
                          ? style.healthmedium
                          : item.TITLE === "Low"
                          ? style.healthlow
                          : item.TITLE === "Informational"
                          ? style.healthinformational
                          : item.TITLE === "Optimization"
                          ? style.healthoptimization
                          : ""
                      }`}
                    ></div>
                    <div className="health-title">{item.TITLE}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="project-details-border"></div>

            <div className="flex">
              {step === 3 ? (
                <ProjectPie
                  issueD={functionalData === null ? [] : functionalData}
                />
              ) : (
                ""
              )}
              <div className="total-project-details">
                {functionalData.TOTAL}
              </div>
            </div>

            <div className="project-details-border w-[98%]"></div>
            <div className="flex ml-6 technical-risk-title">
              Technical Risk Item
            </div>

            <div className="flex w-full">
              <div className="flex">
                <ProjectHealth
                  trilers={functinalTrailer === null ? [] : functinalTrailer}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ScanningHistory
            scanning={scanFunctional === null ? [] : scanFunctional}
          />
        </div>
      </div>
      {/* end of Functional */}

      <Footer />
    </div>
  );
};

export default ProjectDetailsGroup;
