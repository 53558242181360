import React from "react";
import { Doughnut } from "react-chartjs-2";

const FullCircle = (dataAllocation) => {
  let vestingdata = dataAllocation.dataAllocation;
  // console.log("Vesting circle");
  // console.log(vestingdata);

  if (vestingdata === undefined) {
    const constData = [
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
    ];
    vestingdata = constData;
  }

  if (vestingdata !== undefined && vestingdata.length === 0) {
    const constData = [
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
      { name: "No data", percentage: 0 },
    ];
    vestingdata = constData;
  }

  var arr = [];
  for (var i = 0; i < vestingdata.length; i++) {
    if (vestingdata[i].name === "Mining Pool") {
      arr = arr.concat(["#5A189A"]);
    } else if (vestingdata[i].name === "Ecosystem Incentives") {
      arr = arr.concat(["#9D4EDD"]);
    } else if (vestingdata[i].name === "Protocol Treasury") {
      arr = arr.concat(["#C77DFF"]);
    } else if (vestingdata[i].name === "Team") {
      arr = arr.concat(["#E0AAFF"]);
    } else if (vestingdata[i].name === "IDO") {
      arr = arr.concat(["#48BFE3"]);
    } else if (vestingdata[i].name === "Advisors") {
      arr = arr.concat(["#56CFE1"]);
    } else if (vestingdata[i].name === "LP Reserve") {
      arr = arr.concat(["#5AEDC9"]);
    } else if (vestingdata[i].name === "WL Bonuses") {
      arr = arr.concat(["#80FFDB"]);
      console.log(arr);
    }
  }

  if (arr.length === 0) {
    arr = [
      "#5A189A",
      "#9D4EDD",
      "#C77DFF",
      "#E0AAFF",
      "#48BFE3",
      "#56CFE1",
      "#5AEDC9",
      "#80FFDB",
    ];
  }

  var data = {
    labels: vestingdata?.map((x) => x.name),
    datasets: [
      {
        label: `Amount`,
        data: vestingdata?.map((x) => x.amt),
        backgroundColor: arr,
        borderColor: arr,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    plugins: {
      legend: {
        display: false, // Hide the labels
      },
    },
    cutout: 90,
  };

  return <Doughnut data={data} options={options} />;
};

export default FullCircle;
