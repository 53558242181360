import React from "react";
import HbBar from "./HbBar";

const Overview = (view) => {
  let Overview = view.view;
  if (Overview !== undefined) {
    return (
      <div className="w-full bg-white -mt-10 pt-10 pl-10 pr-32 ml-20 drop-shadow">
        <div className="flex">
          <div className="text-gray-500 flex gap-2">OVERVIEW</div>
        </div>
        <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-3">
          <div className="text-3xl">
            {Overview ? (Overview.NOLINES ? Overview.NOLINES : 0) : 0}
          </div>
          <div className="text-xl text-gray-500">Lines of code scanned</div>
        </div>
        <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-8">
          <div className="text-3xl">
            {Overview
              ? Overview.PROJECT_MONITORED
                ? Overview.PROJECT_MONITORED
                : 0
              : 0}
          </div>
          <div className="text-xl text-gray-500">Projects monitored</div>
        </div>
        <div className="flex mt-10">
          <div className="text-gray-500 flex gap-2">ISSUES</div>
        </div>
        <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-3">
          <div className="text-3xl">
            {Overview ? (Overview.TOTAL ? Overview.TOTAL : 0) : 0}
          </div>
          <div className="text-xl text-gray-500">
            No. of Vulnerabilities Found
          </div>
        </div>
        <div className="pt-20">
          {Overview.BAR_VALUES?.map((item, index) => (
            <div key={index}>
              <div className="flex">
                <div className="flex flex-1">{item.TITLE}</div>
                <p className="r-0">{item.NO}</p>
              </div>
              <HbBar hbar={item.VALUES} />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex">
        <div className="flex mt-10 rounded-sm">
          <div className="flex flex-1 text-lg">High</div>
          <div className="text-lg">0</div>
        </div>

        <div className="w-full h-2 bg-red-200 rounded-full">
          <div className="w-2 h-full text-center text-xs text-white bg-red-600 rounded-full"></div>
          <div className="w-1/2 bg-red-600"></div>
        </div>

        <div className="flex mt-5 rounded-sm">
          <div className="flex flex-1 text-lg">Medium</div>
          <div className="text-lg">0</div>
        </div>
        <div class="w-full h-2 bg-blue-200 rounded-full">
          <div class="w-1/3 h-full text-center text-xs text-white bg-blue-600 rounded-full"></div>
        </div>

        <div className="flex mt-5 rounded-sm">
          <div className="flex flex-1 text-lg">Low</div>
          <div className="text-lg">0</div>
        </div>
        <div class="w-full h-2 bg-green-200 rounded-full">
          <div class="w-1/3 h-full text-center text-xs text-white bg-green-600 rounded-full"></div>
        </div>

        <div className="flex mt-5 rounded-sm">
          <div className="flex flex-1 text-lg">Informational</div>
          <div className="text-lg">0</div>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-full">
          <div class="w-72 h-full text-center text-xs text-white bg-gray-600 rounded-full"></div>
        </div>

        <div className="flex mt-5 rounded-sm">
          <div className="flex flex-1 text-lg">Optimization</div>
          <div className="text-lg">0</div>
        </div>
      </div>
    );
  }
};

export default Overview;
