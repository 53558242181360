import React from "react";
import HSBar from "react-horizontal-stacked-bar-chart";

const HbBar = (hbar) => {
  let Hbar = hbar.hbar;

  return (
    <div>
      <HSBar height={18} data={Hbar} />
    </div>
  );
};

export default HbBar;
