import React from 'react'
import ReactDOM from "react-dom";
import HSBar from "react-horizontal-stacked-bar-chart";

const HbBarReport = (hbar) => {
    let Hbar = hbar.hbar;
    console.log(Hbar);
  return (
    <div className='mb-8'>
      <HSBar height={10} data={Hbar} id="hsbarExample"  />
    </div>
  )
}

export default HbBarReport