import React, { useState, useEffect } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardBackspace,
  MdFileCopy,
  MdCloudDownload,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ProjectPieGroupSingle from "./ProjectPieGroupSingle";
import axios from "axios";
import ScanningHistory from "./ScanningHistory";
import ProjectHealth from "./ProjectHealth";
import { useNavigate } from "react-router-dom";
import MainLoader from "./MainLoader";
import HbBar from "./HbBar";
import HeaderScanning from "./HeaderScanning";
import Footer from "./Footer";
const style = {
  submitGeneral: `py-2 rounded-3xl font-bold`,
  inactiveSubmit: `w-full mb-20 text-center mt-5 rescan-button`,
  activeSubmit: `bg-[#531b66] rounded-lg w-full mb-20 text-center mt-5 text-white`,

  high: `bg-[#E6D2D2] rounded-md text-[#E42528] text-center flex pl-2 w-[10%] text-details-issue `,
  medium: `bg-[#E8F2FF] rounded-md text-[#2F72F5] text-center flex pl-3 w-[20%] text-details-issue `,
  low: `bg-[#D0E2DC] rounded-md text-[#16C683] text-center flex pl-3 w-[12%] text-details-issue`,
  information: `bg-[#E0E0E1] rounded-md text-[#5A5A5A] text-center flex pl-3 w-[24%] text-details-issue `,
  optimization: `bg-[#D7D1DA] rounded-md text-white text-[#4D1C71] flex pl-3 w-[25%] text-details-issue `,

  hig: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  lo: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
};

const ProjectDetailsGroupSingle = () => {
  const navigate = useNavigate();
  const [projectDetails, setProjectDetails] = useState([]);
  const [Address, setAddress] = useState(0);
  const [issue, setIssue] = useState([]);
  const [barValues, setBarValues] = useState([]);
  const [issueDough, setIssueDough] = useState([]);
  const [Trailer, setTrailer] = useState([]);
  const [descStatus, setDescStatus] = useState({});
  const [scanning, setScanning] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectIcon, setProjectIcon] = useState(0);
  const [pdf, setPdf] = useState([]);
  const [Hbar, setHbar] = useState([]);
  const [icon, setIcon] = useState([]);
  const [total, setTotal] = useState(0);
  const id = sessionStorage.getItem("id");
  const name = sessionStorage.getItem("projectName");
  const iconProject = sessionStorage.getItem("icon");
  const projectDesc = sessionStorage.getItem("projectDesc");
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
  }, []);

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("GETPROJECTSDETAILSGROUP", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setProjectDetails(res.data);
        setIssue(res.data.ISSUES.ISSUES_SORT);
        setDescStatus(res.data.ISSUES.DESC_STATUS);
        setBarValues(res.data.BAR_VALUES);
        setIssueDough(res.data.ISSUES);
        setProjectIcon(res.data.ISSUES.ICON);
        setTrailer(res.data.ISSUES.TRAILER);
        setScanning(res.data.version);
        setHbar(res.data.BAR_VALUES_PROF);
        setAddress(res.data.ISSUES.ADDRESS);
        setPdf(res.data.ISSUES.PDF_REPORT);
        setIcon(res.data.icon);
        setTotal(res.data.TOTAL);
        setIsLoading(false);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const viewDetails = (id) => {
    sessionStorage.setItem("id", id);
    navigate(`/ScannReport`);
  };
  return (
    <div>
      <HeaderScanning />

      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          {id.startsWith("2") ? "Portfolio" : "Scanning"}{" "}
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">Project Details</span>
        </div>

        {/* <div className="flex items-end">
          <Link
            to={pdf}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 gap-2 text-white rounded-lg px-10 py-3 text-lg profile-button"
          >
            <MdCloudDownload className="text-2xl flex" />
            Download Report
          </Link>
        </div> */}
      </div>

      <div className="flex pl-20 gap-4 mt-3">
        <Link to={"/Portfolio"}>
          <MdKeyboardBackspace className="text-2xl" />
        </Link>
        Project Details
      </div>

      <div className="flex gap-10 px-20 py-5 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-2/3 rounded-lg project-summary-section">
          <div className="mb-5 ml-5 project-details-summary">
            Project Summary
            <div className="project-details-border"></div>
          </div>

          <div className="ml-5 project-small-title">
            <p> Project Name</p>
          </div>

          <div className="ml-5 flex mb-5">
            <h3 className="text-2xl flex flex-1 gap-2 project-details-title">
              <img
                src={iconProject !== undefined ? iconProject : ""}
                alt={name}
                className="w-8"
              />
              {name}
            </h3>
          </div>

          <div className="ml-5 project-small-title mb-2">
            <p> Project Description</p>
          </div>

          <div className="ml-5 flex mb-10 project-small-title">
            {projectDesc}
          </div>
        </div>

        <div className=" bg-white px-2 py-4 w-1/3 rounded-lg">
          <div className="project-details-summary ml-5">
            Overview
            <div className="project-details-border"></div>
          </div>

          <div className="ml-5 text-gray-500">
            <p> Project Name</p>
          </div>

          <div className="ml-5 text-violet-900 flex mb-5">
            <h3 className="text-2xl flex flex-1 gap-2 project-details-title">
              <img
                src={iconProject !== undefined ? iconProject : ""}
                alt={name}
                className="w-8"
              />
              {name}
            </h3>
          </div>

          <div className="ml-5 mb-5">
            <p className="project-small-title"> Number of Smart Contracts</p>
            <h3 className="flex flex-1 gap-2 project-details-title">
              {projectDetails.number_of_contracts}
            </h3>
          </div>

          <div className="ml-5 mb-5">
            <p className="project-small-title"> Completed Scan</p>
            <h3 className="flex flex-1 gap-2 project-details-title">
              {projectDetails.number_of_scan}
            </h3>
          </div>

          <div className="ml-5 mb-5">
            <p className="project-small-title"> Risk Level</p>
            <p
              className={`${
                projectDetails.RISK === "High"
                  ? style.high
                  : projectDetails.RISK === "Informational"
                  ? style.information
                  : projectDetails.RISK === "Medium"
                  ? style.medium
                  : projectDetails.RISK === "Low"
                  ? style.low
                  : projectDetails.RISK === "Optimization"
                  ? style.optimization
                  : ""
              }`}
            >
              {projectDetails.RISK}
            </p>
          </div>

          <div className="ml-5"></div>

          <div className="ml-5 project-small-title">
            <p>No. of Issues</p>
            <h3 className="flex flex-1 gap-2 project-details-title mb-2">
              {total !== undefined ? total : 0}
            </h3>
          </div>

          <div className="ml-5 mr-5">
            <HbBar hbar={Hbar == null ? [] : Hbar} />
          </div>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 mr-10 -mt-48 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
          <div className="flex flex-1">
            <p className="flex flex-1 items-start gap-2 pb-3 ml-5 project-details-summary">
              Smart Contract Info
            </p>
            <p>
              {/* <p
                className="mr-5 cursor-pointer report-button"
                onClick={() => viewDetails(id)}
              >
                View Contract Report
              </p> */}
            </p>
          </div>
          <div className="project-details-border -mt-2"></div>

          <div className="ml-5 project-small-title">
            <p> Project Name</p>
          </div>

          <div className="ml-5 project-details-title flex mb-5">
            <h3 className="text-2xl flex flex-1 gap-2">{name}</h3>
          </div>

          <div className="ml-5 project-small-title">
            <p> Address</p>
          </div>

          <div className="ml-5 flex mb-5">
            <p className="flex flex-1 gap-2 project-details-sub-title">
              {Address}
            </p>
            <p className="flex mr-5">
              <MdFileCopy />
            </p>
          </div>

          <div className="ml-5 project-small-title">
            <p> Version history</p>
          </div>
          <div className="project-details-sub-title ml-5 text-sm mb-5">
            <p>{projectDetails.Comp_version}</p>
          </div>

          <div className="ml-5 project-small-title">
            <p> Latest scanning time stamp</p>
          </div>
          <div className="project-details-sub-title ml-5 text-sm">
            <p>{projectDetails.CreationTime}</p>
          </div>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md -mt-2">
        <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
          <div className="flex">
            <div className="flex flex-1 ml-5 project-details-summary">
              Overall Risk
            </div>
            <div className="flex flex-1 items-start">
              {projectDetails.BAR_VALUES?.map((item, index) => (
                <div className="flex" key={index}>
                  <div
                    className={`${
                      item.TITLE === "High"
                        ? style.healthhigh
                        : item.TITLE === "Medium"
                        ? style.healthmedium
                        : item.TITLE === "Low"
                        ? style.healthlow
                        : item.TITLE === "Informational"
                        ? style.healthinformational
                        : item.TITLE === "Optimization"
                        ? style.healthoptimization
                        : ""
                    }`}
                  ></div>
                  <div className="health-title">{item.TITLE}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="project-details-border"></div>

          <div className="flex">
            <ProjectPieGroupSingle
              issueD={issueDough == null ? [] : issueDough}
            />
            <div>
              <div className="total-project-details">
                {projectDetails.TOTAL}
              </div>
            </div>
          </div>

          <div className="project-details-border w-[98%]"></div>
          <div className="flex ml-6 technical-risk-title">
            Technical Risk Item
          </div>

          <div className="flex w-full">
            <div className="flex">
              <ProjectHealth trilers={Trailer == null ? [] : Trailer} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md -mt-2">
        <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
          <div className="flex flex-1 mb-5 issue-main-title">Issue Report</div>

          <div>
            {Object.keys(issue).map((key, indexxx) => (
              <div className="mb-4 mr-10 rounded-lg">
                <div
                  key={indexxx}
                  className="text-lg cursor-pointer issue-title"
                  onClick={() => toogleAccordionxx(indexxx)}
                >
                  <div className="flex item-start">
                    <h2 className="issue-main-sub-title flex">{key}</h2>
                    <div
                      className={`${
                        descStatus[key][1] === "High"
                          ? style.hig
                          : descStatus[key][1] === "Medium"
                          ? style.med
                          : descStatus[key][1] === "Low"
                          ? style.lo
                          : descStatus[key][1] === "Informational"
                          ? style.info
                          : descStatus[key][1] === "Optimization"
                          ? style.obti
                          : ""
                      }`}
                    >
                      {descStatus
                        ? descStatus[key]
                          ? descStatus[key][1]
                          : ""
                        : ""}
                    </div>
                  </div>
                  <div className="issue-main-desc">
                    {descStatus
                      ? descStatus[key]
                        ? descStatus[key][0]
                        : ""
                      : ""}
                  </div>
                </div>
                <div className="issue-button">
                  <Link to={"/IssueDetailsGroup"}>
                    Click to view the details
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProjectDetailsGroupSingle;
