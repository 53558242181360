import React, { useState, useEffect } from "react";
import { MdApi } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import Frame34078 from "../img/Frame34078.png";
import wallet from "../svg/wallet.svg";
import github from "../svg/github.svg";
import upload from "../svg/upload.svg";
import ScanningTable from "./ScanningTable";
import MainLoader from "./MainLoader";
import { motion } from "framer-motion";
import HeadScanning from "./HeadScanning";
import Footer from "./Footer";

const Scanning = () => {
  const [Table, setTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
  }, []);

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    await axios
      .get("GETPROTFOLIO", {
        params: {
          USEID: myEmail,
        },
      })
      .then((res) => {
        setTable(res.data.TABLE);
        setIsLoading(false);
      });
  };
  return (
    <div>
      <HeadScanning />
      {isLoading && (
        <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
          <MainLoader />
        </motion.div>
      )}
      <div className="px-36 mt-10">
        <div className="py-10 px-12 text-white flex-1 flex flex-col items-start scanning-dashboard portfolio-header">
          <h3 className="text-3xl mb-8">Smart Contract Scanning</h3>
          <div className="text-sm">
            Scan your smart contracts to obtain a comprehensive report and
            identify any potential technical <br /> and business vulnerabilities
            <Link
              to={"/StartScanning"}
              className="flex flex-1 bg-white w-64 px-5 py-2 mt-10 rounded-lg text-xl scan-title"
            >
              <div className="flex rounded-lg text-center gap-2 px-5">
                <div className="pt-1">
                  <MdApi className="flex flex-1 text-xl gap-2" />
                </div>
                Start Scanning
              </div>
            </Link>
          </div>
          <div className="scan-header-img">
            <img src={Frame34078} alt="" />
          </div>
        </div>
      </div>

      <div className="px-36 mt-10 mb-10">
        <h3 className="text-xl heading-scan-title">Start Scanning</h3>
        <div className="flex gap-5">
          <div className="flex bg-white text-violet-900 w-86 px-5 py-2 mt-2 rounded-md text-lg gap-5 scan-button cursor-pointer">
            <Link to={"/StartScanning"} className="flex gap-2 pt-2 pb-2">
              <div className="pt-1">
                <img src={upload} alt="" style={{ display: "block" }} />
              </div>
              <p className="scan-file-title">Upload Contract File</p>
              <div className="flex">
                <div className="flex pl-16">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg3"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg3"
                    />
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg2"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg2"
                    />
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg1"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg1"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          </div>

          <div className="flex bg-white text-violet-900 w-86 px-5 py-4 mt-2 rounded-md text-lg scan-button cursor-pointer">
            <Link to={"/GitHubScanning"} className="flex gap-2">
              <div className="pt-1">
                <img src={github} alt="" />
              </div>
              <p className="scan-file-title">Input GitHub Repository</p>

              <div className="flex">
                <div className="flex flex-1 pl-10">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg3"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg3"
                    />
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg2"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg2"
                    />
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg1"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg1"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          </div>

          <div className="flex bg-white text-violet-900 w-86 px-5 py-2 mt-2 rounded-md text-lg scan-button cursor-pointer">
            <Link to={"/ContractScanning"} className="flex gap-4 pt-2 pb-2">
              <div className="pt-1">
                <img src={wallet} alt="" />
              </div>
              <p className="scan-file-title">Smart Contract Address</p>
              <div className="flex">
                <div className="flex flex-1 pl-10">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg3"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg3"
                    />
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg2"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg2"
                    />
                  </svg>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow-img-svg1"
                  >
                    <path
                      d="M7.125 4.5L11.625 9L7.125 13.5"
                      stroke="#654A75"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="svg1"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <h3 className="px-36 mb-5 text-xl">Scanning History</h3>
      <ScanningTable table={Table == null ? [] : Table} />

      <Footer />
    </div>
  );
};

export default Scanning;
