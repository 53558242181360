import React from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import { useSelector } from "react-redux";

const PortfolioProjects = ({}) => {
  const defaultTheme = createTheme();
  const project = useSelector((state) => state.project);
  return (
    <div>
      <h3 className="text-lg px-10 pt-10 pb-3">Projects Table</h3>
      <div className="bg-white mb-20 ml-10 mr-10 rounded-lg">
        <ThemeProvider theme={defaultTheme}>
          <MaterialTable />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default PortfolioProjects;
