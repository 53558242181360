import React from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
const defaultTheme = createTheme();

const ScanningTable = (table) => {
  const navigate = useNavigate();
  let Tables = table.table;

  let updateTable = [];
  let count = 0;
  for (var i = 0; i < Tables.length; i++) {
    if (Tables[i].CURRENTSTATUS !== "Project Added") {
      updateTable[count] = Tables[i];
      updateTable[count].sn = count + 1;
      console.log(count);
      count++;
    }
  }

  console.log(Tables);
  const viewDetails = (id) => {
    sessionStorage.setItem("id", id);
    navigate(`/ProjectDetails`);
  };

  return (
    <div className="px-36 mt-5">
      <ThemeProvider theme={defaultTheme}>
        <MaterialTable
          title="Project Table"
          columns={[
            {
              title: "#",
              field: "sn",
              cellStyle: {
                width: "5%",
              },
            },

            { title: "Project Name", field: "Projectname" },
            { title: "Vulnerabilities", field: "total" },
            {
              title: "Scanning Type",
              field: "InputType",
              cellStyle: {
                color: "#531B66",
              },
            },
            {
              title: "Status",
              field: "status",

              render: (rowData) => {
                return rowData.CURRENTSTATUS === "Scanning is in progress" ? (
                  <p
                    style={{
                      backgroundColor: "rgba(17, 120, 253, 0.1)",
                      color: "#2F72F5",
                      textAlign: "center",
                      borderRadius: "2px",
                      fontSize: "14px",
                    }}
                  >
                    <span>In Progress</span>
                  </p>
                ) : rowData.CURRENTSTATUS === "Scanning Completed" ? (
                  <p
                    style={{
                      backgroundColor: "rgba(22, 198, 131, 0.1)",
                      color: "#16C683",
                      textAlign: "center",
                      borderRadius: "2px",
                      fontSize: "14px",
                    }}
                  >
                    <span>Complete</span>
                  </p>
                ) : (
                  <p
                    style={{
                      backgroundColor: "lightviolet",
                      color: "violet",
                      textAlign: "center",
                      borderRadius: "3px",
                      fontSize: "14px",
                    }}
                  >
                    {rowData.CURRENTSTATUS}
                  </p>
                );
              },
            },
            {
              title: "Scanning Date",
              field: "CreationTime",
            },
            {
              title: "Actions",
              field: "actions",
              render: (rowData) =>
                rowData.CURRENTSTATUS === "Scanning Completed" ? (
                  <button
                    onClick={() => viewDetails(rowData.id)}
                    className="data-button"
                  >
                    View Report
                  </button>
                ) : rowData.CURRENTSTATUS === "Project Added" ? (
                  <button
                    onClick={() => viewDetails(rowData.id)}
                    className="data-button"
                  >
                    View Report
                  </button>
                ) : (
                  ""
                ),
            },
          ]}
          data={updateTable}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontFamily: "DM Sans",
              color: "#656565",
              fontSize: "14px",
            },
            showTitle: false,
          }}
          style={{
            background: "white",
            paddingRight: "20px",
            paddingLeft: "20px",
            fontFamily: "DM Sans",
            fontWeight: "500",
            color: "#281616",
            borderRadius: "16px",
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ScanningTable;
