import React from "react";
import ReactDOM from "react-dom";
import Carousel from "react-elastic-carousel";
import slider1 from "../img/slider1.png";
import slider5 from "../img/slider5.png";
import slider2 from "../img/slider2.png";
import slider3 from "../img/slider3.png";
import slider4 from "../img/slider4.png";
import Twitter from "../img/twitter@2x-1.png";
import Linkedin from "../img/linkedin@2x.png";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const TeamCarousel = () => {
  return (
    <div>
      <div className="App">
        <Carousel breakPoints={breakPoints}>
          <div className="carousel">
            <img src={slider1} alt="" className="carousel-image-center" />
            <p className="carousel-name">Zara Patel</p>
            <p className="carousel-designation">Chief Executive Officer</p>
            <div className="flex justify-center items-center gap-2 mt-3">
              <img src={Twitter} alt="" className="w-5" />
              <img src={Linkedin} alt="" className=" w-5" />
            </div>
          </div>
          <div className="carousel">
            <img src={slider2} alt="" className="carousel-image-center" />
            <p className="carousel-name">Tom Justin</p>
            <p className="carousel-designation">Art Director</p>
            <div className="flex justify-center items-center gap-2 mt-3">
              <img
                src={Twitter}
                alt=""
                className="carousel-social-center w-5"
              />
              <img
                src={Linkedin}
                alt=""
                className="carousel-social-center w-5"
              />
            </div>
          </div>
          <div className="carousel">
            <img src={slider5} alt="" className="carousel-image-center" />
            <p className="carousel-name">Mary Benepe</p>
            <p className="carousel-designation">Chief Product Officer</p>
            <div className="flex justify-center items-center gap-2 mt-3">
              <img
                src={Twitter}
                alt=""
                className="carousel-social-center w-5"
              />
              <img
                src={Linkedin}
                alt=""
                className="carousel-social-center w-5"
              />
            </div>
          </div>
          <div className="carousel">
            <img src={slider3} alt="" className="carousel-image-center" />
            <p className="carousel-name">Danny Margot</p>
            <p className="carousel-designation">Chief Technical Officer</p>
            <div className="flex justify-center items-center gap-2 mt-3">
              <img
                src={Twitter}
                alt=""
                className="carousel-social-center w-5"
              />
              <img
                src={Linkedin}
                alt=""
                className="carousel-social-center w-5"
              />
            </div>
          </div>
          <div className="carousel">
            <img src={slider4} alt="" className="carousel-image-center" />
            <p className="carousel-name">Manson Rashford</p>
            <p className="carousel-designation">Chief Financial Director</p>
            <div className="flex justify-center items-center gap-2 mt-3">
              <img
                src={Twitter}
                alt=""
                className="carousel-social-center w-5"
              />
              <img
                src={Linkedin}
                alt=""
                className="carousel-social-center w-5"
              />
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default TeamCarousel;
