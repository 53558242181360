import React, { useState, useEffect } from "react";
import HeaderPortfolio from "./HeaderPortfolio";
import Footer from "./Footer";
import axios from "axios";
import MainLoader from "./MainLoader";
import { motion } from "framer-motion";
import DataTablePortfolio from "./DataTablePortfolio";

const Portfolio = () => {
  const [statsData, setStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    console.log(myEmail);
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const myEmail = sessionStorage.getItem("email");
    await axios
      .get("getUserProfile", {
        params: {
          USEID: myEmail,
        },
      })
      .then((res) => {
        console.log(res.data);
        setStatsData(res.data.DATA);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <HeaderPortfolio />
      <div className="py-0">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
      </div>
      <div className="bg-white mb-20 ml-20 mr-20 mt-10 rounded-lg drop-shadow">
        <h3 className="mb-2 ml-5 project-details-summary pt-5">Portfolio</h3>
        <p className="dashboard-desc">
          Manage and monitor your smart contracts within your portfolio for any
          potential technical or business <br /> vulnerabilitie.
        </p>
        <DataTablePortfolio newLog={statsData == null ? [] : statsData} />
      </div>
      <Footer />
    </div>
    // Footer
  );
};

export default Portfolio;
