import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const LBR = (data) => {
  let Lbr = data.data;
  // let lbrDate = Lbr.DATE_LIST;
  // const limitedData = Lbr.slice(0, 5);

  console.log("from LBR Graph");
  console.log(Lbr);
  const chartRef = useRef(null);

  function convertArrayUTCToMonthAndYear(utcDateStrings) {
    const result = [];
    for (const utcDateString of utcDateStrings) {
      const date = new Date(utcDateString);
      if (isNaN(date)) {
        result.push("Invalid date");
      } else {
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getUTCFullYear();
        result.push(`${month} ${year}`);
      }
    }

    return result;
  }

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const xAxisData = Lbr.LBR_ETH_staked_LIST.slice(0, 12);
    const seriesData = Lbr.total_liquidity_LIST.slice(0, 12);
    echarts.registerTheme("red", {
      color: ["red"],
    });

    const options = {
      xAxis: {
        type: "category",
        data: convertArrayUTCToMonthAndYear(
          Lbr?.DATE_LIST === 0 ? [] : Lbr.DATE_LIST.slice(0, 12)
        ),
      },
      yAxis: {
        // data: seriesData,
        type: "value",
        splitLine: {
          show: false, // Set this to false to disable background grid lines
        },
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
          handleIcon: "circle",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
          height: 10, // Custom height of the dataZoom
          // bottom: 20,
          handleSize: 20, // Adjust the handle size
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
            shadowColor: "transparent",
          },
          moveHandleStyle: {
            color: "",
          },
          emphasis: {
            moveHandleStyle: {
              color: "",
            },
          },
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
          },
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
        },
      ],
      series: [
        {
          data: xAxisData,
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "#48BFE3",
          },
          itemStyle: {
            color: "#5A189A", // Change the bar color here
          },
        },
      ],
    };

    chart.setOption(options);
  }, []);

  return (
    <div>
      <div ref={chartRef} className="TokenbarGraph" />
    </div>
  );
};

export default LBR;
