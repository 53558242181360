import React, { useState, useEffect } from "react";
import Header from "./Header";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdInfo,
  MdArrowForward,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import lybra from "../img/lybrafinance@2x.png";
import ethereum from "../img/eth.png";
import copy from "../img/copy-grey.png";
import MainLoader from "./MainLoader";
import image17 from "../img/image17.png";
import NegativeLine from "../charts/NegativeLine";
import FullCircle from "../charts/FullCircle";
import ProjectBarGraph from "../charts/ProjectBarGraph";
import UnlockInformationTable from "./UnlockInformationTable";
import LbrMarketTable from "./LbrMarketTable";
import OverallRishGraph from "../charts/OverallRishGraph";
import TokenVestingChart from "../charts/TokenVestingChart";
import EbarGraph from "../charts/EbarGraph";
import EnegativeBar from "../charts/EnegativeBar";
import SemiCircle from "../charts/SemiCircle";
import privacy from "../img/privacy_tip.png";
import { Tooltip } from "react-tooltip";
import EUSDMaxSuppy from "../charts/EUSDMaxSuppy";
import EUSDTable from "./EUSDTable";
import EUSDMintGraph from "../charts/EUSDMintGraph";
import StakingPoolTable from "./StakingPoolTable";
import LBR from "../charts/LBR";
const style = {
  critical: `critical`,
  moderate: `moderate`,
  minor: `minor`,
  informational: `informational`,
  healthoptimization: `health-Opimization`,

  high: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  low: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
};

const Staking = () => {
  const [step, setStep] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [tradingVolumeGraph, setTradingVolumeGraph] = useState([]);
  const [tvlGraph, setTvlGraph] = useState([]);
  const [descStatus, setDescStatus] = useState({});
  const [overallRisk, setOverallRisk] = useState([]);
  const [mintData, setMintData] = useState([]);
  const [ethData, setEthData] = useState([]);
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);
  const [issue, setIssue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchTokenData(myEmail);
    fetchData(myEmail);
    fetchDataGraph(myEmail);
    fetchMintGraph(myEmail);
    fetchEthGraph(myEmail);
  }, []);
  const fetchTokenData = async (myEmail) => {
    axios
      .get("PROJECT_DESCRIPTION_GROUP", {
        params: {
          USEID: "pritomabhijit@gmail.com",
          PROJECT_ID: "320230810225017",
        },
      })
      .then((res) => {
        // console.log(res.data);
        setProjectDetails(res.data);
        setOverallRisk(res.data.STAKING_DATA.BAR_VALUES_PROF);

        axios
          .get("GETPROJECTSDETAILSGROUP", {
            params: {
              USEID: myEmail,
              PROJECT_ID: "20230810221652",
            },
          })
          .then((res) => {
            console.log("Issue list");
            console.log(res.data);
            setIssue(res.data.ISSUES.ISSUES_SORT);
            setDescStatus(res.data.ISSUES.DESC_STATUS);
            setIsLoading(false);
          });
      });
  };

  const fetchDataGraph = async (myEmail) => {
    axios
      .get("getHeaderForProject", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        console.log(res.data);
        setTradingVolumeGraph(res.data.TVL_GRAPH_DATA);
        setTvlGraph(res.data.TVL_GRAPH_DATA_INFLOW);
      });
  };

  const fetchMintGraph = async (myEmail) => {
    axios
      .get("getBurnMint", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("***Mint Data**");
        // console.log(res.data);
        setMintData(res.data.DUNEDATA);
      });
  };

  const fetchEthGraph = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("getDataETHRatio", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        console.log("***Eth Data**");
        console.log(res.data);
        setEthData(res.data.DUNEDATA);
        setIsLoading(false);
      });
  };

  const fetchData = async (myEmail) => {
    axios
      .get("PROJECT_DESCRIPTION", {
        params: {
          USEID: "pritomabhijit@gmail.com",
          PROJECT_ID: "20230604133059",
        },
      })
      .then((res) => {
        // console.log(res.data);
        setIssue(res.data.ISSUES.ISSUES_SORT);
        setDescStatus(res.data.ISSUES.DESC_STATUS);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  function ratingOverview() {
    setStep(1);
  }
  function projectInfo() {
    setStep(2);
  }

  return (
    <div>
      <Header />
      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          Project Dashboard
          <MdKeyboardArrowRight className="text-2xl" />
          {projectDetails.NAME}
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">Staking</span>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-full rounded-lg">
          <div className="mb-5 ml-5 flex">
            <p className="project-details-summary">Staking</p>
          </div>

          <div className="project-details-border"></div>

          <div className="ml-5 flex mb-5">
            <div className="flex gap-2 project-details-title">
              <img src={lybra} alt="" className="w-8" />
              {projectDetails.NAME && projectDetails.NAME}
            </div>

            <span className="projectlbr flex">
              <img src={ethereum} alt="" className="w-5 h-5 ml-10" />
            </span>
            <span>0xf1182229b71e79e504b1d2bf076c15a277311e05</span>
            <span>
              <img src={copy} alt="" className="ml-3" />
            </span>

            <div className="flex flex-1 justify-end project-details-supported-chain">
              Supported Chain
            </div>
          </div>

          <div className="flex">
            <div className="token-due-desc" style={{ width: "70%" }}>
              <p>{projectDetails.DESCIPTION && projectDetails.DESCIPTION}</p>
            </div>
            <div className="flex flex-1 justify-end">
              {projectDetails.CHAINLIST?.map((item, index) => (
                <div key="index">
                  {item === "Ethereum" ? (
                    <img src={ethereum} alt="" className="chain-eth -mt-5" />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* menu */}
      <div className="flex gap-10 pl-20 mr-10 drop-shadow-md">
        <ul className="flex items-center gap-8">
          <li
            className={
              step === 1 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={ratingOverview}
          >
            <Link to={""}>Smart Contract Security</Link>
            <div className={step === 1 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 2 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={projectInfo}
          >
            <Link to={""}>Staking Pool</Link>
            <div className={step === 2 ? "active" : ""}></div>
          </li>
        </ul>
      </div>
      {/* end of menu */}

      <div className={`${step === 1 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Smart Contracts Info</p>
            </div>

            <div className="project-details-border"></div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">Contract Type</p>
              <p className="token-due-smart-contract-token">Token Contract</p>
            </div>

            <div className="project-details-border"></div>

            <div className="flex">
              <div className="token-contract-title">Token Contract Address</div>
              <div className=" flex flex-1 justify-end">
                <button onClick={toggleAccordion}>
                  {isExpanded ? (
                    <MdKeyboardArrowUp className="token-contract-arrow" />
                  ) : (
                    <MdKeyboardArrowDown className="token-contract-arrow" />
                  )}
                </button>
              </div>
            </div>

            <div>
              {isExpanded && (
                <div className=" mt-5">
                  {projectDetails.TOKEN_CONTRACTS?.map((item, index) => (
                    <div key="index" className="mt-5">
                      <div className="flex">
                        <div className="flex token-contract-details">
                          {item.PROJECT_PLATFORM === "Ethereum" ? (
                            <img src={ethereum} alt="" className="w-5 h-5" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="ml-2">
                          <Link to={"/TokenContractInfo"}>
                            {item.PROJECT_NAME}
                          </Link>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="flex token-contract-address">
                          {item.PROJECT_ADDRESS}
                        </div>
                        <div className="ml-2">
                          <img
                            src={copy}
                            alt=""
                            className="mt-1 cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="project-details-border"></div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">Latest Version</p>
              <p className="token-due-smart-latest-scan">v0.5.3</p>
            </div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">
                Latest Scanning Time Stamp
              </p>
              <p className="token-due-smart-latest-scan">
                10 FEB 2023 10:00AM UTC
              </p>
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* Overall Risk */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Overall Risk</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                <div className="w-1/4">
                  <OverallRishGraph
                    issueD={overallRisk == null ? [] : overallRisk}
                  />
                </div>
                <div className="w-1/4">
                  {overallRisk.map((item, index) => (
                    <div key="index">
                      <div className="flex gap-2 mt-3">
                        <div
                          className={`${
                            item.TITLE === "High"
                              ? style.critical
                              : item.TITLE === "Medium"
                              ? style.moderate
                              : item.TITLE === "Low"
                              ? style.minor
                              : item.TITLE === "Informational"
                              ? style.informational
                              : ""
                          }`}
                        ></div>
                        <div className="critical-title">
                          {item.TITLE === "High"
                            ? "Critical"
                            : item.TITLE === "Medium"
                            ? "Moderate"
                            : item.TITLE === "Low"
                            ? "Minor"
                            : item.TITLE === "Informational"
                            ? "Informational"
                            : ""}{" "}
                          {item.TITLE !== "Optimization" ? item.value : ""}
                        </div>
                        <div className="token-diligence-info">
                          {item.TITLE !== "Optimization" ? <MdInfo /> : ""}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="flex gap-2 mt-4">
                    <div className="moderate flex"></div>
                    <div className="critical-title">Moderate (1)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <div className="minor flex"></div>
                    <div className="critical-title">Minor (7)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <div className="informational flex"></div>
                    <div className="critical-title">Informational (8)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}

        {/* Issue Report */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Issue Report</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              {Object.keys(issue).map((key, indexxx) => (
                <div className="mb-4 mr-10 rounded-lg">
                  <div
                    key={indexxx}
                    className="text-lg cursor-pointer issue-title"
                    onClick={() => toogleAccordionxx(indexxx)}
                  >
                    <div className="flex item-start">
                      <h2 className="issue-main-sub-title flex">{key}</h2>
                      <div
                        className={`${
                          descStatus[key][1] === "High"
                            ? style.high
                            : descStatus[key][1] === "Medium"
                            ? style.med
                            : descStatus[key][1] === "Low"
                            ? style.low
                            : descStatus[key][1] === "Informational"
                            ? style.info
                            : descStatus[key][1] === "Optimization"
                            ? style.obti
                            : ""
                        }`}
                      >
                        {descStatus
                          ? descStatus[key]
                            ? descStatus[key][1]
                            : ""
                          : ""}
                      </div>
                    </div>
                    <div className="issue-main-desc">
                      {descStatus
                        ? descStatus[key]
                          ? descStatus[key][0]
                          : ""
                        : ""}
                    </div>
                  </div>
                  <div className="issue-button">
                    <Link to={"/IssueDetails"}>Click to view the details</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* end of Issue Report */}
      </div>

      <div className={`${step === 2 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Staking Pool</p>
            </div>

            <div className="project-details-border"></div>

            <StakingPoolTable />
          </div>
        </div>

        {/* end of smart contract info */}

        {/* TVL Analysis */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Staked Asset Analysis</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                {mintData.length === 0 ? (
                  ""
                ) : (
                  <EUSDMintGraph data={mintData === null ? [] : mintData} />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}

        {/* TVL Analysis */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">
                LBR/ETH Percentage Composition
              </p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                {ethData.length === 0 ? "" : <LBR data={ethData} />}
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}
      </div>

      {/* End of Market prices */}
    </div>
  );
};

export default Staking;
