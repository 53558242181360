import React, { useState, useEffect } from "react";
import {
  MdFileOpen,
  MdKeyboardBackspace,
  MdKeyboardArrowRight,
  MdCloudDownload,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import ProjectPie from "./ProjectPie";
import ProjectHealth from "./ProjectHealth";
import { useNavigate } from "react-router-dom";
import HeaderScanning from "./HeaderScanning";
import MainLoader from "./MainLoader";
import HbBarReport from "./HbBarReport";
import Footer from "./Footer";
const style = {
  submitGeneral: `py-2 rounded-3xl font-bold`,
  inactiveSubmit: `w-full mb-20 text-center mt-5 rescan-button`,
  activeSubmit: `bg-[#531b66] rounded-lg w-full mb-20 text-center mt-5 text-white`,

  high: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  low: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
};

const TokenContractInfo = () => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [issue, setIssue] = useState({});
  const [ProjectName, setProjectName] = useState(true);
  const [info, setInfo] = useState([]);
  const [issueDough, setIssueDough] = useState([]);
  const [Trailer, setTrailer] = useState([]);
  const [noLine, setNoLine] = useState(0);
  const [pdf, setPdf] = useState(0);
  const [noProject, setNoProject] = useState(0);
  const [noScan, setNoScan] = useState(0);
  const [descStatus, setDescStatus] = useState({});
  const [noContracts, setNoContracts] = useState(0);
  const [totalVul, setTotalVul] = useState(0);
  const [monitored, setMonitored] = useState(0);
  const [tracked, setTracked] = useState(0);
  const [inputType, setInputType] = useState(0);
  const [InputTypePortfolio, setInputTypePortfolio] = useState(0);
  const [controlFlow, setControlFlow] = useState(0);
  const [bar, setBar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);
  const id = sessionStorage.getItem("id");

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
  }, []);

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("PROJECT_DESCRIPTION", {
        params: {
          USEID: myEmail,
          PROJECT_ID: "320230707153704",
        },
      })
      .then((res) => {
        console.log(res.data);
        setProjectDetails(res.data);
        setIssue(res.data.ISSUES.ISSUES_SORT);
        setInputTypePortfolio(res.data.ISSUES.INPUTTYPE);
        setDescStatus(res.data.ISSUES.DESC_STATUS);
        setBar(res.data.BAR_VALUES);
        setMonitored(res.data.PROJECT_MONITORED);
        setInfo(res.data.ISSUES.STATS);
        setNoLine(res.data.ISSUES.NOLINES);
        setNoProject(res.data.PROJECT_MONITORED);
        setTracked(res.data.PROJECT_TRACKED);
        setNoScan(res.data.number_of_scan);
        setNoContracts(res.data.number_of_contracts);
        setTotalVul(res.data.ISSUES.TOTAL);
        setInputType(res.data.InputType);
        setControlFlow(res.data.controlFlow);
        setIssueDough(res.data.ISSUES);
        setTrailer(res.data.ISSUES.TRAILER);
        setIsLoading(false);
        setPdf(res.data.ISSUES.PDF_REPORT);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const reScan = () => {
    setProjectName(false);
    const myEmail = sessionStorage.getItem("email");
    axios
      .get("REPORT", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res);
        setProjectName(true);
      });
  };
  return (
    <div>
      <HeaderScanning />
      <div className="flex px-15 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="w-full">
          <div className="flex px-20 pt-5">
            <div className="flex project-small-title">
              {id.startsWith("2") ? "Portfolio" : "Scanning"}{" "}
              <MdKeyboardArrowRight className="text-2xl" />
              <span className="project-small-title">Project Details</span>
              <MdKeyboardArrowRight className="text-2xl" />
              <span className="project-details-top-title">
                Smart Contract Scan Report
              </span>
            </div>
          </div>

          <div className="flex px-20 gap-4 mb-10 mt-3">
            <Link to={"/StartScanning"}>
              <MdKeyboardBackspace className="text-2xl" />
            </Link>
            Smart Contract Scan Report
          </div>

          <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md">
            <div className=" bg-white px-2 py-4 w-full rounded-lg">
              <div className="flex flex-1 mb-5 issue-main-title">
                Issue Report
              </div>

              <div>
                {Object.keys(issue).map((key, indexxx) => (
                  <div className="mb-4 mr-10 rounded-lg">
                    <div
                      key={indexxx}
                      className="text-lg cursor-pointer issue-title"
                      onClick={() => toogleAccordionxx(indexxx)}
                    >
                      <div className="flex item-start">
                        <h2 className="issue-main-sub-title flex">{key}</h2>
                        <div
                          className={`${
                            descStatus[key][1] === "High"
                              ? style.high
                              : descStatus[key][1] === "Medium"
                              ? style.med
                              : descStatus[key][1] === "Low"
                              ? style.low
                              : descStatus[key][1] === "Informational"
                              ? style.info
                              : descStatus[key][1] === "Optimization"
                              ? style.obti
                              : ""
                          }`}
                        >
                          {descStatus
                            ? descStatus[key]
                              ? descStatus[key][1]
                              : ""
                            : ""}
                        </div>
                      </div>
                      <div className="issue-main-desc">
                        {descStatus
                          ? descStatus[key]
                            ? descStatus[key][0]
                            : ""
                          : ""}
                      </div>
                    </div>
                    <div className="issue-button">
                      <Link to={"/IssueDetails"}>
                        Click to view the details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TokenContractInfo;
