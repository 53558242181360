import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeInOut } from "../animations";

const SigninInput = ({
  placeHolder,
  inputState,
  inputStateFunc,
  type,
  isSignup,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <motion.div
      {...fadeInOut}
      className="w-full py-2 border-gray-300 flex items-center gap-2"
    >
      <input
        type={type}
        placeholder={placeHolder}
        value={inputState}
        onChange={(e) => inputStateFunc(e.target.value)}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        className="w-full rounded py-2 outline-none border-none px-4 text-gray-400"
      />
    </motion.div>
  );
};

export default SigninInput;
