import React, { useState, useEffect } from "react";
import {
  MdCloudUpload,
  MdKeyboardBackspace,
  MdInfo,
  MdDocumentScanner,
  MdDeleteForever,
} from "react-icons/md";
import { GoMarkGithub } from "react-icons/go";
import { Link } from "react-router-dom";
import { HeadScanning, Overview, Spinner, Footer } from "../components";
import { FaCloudDownloadAlt } from "react-icons/fa";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../config/firebase.config";
import { addNewProject, getAllProjects } from "../api";
import { storage } from "../config/firebase.config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAllProjects } from "../context/actions/projectActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const style = {
  submitGeneral: `px-6 py-2 rounded-3xl font-bold`,
  inactiveError: `w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4`,
  activeError: `w-full py-2 border border-rose-500 focus:border-gray-50 rounded text-base text-gray-400 px-4`,
  fileNotError: `bg-white group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-7/8 md:h-340 cursor-pointer rounded-lg`,
  fileError: `bg-red-200 group flex justify-center items-center flex-col border-2 border-dotted border-red-500 w-7/8 md:h-340 cursor-pointer rounded-lg`,
};

const StartScanning = () => {
  const user = useSelector((state) => state.user);
  const [Projectname, setProjectname] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [newId, setNewId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [imageDownloadedURL, setimageDownloadedURL] = useState(null);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const [noLine, setNoLine] = useState(0);
  const [noProject, setNoProject] = useState(0);
  const [totalVul, setTotalVul] = useState(0);
  const [high, setHigh] = useState(0);
  const [highV, setHighV] = useState(0);
  const [showDiv, setShowDiv] = useState(false);
  const [project, setProject] = useState([]);
  const [ExProjectname, setExProjectname] = useState("");
  const [overview, setOverview] = useState([]);
  const [projectValue, setProjectValue] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  // const [flag, setFlag] = useState(false);
  const myEmail = sessionStorage.getItem("email");

  // useEffect(() => {
  //   axios
  //     .get("GETALLPROJECTNAME", {
  //       params: {
  //         USEID: myEmail,
  //       },
  //     })
  //     .then((res) => {
  //       setProject(res.data);
  //     });
  // }, []);

  const uploadImage = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const Dateid = Date.now();
    setNewId(Dateid);
    console.log(Dateid);

    const mail = user.email;
    setUserEmail(mail);
    console.log(mail);

    const storageRef = ref(storage, `files/${Dateid}/${imageFile.name}`);
    setFileUpload(imageFile.name);

    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        dispatch("Error");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimageDownloadedURL(downloadURL);
          setIsLoading(false);
          setProgress(null);
        });
      }
    );
  };

  const deleteImageFromFirebase = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const deleteRef = ref(storage, imageDownloadedURL);

    deleteObject(deleteRef).then(() => {
      setimageDownloadedURL(null);
      setIsLoading(null);
      dispatch("Image Removed from cloud");
    });
  };

  const submitNewProject = async (e) => {
    e.preventDefault();
    //  validate form values
    console.log(Projectname);
    console.log(myEmail);
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const data = {
        Projectname: Projectname,
        imageURL: fileUpload,
        id: newId,
        email: myEmail,
      };
      addNewProject(data).then((res) => {
        setimageDownloadedURL(null);
        setProjectname(null);
        sessionStorage.setItem("id", newId);
        navigate(`/GeneratingReport`);
      });
      await axios
        .get("REPORT", {
          params: {
            USEID: myEmail,
            PROJECT_ID: newId,
          },
        })
        .then((res) => {
          // console.log(res);
          setOverview(res.data);
          setNoLine(res.data.NOLINES);
          setNoProject(res.data.PROJECT_MONITORED);
          setTotalVul(res.data.TOTAL);
          setHigh(res.data.STATS);
          setHighV(res.data.High_VALUE);
        });

      getAllProjects().then((data) => {
        dispatch(setAllProjects);
      });
    }
  };

  useEffect(() => {
    axios
      .get("ISPROJECTNAMEEXITS", {
        params: {
          USEID: myEmail,
          TYPE: "FILE",
          PROJECT_NAME: Projectname,
        },
      })
      .then((res) => {
        console.log(res);
        setProjectValue(res.data.STATUS === true);
        console.log("***Status**");
        console.log(projectValue);
        console.log("Project Name");
        console.log(Projectname);
      });
  }, [setInterval(3000)]);

  const validateInputs = () => {
    // e.preventDefault();
    // axios
    //   .get("ISPROJECTNAMEEXITS", {
    //     params: {
    //       USEID: myEmail,
    //       TYPE: "FILE",
    //       PROJECT_NAME: Projectname,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     setProjectValue(res.data.STATUS === true);
    //     console.log("***Status**");
    //     console.log(projectValue);
    //   });

    const errors = {};
    if (Projectname === "") {
      errors.Projectname = "This field is required";
    }
    let newValue = Projectname.trim();
    if (newValue.length === 0) {
      errors.Projectname = "This field is required";
    } else if (projectValue === true) {
      errors.Projectname = "This project is already exist";
    } else if (imageDownloadedURL == null) {
      errors.uploadImage = "This field is required";
    }
    return errors;
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setShowDiv(!showDiv);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setProjectname(event.target.value);
  };

  return (
    <div>
      <HeadScanning />
      <div className="flex pl-20 pt-10">
        <div className="w-2/3">
          <div className="flex pl-20 gap-4 mb-10 text-xl">
            <Link to={"/Scanning"}>
              <MdKeyboardBackspace className="text-2xl" />
            </Link>
            Smart Contract Scanning
          </div>

          <div className="flex ml-20">
            <div className="text-gray-500 flex gap-2">
              1. Choose Scanning Type <MdInfo className="text-xl" />
            </div>
          </div>

          <div className="flex mt-2">
            <div className="flex flex-1 ml-20 gap-2">
              <Link
                to={"/StartScanning"}
                className=" w-64 text-white px-5 py-3 rounded-md flex gap-2 profile-button"
              >
                <MdCloudUpload className="text-xl" />
                Upload Contract File
              </Link>

              <Link
                to={"/GitHubScanning"}
                className="text-white w-56 bg-gray-300 px-5 py-3 rounded-md flex gap-2"
              >
                <GoMarkGithub className="flex text-xl" />
                GitHub Repository
              </Link>

              <Link
                to={"/ContractScanning"}
                className="text-white w-56 bg-gray-300 px-5 py-3 rounded-md flex gap-2"
              >
                <MdDocumentScanner className="flex text-xl" />
                Contract Address
              </Link>
            </div>
          </div>

          <div className="ml-20">
            <div>
              <div className="flex mt-5">
                <div className="text-gray-500 flex gap-2">2. Project Name</div>
              </div>

              <div className="flex gap-2">
                <Link
                  to={"/StartScanning"}
                  className="flex px-2 py-2 text-white rounded-lg profile-button"
                >
                  New Project
                </Link>

                <Link
                  to={"/StartScanningExisting"}
                  className="flex px-2 py-2 bg-gray-300 text-white rounded-lg ml-3"
                >
                  Existing Project
                </Link>
              </div>
              <div className="flex flex-1 w-7/8 mt-5">
                <input
                  type="text"
                  name={Projectname}
                  value={Projectname}
                  onChange={(e) => setProjectname(e.target.value)}
                  placeholder="Enter a project name"
                  className={`${
                    errors.Projectname ? style.activeError : style.inactiveError
                  }`}
                />
              </div>
              {errors.Projectname && (
                <div className="text-red-600">{errors.Projectname}</div>
              )}

              <div className="flex mt-5">
                <div className="text-gray-500 flex gap-2">3. Upload file</div>
              </div>

              <div
                className={`${
                  errors.uploadImage ? style.fileError : style.fileNotError
                }`}
              >
                {isLoading ? (
                  <div className="w-full h-full flex flex-col items-center justify-evenly px-24">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {!imageDownloadedURL ? (
                      <>
                        <label>
                          <div className="flex flex-col items-center justify-center h-full w-full cursor-pointer">
                            <p className="font-bold text-4xl">
                              <FaCloudDownloadAlt className="-rotate-0" />
                            </p>
                            <p className="text-lg text-textColor">
                              Click to upload a file
                            </p>
                            <div className="text-gray-500">
                              <p className="text-center text-sm mt-5">
                                You can upload upto 5 files with extension
                                ".sol"
                              </p>
                              <p className="text-center text-sm">
                                whose size must not exceed above 5 MB
                              </p>
                            </div>
                            {errors.uploadImage && (
                              <div className="text-red-600">
                                {errors.uploadImage}
                              </div>
                            )}
                          </div>

                          <input
                            type="file"
                            name="uploadImage"
                            onChange={uploadImage}
                            className="w-0 h-0"
                            accept=".sol, .zip"
                          />
                        </label>
                      </>
                    ) : (
                      <>
                        <div>
                          <div className="flex">
                            <div className="bg-gray-200 px-10 py-3 rounded-lg">
                              {fileUpload}
                            </div>

                            <button
                              type="button"
                              onClick={deleteImageFromFirebase}
                              className="w-10 h-10 bg-red-400 text-center rounded-full mt-1"
                            >
                              <MdDeleteForever className="text-white text-xl ml-2" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="flex mt-5">
                <div className="text-gray-500 flex gap-2">Remarks</div>
              </div>
              <div className="mt-2 text-gray-700 text-sm">
                <ul className="ml-10 mr-10">
                  <li className="list-disc">
                    A Maximum number of files that can be uploaded is 5 and file
                    size cannot exceed 5MB.
                  </li>
                  <li className="list-disc">
                    Files to be uploaded should be Solidity(.sol) files,
                    preferably compiled successfully. Incorrect syntax might
                    render incorrect results.
                  </li>
                </ul>
              </div>
              <div className="ml-20 mr-20 mb-20 text-white py-3 rounded-lg text-center mt-5 profile-button">
                <div className="w-7/8">
                  <button type="submit" onClick={submitNewProject}>
                    Start Scanning
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* overview div */}
        <div className="w-1/3">
          <Overview view={overview == null ? [] : overview} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StartScanning;
