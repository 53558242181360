import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainLoader from "./MainLoader";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MyModal = ({ closeModal, param, paramKey, onChildData }) => {
  const allData = param;
  const projectname = paramKey;
  const myEmail = sessionStorage.getItem("email");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const ethereum =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fethereum.png?alt=media&token=f1a3a298-1b27-43c9-b604-485e3486fde3";

  const polygon =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fpolygon.png?alt=media&token=c2461d2a-0f86-4015-a895-126412b2314d";

  const binance =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fbinance.png?alt=media&token=4075ccab-bd14-4204-bc54-ca074adf8d97";

  const avalance =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Favalanche.png?alt=media&token=c9012e03-cc2e-4779-9127-4a130b9d3af1";

  const arbitram =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Farbitrum.png?alt=media&token=781a3a89-f6d0-4d32-9cd0-71c1259c6f5b";

  const fantom =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Ffantom.png?alt=media&token=52379a3f-5a8a-4a10-8f15-8de0c30eb066";

  const optimism =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2FOptimism.png?alt=media&token=0a5dab10-4082-4d7a-900f-2bdfa84f97d9";

  const [projectId, setProjectId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const submitProject = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    console.log(onChildData);
    toast("Project added to your portfolio successfully.", {
      position: toast.POSITION.BOTTOM_CENTER,
      // autoClose: 5000,
      toastId: "success1",
      theme: "colored",
      toastClassName: "custom-toast",
    });
    setTimeout(() => {
      closeModal();
    }, 2000);
    const newId = projectId;
    await axios
      .get("ADD_IMP_PROJECT", {
        params: {
          USERID: myEmail,
          PROJECT_ID: newId,
          INPUT_PROJECT_NAME: projectname,
        },
      })
      .then((res) => {
        console.log(res);
      });
    onChildData(Math.random());
  };
  return (
    <>
      {isLoading && (
        <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
          <MainLoader />
        </motion.div>
      )}
      <div className="modal-wraper" onClick={closeModal}></div>
      <div className="modal-container">
        <div className="flex flex-1 mb-5">
          <p className="flex flex-1 items-start gap-2 pb-3 modal-project-title ">
            Add to Portfolio
          </p>
          <button onClick={closeModal}>Cancel</button>
          <button
            type="button"
            className="ml-5 project-confirm"
            disabled={isButtonDisabled}
            onClick={submitProject}
          >
            Confirm
          </button>
        </div>
        <ToastContainer limit={1} />
        <div className="modal-border-bottom-top"></div>

        <div className="modal-project-name">Project Name</div>
        <div>{allData.NAME}</div>
        <p className="modal-project-desc">{allData.DESCRIPTION}</p>
        <div className="modal-border-bottom"></div>
        <p className="modal-project-select">
          Please select the chain where the project deployed their smart
          contract
        </p>

        <div>
          {allData.CHAIN_LIST.map((item) => (
            <div className="mt-2 flex gap-2" key={item.CHAIN}>
              <input
                type={item.ISADDED === "Yes" ? "hidden" : "radio"}
                name="projectId"
                value={item.PROJECTID}
                onChange={(e) => setProjectId(e.target.value)}
                className="mt-1"
                // disabled='{item.ISADDED === "Yes" ? "yes" : "no"}'
              />

              {item.CHAIN === "Ethereum" ? (
                <span className="flex">
                  <img src={ethereum} className="w-5 mr-2" alt="Ethereum" />
                  <p>{item.CHAIN}</p>
                </span>
              ) : item.CHAIN === "Polygon" ? (
                <span className="flex">
                  <img src={polygon} className="w-5 mr-2" alt="Polygon" />
                  <p className="ml-1">{item.CHAIN}</p>
                </span>
              ) : item.CHAIN === "Binance" ? (
                <span className="flex">
                  <img src={binance} className="w-5 mr-2" alt="Binance" />
                  <p className="ml-1">{item.CHAIN}</p>
                </span>
              ) : item.CHAIN === "Avalanche" ? (
                <span className="flex">
                  <img src={avalance} className="w-5" alt="Avalanche" />
                  <p className="ml-1">{item.CHAIN}</p>
                </span>
              ) : item.CHAIN === "Arbitrum" ? (
                <span className="flex">
                  <img src={arbitram} className="w-5" alt="Arbitrum" />
                  <p className="ml-1">{item.CHAIN}</p>
                </span>
              ) : item.CHAIN === "Fantom" ? (
                <span className="flex">
                  <img src={fantom} className="w-5" alt="Fantom" />
                  <p className="ml-1">{item.CHAIN}</p>
                </span>
              ) : item.CHAIN === "Optimism" ? (
                <span className="flex">
                  <img src={optimism} className="w-5" alt="Optimism" />
                  <p className="ml-1">{item.CHAIN}</p>
                </span>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default MyModal;
