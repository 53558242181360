import React, { useState, useEffect } from "react";
import { MdOutlineCopyright, MdKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import { Footer, HeaderScanning, Overview } from "../components";

import axios from "axios";
import { useNavigate } from "react-router-dom";
const GeneratingReport = () => {
  const [status, setStatus] = useState([]);
  const navigate = useNavigate();
  const [overview, setOverview] = useState([]);
  const myEmail = sessionStorage.getItem("email");
  const id = sessionStorage.getItem("id");
  console.log(id);

  // axios
  //   .get("UPDATEPROJECTINFOREPORT", {
  //     params: {
  //       USEID: myEmail,
  //       PROJECT_ID: id,
  //     },
  //   })
  //   .then((res) => {
  //     console.log(res);
  //     setOverview(res.data);
  //     setStatus(res.data.STATUS == true);
  //     console.log(status);
  //     console.log(res.data.STATUS == true);
  //     if (status) {
  //       navigate("/ScannReport", { replace: true });
  //     }
  //   });

  useEffect(() => {
    axios
      .get("UPDATEPROJECTINFOREPORT", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res);
        setOverview(res.data);
        setStatus(res.data.STATUS == true);
        console.log(status);
        console.log(res.data.STATUS == true);
        if (status) {
          navigate("/ScannReport", { replace: true });
        }
      });
  }, []);
  return (
    <div>
      <HeaderScanning />
      <div className="flex pl-20 pt-10 h-full">
        <div className="w-2/3 mb-24">
          <div className="flex px-20 gap-4 mb-10 text-xl">
            <Link to={"/Scanning"}>
              <MdKeyboardBackspace className="text-2xl" />
            </Link>
            Smart Contract Scan Report
          </div>

          <div className="bg-white rounded-lg w-4/5 ml-20">
            <div className="pt-8 pl-5 report-title">Generating Report...</div>
            <div className="hr-report"></div>

            <div className="pl-5 pt-4 report-subtitle">
              Smart contract report is generating, please wait for few minutes.
            </div>

            <div className="report-process pl-5 pt-10 pb-10">
              {/* {overview == null ? (
                <div className="sbl-circ-path"></div>
              ) : (
                navigate("/ScannReport", { replace: true })
              )} */}
              <div className="sbl-circ-path"></div>
            </div>
            <div className="pl-5 report-details mb-10">
              You can choose to return to the dashboard, while the smart
              contract continues to upload in the background.
            </div>
            <div className="pl-5 back-dashboard-link back-dashboard">
              <Link to={"/Dashboard"}>Back to Dashboard</Link>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <Overview view={overview == null ? [] : overview} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default GeneratingReport;
