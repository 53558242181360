import React, { useState, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { motion } from "framer-motion";
import Logo from "../img/logo.png";
import Logof from "../img/logof.png";
import loginbg from "../img/loginbg.png";
import { buttonClick } from "../animations";
import { SigninInput } from "../container";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { app } from "../config/firebase.config";
import { validateUserJWTToken } from "../api";
import { useNavigate } from "react-router-dom";
import { setUserDetails } from "../context/actions/userActions";

const MainContainer = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user) {
      const email = sessionStorage.setItem("email", user.email);
      console.log(email);
      navigate("/Dashboard", { replace: true });
    }
  }, [user]);

  const loginWithGoogle = async () => {
    await signInWithPopup(firebaseAuth, provider).then((useCred) => {
      firebaseAuth.onAuthStateChanged((cred) => {
        if (cred) {
          cred.getIdToken().then((token) => {
            validateUserJWTToken(token).then((data) => {
              dispatch(setUserDetails(data));
            });
          });
        }
      });
    });
  };

  const signUpWithEmail = async () => {
    if (email === "" || password === "" || confirm_password === "") {
      //alert
    } else {
      if (password === confirm_password) {
        setUserEmail("");
        setPassword("");
        setConfirmPassword("");
        await createUserWithEmailAndPassword(
          firebaseAuth,
          email,
          password
        ).then((userCred) => {
          firebaseAuth.onAuthStateChanged((cred) => {
            if (cred) {
              cred.getIdToken().then((token) => {
                validateUserJWTToken(token).then((data) => {
                  dispatch(setUserDetails(data));
                });
                navigate("/Dashboard", { repplace: true });
              });
            }
          });
        });
      } else {
      }
    }
  };

  const signInWithEmailPass = async () => {
    if (email !== "" && password !== "") {
      await signInWithEmailAndPassword(firebaseAuth, email, password).then(
        (userCred) => {
          firebaseAuth.onAuthStateChanged((cred) => {
            if (cred) {
              cred.getIdToken().then((token) => {
                validateUserJWTToken(token).then((data) => {
                  dispatch(setUserDetails(data));
                });
                navigate("/Dashboard", { repplace: true });
              });
            }
          });
        }
      );
    }
  };
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full" id="home">
      <div className="py-2 flex-1 flex flex-col items-start login-bg h-full login">
        <img
          src={Logo}
          className="py-10 flex items-center justify-center relative ml-28"
          alt="logo"
        />
        <p className="text-lg text-white py-10 ml-28">
          Experience in Scanning Web3 Smart <br /> Contract
        </p>
        <p className="text-xs text-white ml-28 mr-10 login-details">
          A premier security monitoring platform to identify vulnerabilities and
          early <br /> signals of hacks of your smart contract.
        </p>

        <p className="text-xm text-white mt-28 ml-28 pt-64">Powered by</p>
        <img
          src={Logof}
          className="py-4 flex items-center justify-center relative ml-28"
          alt="logo"
        />
        <div className="loginbg">
          <img src={loginbg} alt="" />
        </div>
      </div>

      <div className="mt-32">
        <h3 className="flex ml-24 font-bold text-2xl">Nice to see you again</h3>
        <div className="py-2 flex-1 flex items-center relative pt-16">
          <div className="w-full flex items-center justify-center">
            <div className="w-[90%] md:w-[75%] rounded-lg p-4 flex flex-col items-center justify-center gap-4">
              <p className="font-extrabold text-1xl text-gray-600">
                {isSignUp ? "Signup" : ""}
              </p>
              <SigninInput
                placeHolder={"Enter email here"}
                inputState={email}
                inputStateFunc={setUserEmail}
                type="email"
                isSignup={isSignUp}
              />

              <SigninInput
                placeHolder={"Password here"}
                inputState={password}
                inputStateFunc={setPassword}
                type="password"
                isSignup={isSignUp}
              />

              {isSignUp && (
                <SigninInput
                  placeHolder={"Enter confirm password here"}
                  inputState={confirm_password}
                  inputStateFunc={setConfirmPassword}
                  type="password"
                  isSignup={isSignUp}
                />
              )}

              <div className="w-full flex items-center">
                {isSignUp ? (
                  <motion.button
                    {...buttonClick}
                    type="button"
                    className="w-full border-none outline-none bg-gradient-to-r from-purple-900 to-neutral-900 text-white rounded-lg py-3 text-lg"
                    onClick={signUpWithEmail}
                  >
                    Signup
                  </motion.button>
                ) : (
                  <motion.button
                    {...buttonClick}
                    type="button"
                    className="w-full border-none outline-none bg-gradient-to-r from-purple-900 to-neutral-900 text-white rounded-lg py-3 text-lg"
                    onClick={signInWithEmailPass}
                  >
                    Signin
                  </motion.button>
                )}
              </div>

              <div class="flex items-center justify-between gap-8">
                <div className="w-24 h-[1px] rounded-md bg-violet-900"></div>
                <p className="text-violet-900">or</p>
                <div className="w-24 h-[1px] rounded-md bg-violet-900"></div>
              </div>

              <div className="w-full flex items-center">
                <button
                  type="button"
                  className="w-full border-none outline-none bg-white text-purple-900 rounded-lg py-3 text-lg"
                  onClick={loginWithGoogle}
                >
                  <FcGoogle className="absolute left-56 mt-1" />
                  Sign in with Google
                </button>
              </div>

              <div className="w-full flex items-center justify-center">
                {!isSignUp ? (
                  <p className="flex">
                    Don't have an account ?{" "}
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={() => setIsSignUp(true)}
                    >
                      <p>Signup now</p>
                    </span>
                  </p>
                ) : (
                  <p className="flex">
                    Already have an account
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={() => setIsSignUp(false)}
                    >
                      <p>Signin</p>
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainContainer;
