import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const ProjectPie = (issueD) => {
  // let IssueD = issueD.issueD === [] ? [] : issueD.issueD;

  let IssueD = issueD.issueD === null ? [] : issueD.issueD;
  // console.log("*****ProjectPie");
  // console.log(IssueD);

  var arr = [];
  for (var i = 0; i < IssueD?.STATS_LIST?.length; i++) {
    // console.log(arr);
    if (IssueD?.STATS_LIST[i].name === "High") {
      arr = arr.concat(["#E42528"]);
    } else if (IssueD?.STATS_LIST[i].name === "Medium") {
      arr = arr.concat(["#2F72F5"]);
    } else if (IssueD?.STATS_LIST[i].name === "Low") {
      arr = arr.concat(["#16C683"]);
    } else if (IssueD?.STATS_LIST[i].name === "Informational") {
      arr = arr.concat(["#B7B8BD"]);
    } else if (IssueD?.STATS_LIST[i].name === "Optimization") {
      arr = arr.concat(["#3C1B66"]);
      console.log(arr);
    }
  }

  if (arr.length === 0) {
    arr = [
      "#E42528",
      "#2F72F5",
      "#16C683",
      "#B7B8BD",
      "#3C1B66",
      "rgba(255, 159, 64, 0.2)",
    ];
  }
  var data = {
    labels: IssueD?.STATS_LIST?.map((x) => x.name),
    datasets: [
      {
        label: `Issues`,
        data: IssueD?.STATS_LIST?.map((x) => x.number),
        backgroundColor: arr,
        borderColor: arr,
        borderWidth: 1,
      },
    ],
  };
  var options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 10,
        },
      },
    },
    responsive: true,
    cutout: 95,
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        ctx.font = "16px DM Sans";
        ctx.fillStyle = "#656565";
        ctx.textBaseline = "top";
        var text = "Issues",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 1.8;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <div className="pl-5">
      <Doughnut data={data} height={300} options={options} plugins={plugins} />
    </div>
  );
};

export default ProjectPie;
