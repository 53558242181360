import React, { useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";
import axios from "axios";
import { MdStarRate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import HbBar from "./HbBar";
const defaultTheme = createTheme();

const DataTableDetails = (table) => {
  const [newStar, setNewStar] = useState(false);
  const navigate = useNavigate();

  let Tables = table.table;
  console.log(Tables);

  let updateTable = [];
  let count = 0;
  for (var i = 0; i < Tables.length; i++) {
    if (Tables[i].CURRENTSTATUS === "Project Added") {
      updateTable[count] = Tables[i];
      updateTable[count].sn = count + 1;
      count++;
    }
  }

  const viewDetails = (id) => {
    sessionStorage.setItem("id", id);
    if (id.startsWith("2")) {
      navigate(`/ProjectDetails`);
    } else if (id.startsWith("3")) {
      navigate(`/ProjectDetailsGroup`);
    }
  };

  const changeStart = (id) => {
    const myEmail = sessionStorage.getItem("email");
    console.log(myEmail);
    console.log(id);

    axios
      .get("ISSTAR", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res);
      });
  };

  return (
    <div className="px-36 mt-10">
      <ThemeProvider theme={defaultTheme}>
        <MaterialTable
          // isLoading={loading}
          state={{ isLoading: true }}
          title="My Portfolio"
          columns={[
            {
              title: "#",
              render: (rowData) => {
                return rowData.Projectname.IsStar === "Yes" ? (
                  <p className="flex gap-2">
                    <div>
                      <button onClick={changeStart}>
                        <MdStarRate />
                      </button>
                    </div>

                    {rowData.sn}
                  </p>
                ) : (
                  <p className="flex gap-2">
                    <div>
                      <button onClick={(id) => changeStart(rowData.id)}>
                        <MdStarRate color={newStar ? "#F5BE2F" : "#B7B8BD"} />
                      </button>
                    </div>
                    {rowData.sn}
                  </p>
                );
              },
              cellStyle: {
                width: "5%",
              },
            },

            {
              title: "Project Name",
              render: (rowData) => {
                return (
                  <p className="flex gap-2">
                    <img
                      src={rowData.icon}
                      style={{ width: 20, borderRadius: "50%" }}
                      alt=""
                    />
                    {rowData.Projectname}
                  </p>
                );
              },
              // cellStyle: {
              //   width: "25%",
              // },
            },
            {
              title: "N. of Smart Contracts",
              field: "number_of_contracts",
              // cellStyle: {
              //   width: "10%",
              // },
            },
            {
              title: "Completed Scans",
              field: "number_of_scan",
              // cellStyle: {
              //   width: "10%",
              // },
            },
            {
              title: "Risk Level",
              field: "status",

              render: (rowData) => {
                return rowData.status === "High" ? (
                  <p
                    style={{
                      backgroundColor: "#E6D2D2",
                      color: "#E42528",
                      textAlign: "center",
                      borderRadius: "3px",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {rowData.status}
                  </p>
                ) : rowData.status === "Low" ? (
                  <p
                    style={{
                      backgroundColor: "#D0E2DC",
                      color: "#16C683",
                      textAlign: "center",
                      borderRadius: "3px",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {rowData.status}
                  </p>
                ) : rowData.status === "Medium" ? (
                  <p
                    style={{
                      backgroundColor: "#E8F2FF",
                      color: "#2F72F5",
                      textAlign: "center",
                      borderRadius: "3px",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {rowData.status}
                  </p>
                ) : rowData.status === "Scanning is in progress" ? (
                  <p
                    style={{
                      backgroundColor: "rgba(245, 190, 47, 0.1)",
                      color: "#F5BE2F",
                      textAlign: "center",
                      borderRadius: "3px",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {rowData.status}
                  </p>
                ) : rowData.status === "Informational" ? (
                  <p
                    style={{
                      backgroundColor: "#E0E0E1",
                      color: "#B7B8BD",
                      textAlign: "center",
                      borderRadius: "3px",
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {rowData.status}
                  </p>
                ) : rowData.status === "Optimization" ? (
                  <p
                    style={{
                      backgroundColor: "#D7D1DA",
                      color: "#4D1C71",
                      textAlign: "center",
                      borderRadius: "3px",
                    }}
                  >
                    {rowData.status}
                  </p>
                ) : (
                  <p
                    style={{
                      backgroundColor: "#D7D1DA",
                      color: "#4D1C71",
                      textAlign: "center",
                      borderRadius: "3px",
                    }}
                  >
                    {rowData.status}
                  </p>
                );
              },
            },
            {
              // title: "N. of Smart Contracts",
              field: "total",
              cellStyle: {
                width: "1%",
                paddingLeft: "23px",
                fontSize: "16px",
              },
            },
            {
              title: "No of Issues",
              field: "BAR_VALUES",
              render: (rowData) => {
                return <HbBar hbar={rowData.BAR_VALUES} />;
              },
              cellStyle: {
                padding: "0px",
              },
            },
            {
              // title: "Actions",
              // field: "actions",
              render: (rowData) =>
                rowData.CURRENTSTATUS === "Scanning Completed" ? (
                  <button
                    onClick={() => viewDetails(rowData.id)}
                    className="data-button"
                  >
                    View Report
                  </button>
                ) : rowData.CURRENTSTATUS === "Project Added" ? (
                  <button
                    onClick={() => viewDetails(rowData.id)}
                    className="data-button"
                  >
                    View Report
                  </button>
                ) : (
                  ""
                ),
            },
          ]}
          data={updateTable}
          // actions={[
          //   (rowData) => ({
          //     icon:
          //       rowData.CURRENTSTATUS === "Scanning Completed" ? (
          //         <button onClick={() => viewDetails(rowData.id)}>Edit</button>
          //       ) : (
          //         ""
          //       ),
          //     tooltip:
          //       rowData.CURRENTSTATUS === "Scanning Completed"
          //         ? "visibility"
          //         : "",
          //     onClick:
          //       rowData.CURRENTSTATUS === "Scanning Completed"
          //         ? (event, rowData) => viewDetails(rowData.id)
          //         : "",
          //   }),
          // ]}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontFamily: "DM Sans",
              color: "#656565",
              fontSize: "14px",
            },
            // showTitle: false,
          }}
          style={{
            background: "white",
            paddingRight: "20px",
            paddingLeft: "20px",
            fontFamily: "DM Sans",
            fontWeight: "500",
            color: "#281616",
            borderRadius: "16px",
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default DataTableDetails;
