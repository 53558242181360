import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const EUSDMaxSuppy = (data) => {
  let Data = data.data;

  // const limitedData = Data.slice(0, 5);

  const chartRef = useRef(null);

  function convertArrayUTCToMonthAndYear(utcDateStrings) {
    const result = [];
    for (const utcDateString of utcDateStrings) {
      const date = new Date(utcDateString);
      if (isNaN(date)) {
        result.push("Invalid date");
      } else {
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getUTCFullYear();
        result.push(`${month} ${year}`);
      }
    }

    return result;
  }

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const options = {
      xAxis: {
        type: "category",
        data: convertArrayUTCToMonthAndYear(
          Data.DATE_LIST === undefined ? [] : Data.DATE_LIST.slice(0, 5)
        ),
        splitLine: { show: true, interval: 6 },
        show: true,
      },
      yAxis: [
        {
          type: "value",
          name: "Bar Value",
          splitLine: { show: false },
          show: true,
        },
        {
          type: "value",
          name: "Line Value",
          splitLine: {
            show: false,
          },
          label: {
            show: false,
          },
        },
      ],
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
          handleIcon: "circle",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
          height: 10, // Custom height of the dataZoom
          // bottom: 20,
          handleSize: 20, // Adjust the handle size
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
            shadowColor: "transparent",
          },
          moveHandleStyle: {
            color: "",
          },
          emphasis: {
            moveHandleStyle: {
              color: "",
            },
          },
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
          },
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
        },
      ],
      series: [
        {
          type: "bar",
          data: Data.eusd_circulating_LIST.slice(0, 5),
          itemStyle: {
            color: "#5A189A", // Change the bar color here
          },
        },

        {
          type: "line",
          yAxisIndex: 1,
          data: Data.stETH_to_eUSD_ratio_LIST.slice(0, 5),
          lineStyle: {
            color: "#48BFE3", // Line color
            shadowColor: "#38BCD7", // Shadow color
            shadowBlur: 10, // Shadow blur radius
            shadowOffsetY: 10, // Vertical shadow offset
          },
        },
      ],
    };
    chart.setOption(options);
  }, []);

  return <div ref={chartRef} className="TokenbarGraph" />;
};

export default EUSDMaxSuppy;
