import React, { useState, useEffect } from "react";
import MyModal from "./ShowModal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  enable: `px-6 py-2 rounded-3xl font-bold`,
  disable: `w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4`,
};

const TopProject = (props) => {
  const ethereum =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fethereum.png?alt=media&token=f1a3a298-1b27-43c9-b604-485e3486fde3";

  const polygon =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fpolygon.png?alt=media&token=c2461d2a-0f86-4015-a895-126412b2314d";

  const binance =
    "https://firebasestorage.googleapis.com/v0/b/securatapp.appspot.com/o/public_images%2Fbinance.png?alt=media&token=4075ccab-bd14-4204-bc54-ca074adf8d97";

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [singleData, setSingleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState([]);
  const id = sessionStorage.getItem("id");
  const myEmail = sessionStorage.getItem("email");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const { childData, onChildData } = props;
  let Project = props.topproject;

  console.log("onChildData");
  console.log(onChildData);
  console.log(props);

  const handleModalClick = async (param, paramKey, paramChain) => {
    setSingleData(param);
    setProjectName(paramKey);
    console.log(projectName);
    if (paramChain.length === 1) {
      setButtonDisabled(true);
      toast("Project added to your portfolio successfully.", {
        position: toast.POSITION.BOTTOM_CENTER,
        // autoClose: 10000,
        theme: "colored",
        toastClassName: "custom-toast",
      });
      console.log(paramKey);
      console.log(paramChain[0].CHAIN);
      const newId = paramChain[0].PROJECTID;
      const project = paramKey;
      console.log(paramChain[0].PROJECTID);
      await axios
        .get("ADD_IMP_PROJECT", {
          params: {
            USERID: myEmail,
            PROJECT_ID: newId,
            INPUT_PROJECT_NAME: project,
          },
        })
        .then((res) => {
          console.log(res);
          setButtonDisabled(false);
        });
      onChildData(Math.random());
    } else {
      setShowModal(true);
      onChildData(Math.random());
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 px-36 py-5 top-project gap-5">
        {Object.keys(Project)?.map((key, index) => (
          <div className=" bg-white px-2 py-4 rounded-md" key={index}>
            <div className="flex flex-1 mb-5 border-b-2">
              <p className="flex flex-1 items-start gap-2 pb-3">
                <img src={Project[key].icon} className="w-5 h-5" alt="" />
                {Project[key].NAME}
              </p>
              {Project[key].ALLSELECTED === "NO" ? (
                isButtonDisabled === false ? (
                  <p
                    className="mr-5 font-extrabold cursor-pointer text-sky-400"
                    onClick={() =>
                      handleModalClick(
                        Project[key],
                        Project[key].NAME,
                        Project[key].CHAIN_LIST
                      )
                    }
                  >
                    Add to portfolio
                  </p>
                ) : (
                  <p className="mr-5 font-extrabold text-gray-400">
                    Add to portfolio
                    <ToastContainer limit={1} />
                  </p>
                )
              ) : (
                <p className="mr-5 font-extrabold text-gray-400">
                  Added to portfolio
                </p>
              )}

              {showModal && (
                <MyModal
                  closeModal={closeModal}
                  param={singleData}
                  paramKey={projectName}
                  onChildData={onChildData}
                />
              )}
            </div>

            <div className="flex flex-1 h-24">
              <p className="flex flex-1 items-start gap-2 px-5 top-project-desc">
                {Project[key].DESCRIPTION}
              </p>
            </div>

            <div className="pt-3 ml-5">
              <p className="supported-chain">Supported Chain</p>

              <div className="flex">
                {Project[key]?.CHAIN_LIST?.map((itemm) => (
                  <div className="pt-2" key={itemm.CHAIN}>
                    {itemm.CHAIN === "Ethereum" ? (
                      <img src={ethereum} className="w-5" />
                    ) : itemm.CHAIN === "Polygon" ? (
                      <img src={polygon} className="w-5" />
                    ) : itemm.CHAIN === "Binance" ? (
                      <img src={binance} className="w-5" />
                    ) : (
                      "XXXX"
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopProject;
