import React, { useState } from "react";
import { MdOutlineCopyright, MdKeyboardArrowRight } from "react-icons/md";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const [statsData, setStatsData] = useState(null);
  const user = useSelector((state) => state.user);

  return (
    <div>
      <Header />
      <div className="py-0">
        <div className="py-10 pl-60 ml-20 flex-1 flex flex-col items-start">
          <div className="flex flex-1 text-gray-400">
            <Link to={"/Dashboard"} className="flex">
              Dashboard <MdKeyboardArrowRight className="text-2xl" />
            </Link>
            <span className="text-indigo-700 ">User Profile</span>
          </div>
          <div className="mb-10 pl-10 rounded-lg text-sm bg-white pr-10 pt-3 mt-3 w-2/3">
            <div className="flex mb-5  user-profile-title">
              <div className="flex flex-1 user-profile-info">
                Basic Information
              </div>
              <div className="text-blue-500">Edit Information</div>
            </div>
            <div className="mb-5">
              <p className="text-gray-500">Full name</p>
              <p className="text-purple-900">{user && user.name}</p>
            </div>

            <div className="mb-5">
              <p className="text-gray-500">Email</p>
              <p className="text-purple-900">{user && user.email}</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="py-5 text-white bg-gradient-to-r from-purple-900 to-neutral-900">
        <p className="text-center flex fle-1 gap-2 items-center justify-center">
          <MdOutlineCopyright />
          Securat.ai | 2023
        </p>
      </div>
    </div>
  );
};

export default UserProfile;
