import React, { useState } from "react";
const ProjectHealth = (trilers) => {
  const [critical, setCritical] = useState([]);
  const id = sessionStorage.getItem("id");
  let Trailers = trilers.trilers;

  if (Trailers.length == 0) {
    const constData = [{ TAILOR_TITLE: " ", GENERALDESCRIPTION: " " }];
    Trailers = constData;
  }
  return (
    <div className="w-full pl-5 mt-5">
      {Trailers.map((item, index) => (
        <div>
          <p className="report-title">{item.TAILOR_TITLE}</p>
          <p className="mb-10 report-details mr-5">{item.ISSUEDESCRIPTION}</p>
        </div>
      ))}
    </div>
  );
};

export default ProjectHealth;
