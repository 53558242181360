import { React } from "react";
import {
  Chart as ChartJS,
  BarElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(BarElement, LinearScale, CategoryScale);
const BarChart = (issue) => {
  let Issue = issue.issue;
  if (Issue.length === 0) {
    const constData = [{ name: "No data", number: 0 }];
    Issue = constData;
  }

  var data = {
    labels: Issue?.map((x) => x.name),
    datasets: [
      {
        label: `Scan Completed`,
        data: Issue?.map((x) => x.number),
        backgroundColor: [
          "#3C1B66",
          "rgba(73, 23, 89, 0.3)",
          "#3C1B66",
          "rgba(73, 23, 89, 0.3)",
          "#3C1B66",
          "rgba(73, 23, 89, 0.3)",
        ],
        borderRadius: 50,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="bar-graph ml-10 pt-10 pb-10">
      <div className="pl-10">
        <p>Daily Traffic</p>
      </div>
      <div className="pt-10">
        <Bar data={data} height={200} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
