import React, { useState, useEffect } from "react";
import Header from "./Header";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdInfo,
  MdArrowForward,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import lybra from "../img/lybrafinance@2x.png";
import MainLoader from "./MainLoader";
import ethereum from "../img/eth.png";
import copy from "../img/copy-grey.png";
import image17 from "../img/image17.png";
import NegativeLine from "../charts/NegativeLine";
import FullCircle from "../charts/FullCircle";
import ProjectBarGraph from "../charts/ProjectBarGraph";
import UnlockInformationTable from "./UnlockInformationTable";
import LbrMarketTable from "./LbrMarketTable";
import OverallRishGraph from "../charts/OverallRishGraph";
import TokenVestingChart from "../charts/TokenVestingChart";
import EbarGraph from "../charts/EbarGraph";
import EnegativeBar from "../charts/EnegativeBar";
import WalletEntanglement from "../charts/WalletEntanglement";
import TokenUnlockCircle from "../charts/TokenUnlockCircle";
const style = {
  critical: `critical`,
  moderate: `moderate`,
  minor: `minor`,
  informational: `informational`,
  healthoptimization: `health-Opimization`,

  high: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  low: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,

  tokenAllocationMining: `token-allocation-mining`,
  tokenAllocationEco: `token-allocation-ecosystem`,
  tokenAllocationprotocol: `token-allocation-protocol`,
  tokenAllocationTeam: `token-allocation-team`,
  tokenAllocationIdo: `token-allocation-ido`,
  tokenAllocationAdvisor: `token-allocation-advisor`,
  tokenAllocationLp: `token-allocation-lp`,
  tokenAllocationWl: `token-allocation-wl`,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
};

const TokenDueDiligence = () => {
  const [step, setStep] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [tradingVolumeGraph, setTradingVolumeGraph] = useState([]);
  const [tvlGraph, setTvlGraph] = useState([]);
  const [vestingAllocation, setVestingAllocation] = useState([]);
  const [totalUnlock, setTotalUnlock] = useState([]);
  const [tokenDueId, setTokenDueId] = useState();
  const [exchangeData, setExchangeData] = useState([]);
  const [descStatus, setDescStatus] = useState({});
  const [overallRisk, setOverallRisk] = useState([]);
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);
  const [issue, setIssue] = useState([]);
  const [onlyIssue, setOnlyIssue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchTokenData(myEmail);
    // fetchData(myEmail);
    fetchDataGraph(myEmail);
    fetchVestingData(myEmail);
    fetchMarketExchangeData(myEmail);
  }, []);

  const fetchTokenData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("PROJECT_DESCRIPTION_GROUP", {
        params: {
          USEID: myEmail,
          PROJECT_ID: "320230810225017",
        },
      })
      .then((res) => {
        console.log("Project group description");
        console.log(res.data);
        setProjectDetails(res.data);
        setTokenDueId(res.data?.TOKEN_CONTRACTS[0]?.SUB_PROJECT_ID);
        // setOverallRisk(res.data.TOTAL_DATA.BAR_VALUES_PROF);
        setOverallRisk(res.data.TOKEN_DATA.BAR_VALUES_PROF);
        setIsLoading(false);

        axios
          .get("GETPROJECTSDETAILSGROUP", {
            params: {
              USEID: myEmail,
              PROJECT_ID: "20230810221652",
            },
          })
          .then((res) => {
            console.log("Issue list");
            console.log(res.data);
            setIssue(res.data.ISSUES.ISSUES_SORT);
            setOnlyIssue(res.data.ISSUES.ISSUES);
            setDescStatus(res.data.ISSUES.DESC_STATUS);
            setIsLoading(false);
          });
      });
  };

  // const fetchDataIssue = async (myEmail, tokenDueId) => {
  //   // console.log(myEmail);
  //   // console.log(tokenDueId);
  //   // setIsLoading(true);
  //   // console.log("Due token ID");
  //   // console.log(tokenDueId);
  //   axios
  //     .get("GETPROJECTSDETAILSGROUP", {
  //       params: {
  //         USEID: myEmail,
  //         PROJECT_ID: tokenDueId,
  //       },
  //     })
  //     .then((res) => {
  //       console.log("Issue list");
  //       console.log(res.data);
  //       setIssue(res.data.ISSUES.ISSUES_SORT);
  //       setOnlyIssue(res.data.ISSUES.ISSUES);
  //       setIsLoading(false);
  //     });
  // };

  const fetchDataGraph = async (myEmail) => {
    axios
      .get("getHeaderForProject", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("***12**");
        // console.log(res.data);
        setTradingVolumeGraph(res.data.TVL_GRAPH_DATA);
        setTvlGraph(res.data.TVL_GRAPH_DATA_INFLOW);
      });
  };

  // const fetchData = async (myEmail) => {
  //   axios
  //     .get("PROJECT_DESCRIPTION", {
  //       params: {
  //         USEID: "pritomabhijit@gmail.com",
  //         PROJECT_ID: "20230810221554",
  //       },
  //     })
  //     .then((res) => {
  //       // console.log(res.data);
  //       setIssue(res.data.ISSUES.ISSUES_SORT);
  //       setDescStatus(res.data.ISSUES.DESC_STATUS);
  //     });
  // };

  // TokenDueDiligence
  const fetchVestingData = async (myEmail) => {
    axios
      .get("getVestingData", {
        params: {
          USEID: "pritomabhijit@gmail.com",
          PROJECT_ID: "lybra-finance",
        },
      })
      .then((res) => {
        console.log("Vesting data");
        console.log(res.data);
        setVestingAllocation(res.data.Allocations);
        setTotalUnlock(res.data.Totalunlock);
      });
  };

  // TokenDueDiligence
  const fetchMarketExchangeData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("getMarketExchangeData", {
        params: {
          // USEID: "pritomabhijit@gmail.com",
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("Market Exchange data");
        // console.log(res.data);
        setExchangeData(res.data.EXCHANGEDATA);
        setIsLoading(false);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  function ratingOverview() {
    setStep(1);
  }
  function projectInfo() {
    setStep(2);
  }
  function latestNews() {
    setStep(3);
  }

  function audit() {
    setStep(4);
  }
  return (
    <div>
      <Header />
      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          Project Dashboard
          <MdKeyboardArrowRight className="text-2xl" />
          {projectDetails.NAME}
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">
            Invest: Token Due Diligence
          </span>
        </div>
      </div>

      <div className="flex gap-10 px-20 py-5 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-full rounded-lg">
          <div className="mb-5 ml-5 flex">
            <p className="project-details-summary">
              Invest: Token Due Diligence
            </p>
          </div>

          <div className="project-details-border"></div>

          <div className="ml-5 flex mb-5">
            <div className="flex gap-2 project-details-title">
              <img src={lybra} alt="" className="w-8" />
              {projectDetails.NAME && projectDetails.NAME}
            </div>

            <span className="projectlbr flex">
              <img src={ethereum} alt="" className="w-5 h-5 ml-10" />
            </span>
            <span>0xf1182229b71e79e504b1d2bf076c15a277311e05</span>
            <span>
              <img src={copy} alt="" className="ml-3" />
            </span>

            <div className="flex flex-1 justify-end project-details-supported-chain">
              Supported Chain
            </div>
          </div>

          <div className="flex">
            <div className="token-due-desc" style={{ width: "70%" }}>
              <p>{projectDetails.DESCIPTION && projectDetails.DESCIPTION}</p>
            </div>
            <div className="flex flex-1 justify-end">
              {projectDetails.CHAINLIST?.map((item, index) => (
                <div key="index">
                  {item === "Ethereum" ? (
                    <img src={ethereum} alt="" className="chain-eth -mt-5" />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* menu */}
      <div className="flex gap-10 pl-20 mr-10 drop-shadow-md">
        <ul className="flex items-center gap-8">
          <li
            className={
              step === 1 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={ratingOverview}
          >
            <Link to={""}>Smart Contract Security</Link>
            <div className={step === 1 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 2 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={projectInfo}
          >
            <Link to={""}>Token Metrics</Link>
            <div className={step === 2 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 3 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={latestNews}
          >
            <Link to={""}>Vesting</Link>
            <div className={step === 3 ? "active" : ""}></div>
          </li>
          <li
            className={
              step === 4 ? "menu-active-project" : "menu-deactive-project"
            }
            onClick={audit}
          >
            <Link to={""}>Market Price</Link>
            <div className={step === 4 ? "active" : ""}></div>
          </li>
        </ul>
      </div>
      {/* end of menu */}

      <div className={`${step === 1 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Smart Contracts Info</p>
            </div>

            <div className="project-details-border"></div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">Contract Type</p>
              <p className="token-due-smart-contract-token">Token Contract</p>
            </div>

            <div className="project-details-border"></div>

            <div className="flex">
              <div className="token-contract-title">Token Contract Address</div>
              <div className=" flex flex-1 justify-end">
                <button onClick={toggleAccordion}>
                  {isExpanded ? (
                    <MdKeyboardArrowUp className="token-contract-arrow" />
                  ) : (
                    <MdKeyboardArrowDown className="token-contract-arrow" />
                  )}
                </button>
              </div>
            </div>

            <div>
              {isExpanded && (
                <div className=" mt-5">
                  {projectDetails.TOKEN_CONTRACTS?.map((item, index) => (
                    <div key="index" className="mt-5">
                      <div className="flex">
                        <div className="flex token-contract-details">
                          {item.PROJECT_PLATFORM === "Ethereum" ? (
                            <img src={ethereum} alt="" className="w-5 h-5" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="ml-2">
                          <Link to={"/TokenContractInfo"}>
                            {item.PROJECT_NAME}
                          </Link>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="flex token-contract-address">
                          {item.PROJECT_ADDRESS}
                        </div>
                        <div className="ml-2">
                          <img
                            src={copy}
                            alt=""
                            className="mt-1 cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="project-details-border"></div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">Latest Version</p>
              <p className="token-due-smart-latest-scan">v0.5.3</p>
            </div>

            <div className="mt-5">
              <p className="token-due-smart-contract-type">
                Latest Scanning Time Stamp
              </p>
              <p className="token-due-smart-latest-scan">
                10 FEB 2023 10:00AM UTC
              </p>
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* Overall Risk */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Overall Risk</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                <div className="w-1/4">
                  <OverallRishGraph
                    issueD={overallRisk == null ? [] : overallRisk}
                  />
                </div>
                <div className="w-1/4">
                  {overallRisk.map((item, index) => (
                    <div key="index">
                      <div className="flex gap-2 mt-3">
                        <div
                          className={`${
                            item.TITLE === "High"
                              ? style.critical
                              : item.TITLE === "Medium"
                              ? style.moderate
                              : item.TITLE === "Low"
                              ? style.minor
                              : item.TITLE === "Informational"
                              ? style.informational
                              : ""
                          }`}
                        ></div>
                        <div className="critical-title">
                          {item.TITLE === "High"
                            ? "Critical"
                            : item.TITLE === "Medium"
                            ? "Moderate"
                            : item.TITLE === "Low"
                            ? "Minor"
                            : item.TITLE === "Informational"
                            ? "Informational"
                            : ""}{" "}
                          {item.TITLE !== "Optimization" ? item.value : ""}
                        </div>
                        <div className="token-diligence-info">
                          {item.TITLE !== "Optimization" ? <MdInfo /> : ""}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <div className="flex gap-2 mt-4">
                    <div className="moderate flex"></div>
                    <div className="critical-title">Moderate (1)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <div className="minor flex"></div>
                    <div className="critical-title">Minor (7)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div>

                  <div className="flex gap-2 mt-4">
                    <div className="informational flex"></div>
                    <div className="critical-title">Informational (8)</div>
                    <div className="token-diligence-info">
                      <MdInfo />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}

        {/* Issue Report */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Issue Report</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              {Object.keys(issue).map((key, indexxx) => (
                <div className="mb-4 mr-10 rounded-lg">
                  <div
                    key={indexxx}
                    className="text-lg cursor-pointer issue-title"
                    onClick={() => toogleAccordionxx(indexxx)}
                  >
                    <div className="flex item-start">
                      <h2 className="issue-main-sub-title flex">{key}</h2>
                      <div
                        className={`${
                          descStatus[key][1] === "High"
                            ? style.high
                            : descStatus[key][1] === "Medium"
                            ? style.med
                            : descStatus[key][1] === "Low"
                            ? style.low
                            : descStatus[key][1] === "Informational"
                            ? style.info
                            : descStatus[key][1] === "Optimization"
                            ? style.obti
                            : ""
                        }`}
                      >
                        {/* {descStatus[key][1] === "High"
                          ? "Critical"
                            ? descStatus[key][1]
                            : ""
                          : ""} */}

                        {descStatus[key][1] === "High"
                          ? "Critical"
                          : descStatus[key][1] === "Medium"
                          ? "Moderate"
                          : descStatus[key][1] === "Low"
                          ? "Minor"
                          : descStatus[key][1] === "Informational"
                          ? "Informational"
                          : descStatus[key][1] === "Optimization"
                          ? "Optimization"
                          : ""}
                      </div>
                    </div>
                    <div className="issue-main-desc">
                      {descStatus
                        ? descStatus[key]
                          ? descStatus[key][0]
                          : ""
                        : ""}
                    </div>
                  </div>
                  <div className="issue-button">
                    <Link to={"/IssueDetails"}>Click to view the details</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* end of Issue Report */}
      </div>

      <div className={`${step === 2 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">
                Average 24h Trading Volume
              </p>
            </div>

            <div className="project-details-border"></div>
            <div className="mt-5 w-full">
              {tradingVolumeGraph.length === 0 ? (
                ""
              ) : (
                <EbarGraph
                  trading={
                    tradingVolumeGraph === null ? [] : tradingVolumeGraph
                  }
                />
              )}
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* TVL Analysis */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">TVL Analysis</p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                <div>
                  {tvlGraph.length === 0 ? (
                    ""
                  ) : (
                    <EnegativeBar tvl={tvlGraph === null ? [] : tvlGraph} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of overall risk */}

        {/* Issue Report */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">
                Wallet Entanglement Analysis
              </p>
            </div>

            <div className="project-details-border"></div>

            <div>
              <div className="flex mt-5">
                <div>
                  <WalletEntanglement />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of Issue Report */}
      </div>

      <div className={`${step === 3 ? "block" : "hidden"}`}>
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Token Allocation</p>
            </div>

            <div className="project-details-border"></div>
            <div className=" px-2 py-4 rounded-lg drop-shadow-md pl-10 ml-20">
              <div className="flex mt-5">
                <div className="w-1/2 token-allocation-circle">
                  <FullCircle
                    dataAllocation={
                      vestingAllocation === null ? [] : vestingAllocation
                    }
                  />
                </div>
                <div className="w-1/2">
                  {Object.keys(vestingAllocation).map((key, index) => (
                    <div className="flex gap-2 mt-4">
                      <div className="flex w-1/2">
                        <div
                          className={`${
                            vestingAllocation[key].color === "#5A189A"
                              ? style.tokenAllocationMining
                              : vestingAllocation[key].color === "#9D4EDD"
                              ? style.tokenAllocationEco
                              : vestingAllocation[key].color === "#C77DFF"
                              ? style.tokenAllocationprotocol
                              : vestingAllocation[key].color === "#56CFE1"
                              ? style.tokenAllocationAdvisor
                              : vestingAllocation[key].color === "#5AEDC9"
                              ? style.tokenAllocationLp
                              : vestingAllocation[key].color === "#80FFDB"
                              ? style.tokenAllocationWl
                              : vestingAllocation[key].color === "#E0AAFF"
                              ? style.tokenAllocationTeam
                              : vestingAllocation[key].color === "#48BFE3"
                              ? style.tokenAllocationIdo
                              : ""
                          }`}
                        ></div>
                        <div className="token-allocation-title ml-3">
                          {vestingAllocation[key].name}
                        </div>
                      </div>
                      <div className="token-allocation-percentage -mt-1 ml-20">
                        {vestingAllocation[key].percentage}%
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* end of smart contract info */}

        {/* Token Unlock Progress */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Token Unlock Progress</p>
            </div>

            <div className="project-details-border"></div>
            <div className=" px-2 py-4 rounded-lg drop-shadow-md pl-10 ml-20">
              <div className="flex mt-5">
                <div
                  className="w-1/2 token-allocation-circle"
                  style={{ height: "300px" }}
                >
                  <TokenUnlockCircle
                    dataUnlock={totalUnlock === null ? [] : totalUnlock}
                  />
                </div>
                <div className="w-1/2">
                  {Object.keys(totalUnlock).map((key, index) => (
                    <div className="flex gap-2 mt-4">
                      <div className="flex w-1/2">
                        <div
                          className={`${
                            totalUnlock[key].color === "#5A189A"
                              ? style.tokenAllocationMining
                              : totalUnlock[key].color === "#9D4EDD"
                              ? style.tokenAllocationEco
                              : totalUnlock[key].color === "#C77DFF"
                              ? style.tokenAllocationprotocol
                              : totalUnlock[key].color === "#56CFE1"
                              ? style.tokenAllocationAdvisor
                              : totalUnlock[key].color === "#5AEDC9"
                              ? style.tokenAllocationLp
                              : totalUnlock[key].color === "#80FFDB"
                              ? style.tokenAllocationWl
                              : totalUnlock[key].color === "#E0AAFF"
                              ? style.tokenAllocationTeam
                              : totalUnlock[key].color === "#48BFE3"
                              ? style.tokenAllocationIdo
                              : ""
                          }`}
                        ></div>
                        <div className="token-allocation-title ml-3">
                          {totalUnlock[key].name}
                        </div>
                        <div className="token-unlock-value ml-2">
                          ({totalUnlock[key].percentage}%)
                        </div>
                      </div>
                      <div className="token-allocation-percentage -mt-1 ml-20">
                        {totalUnlock[key].amt}
                      </div>
                    </div>
                  ))}

                  {/* <div className="token-allocation-title">Total Locked</div>
                    <div className="token-unlock-value">(83.49%)</div>
                    <div className="token-allocation-percentage -mt-1 ml-32 token-allocation-small-value flex gap-2">
                      <img src={lybra} alt="" className="w-4 h-4" />
                      83.49m
                    </div>
                    <div className="token-allocation-lbr">LBR</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of Token Unlock Progress */}

        {/* Token vesting */}

        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Token Vesting</p>
            </div>

            <div className="project-details-border"></div>
            <div className=" px-2 py-4 rounded-lg drop-shadow-md pl-10">
              <TokenVestingChart />
            </div>
          </div>
        </div>

        {/* end of token vesting */}

        {/* Upcoming Unlock Event */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Upcoming Unlock Event</p>
              <p className="upcoming-event-date">98</p>
              <p className="upcoming-event-day">D</p>

              <p className="upcoming-event-date">20</p>
              <p className="upcoming-event-day">H</p>

              <p className="upcoming-event-date">20</p>
              <p className="upcoming-event-day">S</p>
            </div>
            <div className="project-details-border"></div>

            <div className="flex">
              <div className="upcoming-unlock-event">
                <p className="upcoming-unlock-event-day">
                  24 <br /> OCT
                </p>
              </div>
              <div className="upcoming-unlock-event-utc">10:00AM UTC</div>
            </div>

            <div className="project-alert-highlight w-full ml-5">
              <div>
                <div className="mt-5">
                  <span className="project-alert-purchased">
                    TOKEN UNLOCK to
                  </span>
                  <span className="project-alert-dollar">Team</span>
                </div>
              </div>

              <div className="project-alert-total-token">Amount</div>

              <div className="flex mt-2">
                <img src={lybra} alt="" className="w-5 h-5 flex" />
                <p className="project-alert-project-name-dollar flex">
                  0.00 <span className="project-alert-lbr">LBR</span>
                  <p className="uncoming-arrow">
                    <MdArrowForward />
                  </p>
                  <span className="project-alert-chain">
                    <img src={lybra} alt="" className="w-5 h-5 ml-3" />
                  </span>
                  <span className="project-alert-chain-cur">11.63k</span>
                  <span className="project-alert-chain-cur-platform">LBR</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* end of Upcoming Unlock Event */}

        {/* Upcoming Unlock Event */}
        <div className="gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">Unlock Information</p>
            </div>

            <div className="project-details-border"></div>
            <UnlockInformationTable />
            <div className="upcoming-unlock-event-sources">Sources</div>
            <div className="upcoming-unlock-event-link">
              <Link
                to={
                  "https://docs.lybra.finance/lybra-finance-docs/tokenomics/lbr-tokenomics/token-allocation"
                }
              >
                https://docs.lybra.finance/lybra-finance-docs/tokenomics/lbr-tokenomics/token-allocation
              </Link>
            </div>

            <div className="upcoming-unlock-event-sources mt-10">Notes</div>
            <p className="upcoming-unlock-event-desc">
              Mining Pool allocation (60%) has a dynamic unlock schedule, and
              has therefore been excluded from this analysis.
            </p>
            <div className="upcoming-unlock-event-sources mt-10">Events</div>
            <ul className="upcoming-unlock-event-desc-list">
              <li>
                A cliff of 200,000 LBR ($331,018) tokens was unlocked from
                Ecosystem Incentives on April 30, 2023 (2 months ago)
              </li>
              <li>
                Linear unlock was increased from 0 LBR ($0) to 93,910 LBR
                ($155,430) tokens per week from Ecosystem Incentives on April
                30, 2023 (2 months ago)
              </li>
              <li>
                Linear unlock was increased from 0 LBR ($0) to 95,830 LBR
                ($158,607) tokens per week from Protocol Treasury on April 30,
                2023 (2 months ago)
              </li>
              <li>
                A cliff of 5,000,000 LBR ($8,275,461) tokens was unlocked from
                IDO on April 30, 2023 (2 months ago)
              </li>
              <li>
                A cliff of 1,000,000 LBR ($1,655,092) tokens was unlocked from
                LP Reserve on April 30, 2023 (2 months ago)
              </li>
            </ul>
          </div>
        </div>
        {/* end of Upcoming Unlock Event */}
      </div>

      {/* Market Prices */}
      <div className={`${step === 4 ? "block" : "hidden"}`}>
        {/* Issue Report */}
        <div className="flex gap-10 px-20 py-5 drop-shadow-md">
          <div className=" bg-white px-2 py-4 w-full rounded-lg">
            <div className="mb-5 ml-5 flex">
              <p className="project-details-summary">LBR Market</p>
            </div>
            <div className="project-details-border"></div>

            <LbrMarketTable
              data={
                exchangeData === null
                  ? []
                  : exchangeData === undefined
                  ? []
                  : exchangeData
              }
            />
          </div>
        </div>
      </div>

      {/* End of Market prices */}
    </div>
  );
};

export default TokenDueDiligence;
