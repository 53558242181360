import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const ScanningHistory = (scanning) => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [accordion, setActiveAccordion] = useState(-1);
  const id = sessionStorage.getItem("id");

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  let Scann = scanning.scanning;
  console.log(Scann);
  return (
    <div>
      <div className="flex gap-10 px-20 py-5 mr-10 -mt-3 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-2/3 rounded-lg">
          <div className="flex flex-1 mb-5 ml-5 project-details-summary">
            Scanning History
          </div>
          <div className="project-details-border"></div>

          <div>
            <div className="ml-5 text-gray-500">
              {Scann?.map((item, index) => (
                <div className="mb-10">
                  <div key={index} onClick={() => toogleAccordion(index)}>
                    <div className={accordion === index ? "active-issue" : ""}>
                      <div className="flex flex-1 item-start">
                        <div className="flex flex-1 text-neutral-950 cursor-pointer">
                          Version V{item.VERSION}
                        </div>
                        <div className="flex text-right mr-20">
                          <MdKeyboardArrowDown />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      accordion === index ? "active-issue" : "inactive"
                    }
                  >
                    <p className="text-sm">{item.STATUS}</p>
                    <p className="text-sm">{item.time}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanningHistory;
