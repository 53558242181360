import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { ImageConfig } from "../config/ImageConfig";
import uploadImg from "../assets/cloud-upload-regular-240.png";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../config/firebase.config";
const DropFileInput = (props) => {
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];

    console.log(newFile);
    const storageRef = ref(storage, `Images/${Date.now()}-${newFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, newFile);

    // if (newFile) {
    //   const updatedList = [...fileList, newFile];
    //   setFileList(updatedList);
    //   props.onFileChange(updatedList);
    // }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  return (
    <div className="flex flex-col bg-white ml-20 mr-20 rounded-lg mt-5 items-center justify-center">
      <div
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className="drop-file-input"
      >
        <div className="flex flex-col items-center drop-file-input__label">
          <img src={uploadImg} alt="" className="w-20" />
          <p>
            Drag & drop files or <span className="text-violet-900">Browse</span>
          </p>
          <p className="px-20 text-gray-600 text-sm pt-3">
            You can upload upto 5 files with extension ".sol" whose size must
            not exceed above 5 MB
          </p>
        </div>

        <input
          type="file"
          value=""
          onChange={onFileDrop}
          className="flex items-center"
        />
      </div>

      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Ready to upload</p>
          {fileList.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <img
                src={
                  ImageConfig[item.type.split("/")[1]] || ImageConfig["default"]
                }
                alt=""
              />
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{item.size}B</p>
              </div>
              <span
                className="drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              ></span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
