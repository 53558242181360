import { React } from "react";
import {
  Chart as ChartJS,
  BarElement,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(BarElement, LinearScale, CategoryScale);
const ProjectBarGraph = (trading) => {
  let Issue = trading.trading;
  // console.log("From Tading volume");
  // console.log(Issue);

  // if (Issue.length === 0) {
  //   const constData = [{ name: "No data", number: 0 }];
  //   Issue = constData;
  // }

  var data = {
    labels: Issue?.map((x) => x.date),
    datasets: [
      {
        label: `Scan Completed`,
        data: Issue?.map((x) => x.totalLiquidityUSD),
        backgroundColor: [
          "#3C1B66",
          "rgba(73, 23, 89, 0.3)",
          "#3C1B66",
          "rgba(73, 23, 89, 0.3)",
          "#3C1B66",
          "rgba(73, 23, 89, 0.3)",
        ],
        borderRadius: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={data} height={200} options={options} />;
};

export default ProjectBarGraph;
