import React from "react";

const HoriBar = () => {
  return (
    <div>
      <div className="flex mt-10 border-b-8 rounded-sm">
        <div className="flex flex-1 text-lg">High</div>
        <div className="text-lg">20</div>
      </div>
    </div>
  );
};

export default HoriBar;
