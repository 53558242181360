import { combineReducers } from "redux";
import userReducer from "./userReducer";
import projectReducers from "./projectReducers";

const myReducers = combineReducers({
  user: userReducer,
  project: projectReducers,
});

export default myReducers;
