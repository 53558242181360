import React, { useState, useEffect } from "react";
import {
  MdFileOpen,
  MdKeyboardBackspace,
  MdKeyboardArrowRight,
  MdCloudDownload,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import ProjectPie from "./ProjectPie";
import ProjectHealth from "./ProjectHealth";
import { useNavigate } from "react-router-dom";
import HeaderScanning from "./HeaderScanning";
import MainLoader from "./MainLoader";
import HbBarReport from "./HbBarReport";
import Footer from "./Footer";
const style = {
  submitGeneral: `py-2 rounded-3xl font-bold`,
  inactiveSubmit: `w-full mb-20 text-center mt-5 rescan-button`,
  activeSubmit: `bg-[#531b66] rounded-lg w-full mb-20 text-center mt-5 text-white`,

  high: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  low: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,

  healthhigh: `health-high`,
  healthmedium: `health-medium`,
  healthlow: `health-low`,
  healthinformational: `health-informational`,
  healthoptimization: `health-Opimization`,
};

const ScannReport = () => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [issue, setIssue] = useState({});
  const [ProjectName, setProjectName] = useState(true);
  const [info, setInfo] = useState([]);
  const [issueDough, setIssueDough] = useState([]);
  const [Trailer, setTrailer] = useState([]);
  const [noLine, setNoLine] = useState(0);
  const [pdf, setPdf] = useState(0);
  const [noProject, setNoProject] = useState(0);
  const [noScan, setNoScan] = useState(0);
  const [descStatus, setDescStatus] = useState({});
  const [noContracts, setNoContracts] = useState(0);
  const [totalVul, setTotalVul] = useState(0);
  const [monitored, setMonitored] = useState(0);
  const [tracked, setTracked] = useState(0);
  const [inputType, setInputType] = useState(0);
  const [InputTypePortfolio, setInputTypePortfolio] = useState(0);
  const [controlFlow, setControlFlow] = useState(0);
  const [bar, setBar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);
  const id = sessionStorage.getItem("id");

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
  }, []);

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("PROJECT_DESCRIPTION", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setProjectDetails(res.data);
        setIssue(res.data.ISSUES.ISSUES_SORT);
        setInputTypePortfolio(res.data.ISSUES.INPUTTYPE);
        setDescStatus(res.data.ISSUES.DESC_STATUS);
        setBar(res.data.BAR_VALUES);
        setMonitored(res.data.PROJECT_MONITORED);
        setInfo(res.data.ISSUES.STATS);
        setNoLine(res.data.ISSUES.NOLINES);
        setNoProject(res.data.PROJECT_MONITORED);
        setTracked(res.data.PROJECT_TRACKED);
        setNoScan(res.data.number_of_scan);
        setNoContracts(res.data.number_of_contracts);
        setTotalVul(res.data.ISSUES.TOTAL);
        setInputType(res.data.InputType);
        setControlFlow(res.data.controlFlow);
        setIssueDough(res.data.ISSUES);
        setTrailer(res.data.ISSUES.TRAILER);
        setIsLoading(false);
        setPdf(res.data.ISSUES.PDF_REPORT);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const reScan = () => {
    setProjectName(false);
    const myEmail = sessionStorage.getItem("email");
    axios
      .get("REPORT", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res);
        setProjectName(true);
      });
  };

  return (
    <div>
      <HeaderScanning />
      <div className="flex px-15 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="w-full">
          <div className="flex px-20 pt-5">
            <div className="flex project-small-title">
              {id.startsWith("2") ? "Portfolio" : "Scanning"}{" "}
              <MdKeyboardArrowRight className="text-2xl" />
              <span className="project-small-title">Project Details</span>
              <MdKeyboardArrowRight className="text-2xl" />
              <span className="project-details-top-title">
                Smart Contract Scan Report
              </span>
            </div>
          </div>

          <div className="flex px-20 gap-4 mb-10 mt-3">
            <Link to={"/StartScanning"}>
              <MdKeyboardBackspace className="text-2xl" />
            </Link>
            Smart Contract Scan Report
          </div>

          <div className="ml-20 bg-white rounded-lg mr-32 drop-shadow-md">
            <div className="flex-col project-details-summary ml-5 mb-5 pt-5">
              Summary
              <div className="project-details-border w-[98%]"></div>
            </div>
            <div className="project-small-title ml-5">Project Name:</div>
            <div className="flex ml-5">
              <div className="flex-1 project-details-title">
                {projectDetails.Projectname}
              </div>
              <Link
                to={pdf}
                target="_blank"
                rel="noopener noreferrer"
                className="mr-5 flex gap-2 report-button"
              >
                <MdCloudDownload className="text-2xl" /> Download Report
              </Link>
            </div>

            <div className="ml-5 project-small-title mt-5">Scanning Type: </div>
            <div className="ml-5 flex small-report-title">
              <div className="flex flex-1 gap-2 mt-2">
                {InputTypePortfolio === "NA" ? "NA" : inputType}
                {/* <MdFileOpen className="text-xl" /> {inputType} */}
              </div>
            </div>

            <div className="ml-5 mt-5 project-small-title">
              No of Contracts:{" "}
            </div>
            <div className="ml-5 flex small-report-title">
              <div className="flex flex-1 gap-2 mt-2">{noContracts}</div>
            </div>

            <div className="ml-5 project-small-title mt-5">Version: </div>
            <div className="ml-5 flex small-report-title">
              <div className="flex flex-1 gap-2 mt-2 mb-10">
                {projectDetails.Comp_version}
              </div>
            </div>
          </div>

          <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md pt-10">
            <div className=" bg-white px-2 py-4 w-full rounded-lg">
              <div className="flex">
                <div className="flex flex-1 ml-5 project-details-summary">
                  Overall Risk
                </div>
                <div className="flex flex-1 items-start">
                  {projectDetails.BAR_VALUES?.map((item, index) => (
                    <div className="flex" key={index}>
                      <div
                        className={`${
                          item.TITLE === "High"
                            ? style.healthhigh
                            : item.TITLE === "Medium"
                            ? style.healthmedium
                            : item.TITLE === "Low"
                            ? style.healthlow
                            : item.TITLE === "Informational"
                            ? style.healthinformational
                            : item.TITLE === "Optimization"
                            ? style.healthoptimization
                            : ""
                        }`}
                      ></div>
                      <div className="health-title">{item.TITLE}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="project-details-border w-[98%]"></div>

              <div className="flex">
                <ProjectPie issueD={issueDough == null ? [] : issueDough} />
                <div>
                  <div className="total-project-details">
                    {totalVul !== undefined ? totalVul : 0}
                  </div>
                </div>

                <div className="pl-16 mt-10">
                  <div className="track-value">{tracked}</div>
                  <div className="scan-report-value-title mb-5">
                    Projects Tracked
                  </div>

                  <div className="track-value">{monitored}</div>
                  <div className="scan-report-value-title ">
                    Projects monitored
                  </div>
                </div>
              </div>

              <div className="project-details-border w-[98%]"></div>
              <div className="technical-risk-item pl-5">
                Technical Risk Item
              </div>
              <ProjectHealth trilers={Trailer == null ? [] : Trailer} />
            </div>
          </div>

          <div className="flex gap-10 px-20 py-5 mr-10 drop-shadow-md">
            <div className=" bg-white px-2 py-4 w-full rounded-lg">
              <div className="flex flex-1 mb-5 issue-main-title">
                Issue Report
              </div>

              <div>
                {Object.keys(issue).map((key, indexxx) => (
                  <div className="mb-4 mr-10 rounded-lg">
                    <div
                      key={indexxx}
                      className="text-lg cursor-pointer issue-title"
                      onClick={() => toogleAccordionxx(indexxx)}
                    >
                      <div className="flex item-start">
                        <h2 className="issue-main-sub-title flex">{key}</h2>
                        <div
                          className={`${
                            descStatus[key][1] === "High"
                              ? style.high
                              : descStatus[key][1] === "Medium"
                              ? style.med
                              : descStatus[key][1] === "Low"
                              ? style.low
                              : descStatus[key][1] === "Informational"
                              ? style.info
                              : descStatus[key][1] === "Optimization"
                              ? style.obti
                              : ""
                          }`}
                        >
                          {descStatus
                            ? descStatus[key]
                              ? descStatus[key][1]
                              : ""
                            : ""}
                        </div>
                      </div>
                      <div className="issue-main-desc">
                        {descStatus
                          ? descStatus[key]
                            ? descStatus[key][0]
                            : ""
                          : ""}
                      </div>
                    </div>
                    <div className="issue-button">
                      <Link to={"/IssueDetails"}>
                        Click to view the details
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/3 bg-white -mt-10 pt-10 px-10 drop-shadow overflow-hidden">
          <div className="flex">
            <div className="scan-report-title flex gap-2 mb-5">OVERVIEW</div>
          </div>
          <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-3">
            <div className="scan-report-value">{noLine}</div>
            <div className="scan-report-value-title ">
              Lines of code scanned
            </div>
          </div>
          <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-8">
            <div className="scan-report-value">{noProject}</div>
            <div className="scan-report-value-title">
              No. of Smart Contract Scanned
            </div>
          </div>
          <div className="flex mt-10">
            <div className="scan-report-title flex gap-2">ISSUES</div>
          </div>
          <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-3">
            <div className="scan-report-value">{totalVul}</div>
            <div className="scan-report-value-title">
              No. of Vulnerabilities Found
            </div>
          </div>
          <div className="pt-20">
            {bar?.map((item, index) => (
              <div key={index}>
                <div className="flex">
                  <div className="flex flex-1 hsbar-title">{item.TITLE}</div>
                  <p className="r-0 hsbar-title">{item.NO}</p>
                </div>
                <HbBarReport hbar={item.VALUES} />
              </div>
            ))}
          </div>

          <div className="mt-10">
            <button
              type="submit"
              onClick={reScan}
              disabled={!ProjectName}
              className={`${style.submitGeneral} ${
                ProjectName ? style.activeSubmit : style.inactiveSubmit
              }`}
            >
              Rescan
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScannReport;
