import React from "react";
import { Link } from "react-router-dom";
import pairStack from "../img/pairStack.png";
import Uniswap from "../img/Uniswap.png";
import pairCoin from "../img/pairCoin.png";
import curve from "../img/curve.png";

const StakingPoolTable = () => {
  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Pool
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Location
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            APR
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Boost
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Volume
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={pairStack} alt="" className="w-5 h-5 mt-1" />
              <p className="influ-name">LBR/ETH LP</p>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={Uniswap} alt="" className="w-5 h-5 mt-1" />
              <p className="influ-name">Uniswap V2</p>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">120.53%</td>
          <td className="px-6 py-4 whitespace-nowrap">None</td>
          <td className="px-6 py-4 whitespace-nowrap">$4,045,513</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link>View Staking Pool</Link>
          </td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={pairCoin} alt="" className="w-5 h-5 mt-1" />
              <p className="influ-name">eUSD/USDC</p>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={curve} alt="" className="w-5 h-5 mt-1" />
              <p className="influ-name">Curve</p>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">23.42%</td>
          <td className="px-6 py-4 whitespace-nowrap">None</td>
          <td className="px-6 py-4 whitespace-nowrap">$14,727,275.264</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link>View Staking Pool</Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default StakingPoolTable;
