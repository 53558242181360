import React from "react";
import jake from "../img/jake.png";
import ethereum from "../img/eth.png";
import download from "../img/download-blue.png";
import { Link } from "react-router-dom";

const SecurityAuditTable = (AuditData) => {
  let data = AuditData.AuditData;
  // console.log("From Security Table");
  // console.log(data);

  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Auditor
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Issues
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Chain
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Version
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Revision Date
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Download
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex">
                <img src={jake} alt="" />
                <p className="influ-name">{item.Auditor}</p>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.Issues}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex">
                <img src={ethereum} alt="" className="w-5 h-5 mt-2" />
                <p className="influ-name">{item.Chain}</p>
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">{item.version}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.RevisionDate}</td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex">
                <img src={download} alt="" className="w-5 h-5 mt-2" />
                <Link to={item.Link}>
                  <p className="influ-name">download</p>
                </Link>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SecurityAuditTable;
