import React from "react";

const UnlockInformationTable = () => {
  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Allocation
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            %
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            TGE
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Vesting
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">Mining Pool</td>
          <td className="px-6 py-4 whitespace-nowrap">60%</td>
          <td className="px-6 py-4 whitespace-nowrap">0%</td>
          <td className="px-6 py-4 whitespace-nowrap">
            0% at TGE. Dynamically emitted over 2 years based on mining
            contracts.
          </td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">Team</td>
          <td className="px-6 py-4 whitespace-nowrap">8.5%</td>
          <td className="px-6 py-4 whitespace-nowrap">0%</td>
          <td className="px-6 py-4 whitespace-nowrap">
            A 6-month cliff, then linearly vesting over the subsequent 2 years.
          </td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">Ecosystem Incentives</td>
          <td className="px-6 py-4 whitespace-nowrap">10%</td>
          <td className="px-6 py-4 whitespace-nowrap">2%</td>
          <td className="px-6 py-4 whitespace-nowrap">
            2% unlocked at TGE, then linearly vesting over 2 years.
          </td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">Protocol Treasury</td>
          <td className="px-6 py-4 whitespace-nowrap">10%</td>
          <td className="px-6 py-4 whitespace-nowrap">0%</td>
          <td className="px-6 py-4 whitespace-nowrap">
            0% at TGE. Linearly vesting over 2 years after TGE.
          </td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">IDO</td>
          <td className="px-6 py-4 whitespace-nowrap">5%</td>
          <td className="px-6 py-4 whitespace-nowrap">100%</td>
          <td className="px-6 py-4 whitespace-nowrap">
            Fully unlocked at TGE. The WL bounses will be distributed in the
            form of esLBR (0.5% of total supply).
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default UnlockInformationTable;
