import React, { useState } from "react";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "../config/firebase.config";

import { useStateValue } from "../context/StateProvider";

const Welcome = () => {
  return (
    <div className="py-0">
      <div className="py-10 px-20 text-white flex-1 flex flex-col items-start bg-main">
        <div className="mb-8">
          <h3 className="heading">Welcome back👋</h3>
        </div>

        <div className="mb-10 text-sm">
          A premier security monitoring platform to identify vulnerabilities and
          early signals of hacks of your smart contract.
        </div>

        <div className="flex flex-1 gap-4 ml-4 mb-3">
          <div className="flex">
            <p className="border-b-2">All</p>
          </div>
          <div className="flex">
            <p>Monthly</p>
          </div>
          <div className="flex">
            <p>Weekly</p>
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="bg-sub-black rounded-md py-4 px-10">
            <p>Total no of Projects</p>
            <h3 className="text-2xl">
              525 <span className="text-teal-300 text-bold text-sm">+12%</span>
            </h3>
          </div>

          <div className="bg-sub-black rounded-md py-4 px-10">
            <p>Total no of Smart Contracts</p>
            <h3 className="text-2xl">
              21 <span className="text-pink-600 text-bold text-sm">-20%</span>
            </h3>
          </div>

          <div className="bg-sub-black rounded-md py-4 px-10">
            <p>No. of Scans Completed</p>
            <h3 className="text-2xl">
              987 <span className="text-teal-300 text-bold text-sm">+6.5%</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
