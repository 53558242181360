import React from "react";
import jake from "../img/jake.png";

const InfluencersTable = () => {
  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            #
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Influencer
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Posts
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Folloers
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Weighted Avg
          </th>
          <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Engagement
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">1</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={jake} alt="" />
              <p className="influ-name">JAKE</p>
            </div>
            <p className="influ-username">@JakeGagain</p>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">25</td>
          <td className="px-6 py-4 whitespace-nowrap">63</td>
          <td className="px-6 py-4 whitespace-nowrap">12</td>
          <td className="px-6 py-4 whitespace-nowrap">55</td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">2</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={jake} alt="" />
              <p className="influ-name">JAKE</p>
            </div>
            <p className="influ-username">@JakeGagain</p>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">25</td>
          <td className="px-6 py-4 whitespace-nowrap">63</td>
          <td className="px-6 py-4 whitespace-nowrap">12</td>
          <td className="px-6 py-4 whitespace-nowrap">55</td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">3</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={jake} alt="" />
              <p className="influ-name">JAKE</p>
            </div>
            <p className="influ-username">@JakeGagain</p>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">25</td>
          <td className="px-6 py-4 whitespace-nowrap">63</td>
          <td className="px-6 py-4 whitespace-nowrap">12</td>
          <td className="px-6 py-4 whitespace-nowrap">55</td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">4</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={jake} alt="" />
              <p className="influ-name">JAKE</p>
            </div>
            <p className="influ-username">@JakeGagain</p>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">25</td>
          <td className="px-6 py-4 whitespace-nowrap">63</td>
          <td className="px-6 py-4 whitespace-nowrap">12</td>
          <td className="px-6 py-4 whitespace-nowrap">55</td>
        </tr>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">5</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex">
              <img src={jake} alt="" />
              <p className="influ-name">JAKE</p>
            </div>
            <p className="influ-username">@JakeGagain</p>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">25</td>
          <td className="px-6 py-4 whitespace-nowrap">63</td>
          <td className="px-6 py-4 whitespace-nowrap">12</td>
          <td className="px-6 py-4 whitespace-nowrap">55</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InfluencersTable;
