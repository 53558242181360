import axios from "axios";

//export const baseURL = "http://127.0.0.1:5001/securat-8b754/us-central1/app";
export const baseURL = "https://beta.securat.ai/firebase";

export const validateUserJWTToken = async (token) => {
  try {
    const res = await axios.get(`${baseURL}/api/users/jwtverification`, {
      headers: { Authorization: "Bearer " + token },
    });
    return res.data.data;
  } catch (err) {
    return null;
  }
};

//Add new project
export const addNewProject = async (data) => {
  try {
    const res = await axios.post(`${baseURL}/api/project/create`, { ...data });
    return res.data.data;
  } catch (err) {
    return null;
  }
};

//Add new Contract
export const addContractProject = async (data) => {
  try {
    const res = await axios.post(`${baseURL}/api/project/createContract`, {
      ...data,
    });
    return res.data.data;
  } catch (err) {
    return null;
  }
};

//Add new GitLink
export const addGitProject = async (data) => {
  try {
    const res = await axios.post(`${baseURL}/api/project/createGit`, {
      ...data,
    });
    return res.data.data;
  } catch (err) {
    return null;
  }
};

// get all the projects
export const getAllProjects = async () => {
  try {
    const res = await axios.get(`${baseURL}/api/project/all`);
    return res.data.data;
  } catch (err) {
    return null;
  }
};
