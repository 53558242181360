import React, { useState, useEffect } from "react";
import {
  MdFileOpen,
  MdKeyboardBackspace,
  MdKeyboardArrowRight,
  MdCloudDownload,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import ProjectPie from "./ProjectPie";
import ProjectHealth from "./ProjectHealth";
import { useNavigate } from "react-router-dom";
import HeaderScanning from "./HeaderScanning";
import MainLoader from "./MainLoader";
import HbBarReport from "./HbBarReport";
import Footer from "./Footer";
const style = {
  submitGeneral: `px-6 py-2 rounded-3xl font-bold`,
  inactiveSubmit: `bg-[#95999e] rounded-sm w-2/3 mb-20 text-center mt-5 ml-20 text-violet`,
  activeSubmit: `bg-[#531b66] rounded-sm w-2/3 mb-20 text-center mt-5 ml-20 text-white`,

  high: `bg-[#E6D2D2] rounded-sm text-[#E42528] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  med: `bg-[#E8F2FF] rounded-sm text-[#2F72F5] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  low: `bg-[#D0E2DC] rounded-sm text-[#16C683] text-center flex  ml-5 pl-2 pr-2 text-details-issue`,
  info: `bg-[#E0E0E1] rounded-sm text-[#5A5A5A] text-center flex ml-5 pl-2 pr-2 text-details-issue `,
  obti: `bg-[#D7D1DA] rounded-sm text-[#4D1C71] flex ml-5 pl-2 pr-2 text-details-issue `,
};

const IssueDetailsGroup = () => {
  const navigate = useNavigate();
  const [projectDetails, setProjectDetails] = useState([]);
  const [issue, setIssue] = useState({});
  const [ProjectName, setProjectName] = useState(true);
  const [info, setInfo] = useState([]);
  const [issueDough, setIssueDough] = useState([]);
  const [Trailer, setTrailer] = useState([]);
  const [noLine, setNoLine] = useState(0);
  const [pdf, setPdf] = useState(0);
  const [noProject, setNoProject] = useState(0);
  const [noScan, setNoScan] = useState(0);
  const [descStatus, setDescStatus] = useState({});
  const [noContracts, setNoContracts] = useState(0);
  const [totalVul, setTotalVul] = useState(0);
  const [monitored, setMonitored] = useState(0);
  const [inputType, setInputType] = useState(0);
  const [bar, setBar] = useState([]);
  const [controlFlow, setControlFlow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionx, setActiveAccordionx] = useState(-1);
  const [accordionxx, setActiveAccordionxx] = useState(-1);
  const id = sessionStorage.getItem("id");

  useEffect(() => {
    const myEmail = sessionStorage.getItem("email");
    fetchData(myEmail);
  }, []);

  const fetchData = async (myEmail) => {
    setIsLoading(true);
    axios
      .get("GETPROJECTSDETAILSGROUP", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setProjectDetails(res.data);
        setIssue(res.data.ISSUES.ISSUES_SORT);
        setDescStatus(res.data.ISSUES.DESC_STATUS);
        setMonitored(res.data.PROJECT_MONITORED);
        setInfo(res.data.STATS);
        setBar(res.data.BAR_VALUES);
        setNoLine(res.data.ISSUES.NOLINES);
        setNoProject(res.data.ISSUES.PROJECT_MONITORED);
        setNoScan(res.data.number_of_scan);
        setNoContracts(res.data.number_of_contracts);
        setTotalVul(res.data.ISSUES.TOTAL);
        setInputType(res.data.INPUTTYPE);
        setControlFlow(res.data.controlFlow);
        setIssueDough(res.data.ISSUES);
        setTrailer(res.data.ISSUES.TRAILER);
        setIsLoading(false);
        setPdf(res.data.ISSUES.PDF_REPORT);
      });
  };

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  function toogleAccordionx(indexx) {
    if (indexx === accordionxx) {
      setActiveAccordionx(-1);
      return;
    }
    setActiveAccordionx(indexx);
  }

  function toogleAccordionxx(indexxx) {
    if (indexxx === accordionxx) {
      setActiveAccordionxx(-1);
      return;
    }
    setActiveAccordionxx(indexxx);
  }

  const reScan = () => {
    setProjectName(false);
    const myEmail = sessionStorage.getItem("email");
    axios
      .get("REPORT", {
        params: {
          USEID: myEmail,
          PROJECT_ID: id,
        },
      })
      .then((res) => {
        console.log(res);
        setProjectName(true);
      });
  };
  return (
    <div>
      <HeaderScanning />
      <div className="flex px-15 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="w-full">
          <div className="flex px-20 pt-5">
            <div className="flex text-gray-400">
              {id.startsWith("2") ? "Portfolio" : "Scanning"}{" "}
              <MdKeyboardArrowRight className="text-2xl" />{" "}
              <div>Project Details</div>{" "}
              <MdKeyboardArrowRight className="text-2xl" />
              <span>Smart Contract Scan Report</span>
              <MdKeyboardArrowRight className="text-2xl" />
              <span className="text-violet-900">Issues</span>
            </div>
          </div>

          <div className="flex gap-10 py-5 mr-10">
            <div className="px-2 py-4 w-full rounded-lg">
              <div>
                {Object.keys(issue).map((key, indexxx) => (
                  <div className="p-2 mb-4 ml-20 mr-10 rounded-lg bg-white">
                    <div
                      key={indexxx}
                      className="text-lg cursor-pointer issue-title"
                      onClick={() => toogleAccordionxx(indexxx)}
                    >
                      <h2
                        className={
                          accordionxx === indexxx ? "active-issue" : ""
                        }
                      >
                        <div className="flex item-start">
                          <div className="flex cursor-pointer issue-details-title">
                            {key}
                          </div>
                          <div
                            className={`${
                              descStatus[key][1] === "High"
                                ? style.high
                                : descStatus[key][1] === "Medium"
                                ? style.med
                                : descStatus[key][1] === "Low"
                                ? style.low
                                : descStatus[key][1] === "Informational"
                                ? style.info
                                : descStatus[key][1] === "Optimization"
                                ? style.obti
                                : ""
                            }`}
                          >
                            {descStatus
                              ? descStatus[key]
                                ? descStatus[key][1]
                                : ""
                              : ""}
                          </div>
                          <div className="flex ml-auto text-right mr-20">
                            <MdKeyboardArrowDown />
                          </div>
                        </div>
                      </h2>
                    </div>

                    <div
                      className={
                        accordionxx === indexxx ? "active-issue" : "inactive"
                      }
                    >
                      {Object.keys(issue[key]).map((keyy, indexx) => (
                        <div className="p-2 mb-4 mr-10 rounded-lg">
                          <div
                            key={indexx}
                            className="text-lg cursor-pointer issue-title"
                            onClick={() => toogleAccordionx(indexx)}
                          >
                            <h2
                              className={
                                accordionx === indexx ? "active-issue" : ""
                              }
                            >
                              <div className="flex flex-1 item-start">
                                <div className="flex flex-1 cursor-pointer issue-details-sub-title">
                                  {keyy} ({issue[key][keyy].length} )
                                </div>
                              </div>
                              <div className="issue-details-desc-title">
                                {issue[key][keyy][0].BUSINESS_MESSAGE}
                              </div>
                            </h2>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/3 bg-white -mt-10 pt-10 px-10 drop-shadow overflow-hidden">
          <div className="flex">
            <div className="scan-report-title flex gap-2 mb-5">OVERVIEW</div>
          </div>
          <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-3">
            <div className="scan-report-value">{noLine}</div>
            <div className="scan-report-value-title ">
              Lines of code scanned
            </div>
          </div>
          <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-8">
            <div className="scan-report-value">{noScan}</div>
            <div className="scan-report-value-title">
              No. of Smart Contract Scanned
            </div>
          </div>
          <div className="flex mt-10">
            <div className="scan-report-title flex gap-2">ISSUES</div>
          </div>
          <div className="flex-col bg-gray-200 rounded-lg px-5 py-3 mt-3">
            <div className="scan-report-value">{totalVul}</div>
            <div className="scan-report-value-title">Total issues found</div>
          </div>
          <div className="pt-10">
            {bar?.map((item, index) => (
              <div key={index}>
                <div className="flex">
                  <div className="flex flex-1 hsbar-title">{item.TITLE}</div>
                  <p className="r-0 hsbar-title">{item.NO}</p>
                </div>
                <HbBarReport hbar={item.VALUES} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default IssueDetailsGroup;
