import React, { useState, useEffect } from "react";
import axios from "axios";
import { MdKeyboardArrowRight, MdKeyboardBackspace } from "react-icons/md";
import { motion } from "framer-motion";

import logoDashboard from "../img/logoDashboard.png";
import { Link } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import Header from "./Header";

const Scanning = () => {
  const [issue, setIssue] = useState([]);

  const [accordion, setActiveAccordion] = useState(-1);

  function toogleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  useEffect(() => {
    axios.get("/issues").then((res) => setIssue(res.data));
  }, []);

  return (
    <div>
      <Header />
      <div className="flex flex-1 px-20 pt-10">
        <div className="flex flex-1 text-gray-400">
          Scanning <MdKeyboardArrowRight className="text-2xl" />
          <span className="text-indigo-700 ">Scanning Details</span>
        </div>
        <div className="flex items-end">
          <button className="bg-gradient-to-r from-purple-900 to-neutral-900 text-white rounded-lg px-10 py-3 text-lg">
            Download Report
          </button>
        </div>
      </div>

      <div className="flex px-20 gap-4">
        <Link to={"/Portfolio"}>
          <MdKeyboardBackspace className="text-2xl" />
        </Link>
        Project Details
      </div>

      <div>
        {issue.map((item, index) => (
          <div className="bg-fuchsia-300 p-5 mb-4 ml-10 mr-10 rounded-lg">
            <div
              key={index}
              className="text-lg text-center cursor-pointer"
              onClick={() => toogleAccordion(index)}
            >
              <h2 className={accordion === index ? "active" : ""}>
                {item.ISSUETITLE}
              </h2>
            </div>

            <div className={accordion === index ? "active" : "inactive"}>
              <div className="bg-white text-black rounded-lg mb-3 px-10 py-5 text-center">
                {item.ISSUEDESCRIPTION}
              </div>

              <div className="bg-white text-black rounded-lg mb-3 px-10 py-5 text-center">
                {item.GENERALDESCRIPTION}
              </div>

              <div className="bg-red-300 text-red-800 rounded-lg mb-3 px-10 py-5 text-center">
                {item.IMPACT}
              </div>

              <div className="bg-white text-black rounded-lg mb-3 px-10 py-5 text-center">
                {item.RECOMMENDATION}
              </div>

              <div className="bg-white text-black rounded-lg mb-3 px-10 py-5 text-center">
                {item.MARKDOWN}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Scanning;
