import React, { useState } from "react";
import {
  MdCloudUpload,
  MdKeyboardBackspace,
  MdInfo,
  MdDocumentScanner,
} from "react-icons/md";
import { GoMarkGithub } from "react-icons/go";
import { app } from "../config/firebase.config";
import { addContractProject } from "../api";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Footer, HeadScanning, Overview, Spinner } from "../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const style = {
  inactiveError: `w-full outline-none py-2 border-none rounded text-base text-gray-400 px-4`,
  activeError: `w-full py-2 border border-rose-500 focus:border-gray-50 rounded text-base text-gray-400 px-4`,
};

const ContractScanning = () => {
  const user = useSelector((state) => state.user);
  const [Network, setNetwork] = useState(null);
  const [Address, setAddress] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [Platform, setPlatform] = useState("");
  const [newId, setNewId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [noLine, setNoLine] = useState(0);
  const [noProject, setNoProject] = useState(0);
  const [totalVul, setTotalVul] = useState(0);
  const [high, setHigh] = useState(0);
  const [overview, setOverview] = useState([]);
  const [projectValue, setProjectValue] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const myEmail = sessionStorage.getItem("email");

  const submitContractAddress = (e) => {
    e.preventDefault();

    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const Dateid = Date.now();
      setNewId(Dateid);
      const mail = user.email;
      setUserEmail(mail);

      const data = {
        Address: Address,
        ProjectName: ProjectName,
        Platform: Platform,
        id: Dateid,
        email: myEmail,
      };

      addContractProject(data).then((res) => {
        setAddress(null);
        setProjectName("");
        sessionStorage.setItem("id", Dateid);
        navigate(`/GeneratingReport`);
      });

      axios
        .get("REPORT", {
          params: {
            USEID: myEmail,
            PROJECT_ID: Dateid,
          },
        })
        .then((res) => {
          console.log(res);
          setNoLine(res.data.NOLINES);
          setNoProject(res.data.PROJECT_MONITORED);
          setTotalVul(res.data.TOTAL);
          setHigh(res.data.STATS);
          setOverview(res.data);
        });
    }
  };

  function isAlphanumeric(str) {
    return /^[a-zA-Z0-9]{42,42}$/.test(str);
  }

  const validateInputs = () => {
    axios
      .get("ISPROJECTNAMEEXITS", {
        params: {
          USEID: myEmail,
          TYPE: "ADDRESS",
          PROJECT_NAME: ProjectName,
        },
      })
      .then((res) => {
        console.log(res);
        setProjectValue(res.data.STATUS);
        console.log(projectValue);
      });

    const errors = {};
    let newValue = ProjectName.trim();
    let newAddress = Address.trim();
    let newPlatform = Platform.trim();
    if (Address == "") {
      errors.Address = "This field is required";
    } else if (newAddress.length === 0) {
      errors.Address = "This field is required";
    } else if (!isAlphanumeric(Address)) {
      errors.Address = "Invalid Address";
    } else if (ProjectName == "") {
      errors.ProjectName = "This field is required";
    } else if (projectValue === true) {
      errors.ProjectName = "This project is already exist";
    } else if (newValue.length === 0) {
      errors.ProjectName = "This field is required";
    } else if (Platform == "") {
      errors.Platform = "This field is required";
    } else if (newPlatform.length === 0) {
      errors.Platform = "This field is required";
    }
    return errors;
  };

  const handleChange = (event) => {
    event.preventDefault();
    setPlatform(event.target.value);
  };

  return (
    <div>
      <HeadScanning />
      <div className="flex pl-20 pt-10">
        <div className="w-2/3">
          <div className="flex pl-20 gap-4 mb-10 text-xl">
            <Link to={"/Scanning"}>
              <MdKeyboardBackspace className="text-2xl" />
            </Link>
            Smart Contract Scanning
          </div>

          <div className="flex ml-20">
            <div className="text-gray-500 flex gap-2">
              1. Choose Scanning Type <MdInfo className="text-xl" />
            </div>
          </div>

          <div className="flex mt-2">
            <div className="flex flex-1 ml-20 gap-2">
              <Link
                to={"/StartScanning"}
                className="bg-gray-300 w-64 text-white px-5 py-3 rounded-md flex gap-2"
              >
                <MdCloudUpload className="text-xl" />
                Upload Contract File
              </Link>

              <Link
                to={"/GitHubScanning"}
                className="bg-gray-300 text-white w-56  px-5 py-3 rounded-md flex gap-2"
              >
                <GoMarkGithub className="flex text-xl" />
                GitHub Repository
              </Link>

              <Link
                to={"/ContractScanning"}
                className=" text-white w-56 px-5 py-3 rounded-md flex gap-2 profile-button"
              >
                <MdDocumentScanner className="flex text-xl" />
                Contract Address
              </Link>
            </div>
          </div>

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">
              2. Enter Contract Address
            </div>
          </div>

          <div className="flex flex-1 w-7/8 ml-20 mt-5">
            <input
              type="text"
              value={Address}
              name="Address"
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Contract Address"
              className={`${
                errors.Address ? style.activeError : style.inactiveError
              }`}
            />
          </div>
          {errors.Address && (
            <div className="text-red-600 ml-20">{errors.Address}</div>
          )}

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">
              3. Enter Project Name
            </div>
          </div>

          <div className=" ml-20 flex gap-2">
            <Link
              to={"/ContractScanning"}
              className="flex px-2 py-2 text-white rounded-lg profile-button"
            >
              New Project
            </Link>

            <Link
              to={"/ContractScanningEx"}
              className="flex px-2 py-2 bg-gray-300 text-white rounded-lg ml-3"
            >
              Existing Project
            </Link>
          </div>

          <div className="flex flex-1 w-7/8 ml-20 mt-5">
            <input
              type="text"
              value={ProjectName}
              name="ProjectName"
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Project Name"
              className={`${
                errors.ProjectName ? style.activeError : style.inactiveError
              }`}
            />
          </div>
          {errors.ProjectName && (
            <div className="text-red-600 ml-20">{errors.ProjectName}</div>
          )}

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">
              4. Select Contract Platform
            </div>
          </div>

          <div className="flex flex-1 w-7/8 ml-20 mt-5">
            <select
              value={Platform}
              name="Platform"
              onChange={handleChange}
              className={`${
                errors.Platform ? style.activeError : style.inactiveError
              }`}
            >
              <option value="">--Select Platform--</option>
              <option value="Arbitrum">Arbitrum</option>
              <option value="ArbitrumTestnet">Arbitrum Testnet</option>
              <option value="Avalanche">Avalanche</option>
              <option value="AvalancheTestnet">Avalanche Testnet</option>
              <option value="Binance">Binance</option>
              <option value="BinanceTestnet">Binance Testnet</option>
              <option value="Ethereum">Ethereum</option>
              <option value="Fantom">Fantom</option>
              <option value="GoerliTestnet">Goerli Testnet</option>
              <option value="KovanTestnet">Kovan Testnet</option>
              <option value="MumbaiTestnet">Mumbai Testnet</option>
              <option value="Optimism">Optimism</option>
              <option value="Polygon">Polygon</option>
              <option value="RopstenTestnet">Ropsten Testnet</option>
              <option value="SepoliaTestnet">Sepolia Testnet</option>
              <option value="Tobalaba">Tobalaba</option>
            </select>
          </div>
          {errors.Platform && (
            <div className="text-red-600 ml-20">{errors.Platform}</div>
          )}

          <div className="flex ml-20 mt-5">
            <div className="text-gray-500 flex gap-2">Remarks</div>
          </div>
          <div className="ml-20 mt-2 text-gray-700 text-sm">
            <ul className="ml-10 mr-10">
              <li className="list-disc">
                Navigate to the explorer of the particular blockchain (Ethereum
                - Etherscan.io).
              </li>
              <li className="list-disc">
                Use the search bar to get your smart contract and check if the
                source code is verified in the "Contract" tab of the selected
                explorer.
              </li>
            </ul>
          </div>
          <div className="ml-20 mr-20 mb-20 text-white py-3 rounded-lg text-center mt-5 profile-button">
            <button onClick={submitContractAddress}>Start Scanning</button>
          </div>
        </div>

        {/* overview div */}
        <div className="w-1/3">
          <Overview view={overview == null ? [] : overview} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default ContractScanning;
