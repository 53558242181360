import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const EUSDMintGraph = (data) => {
  let MintData = data.data;
  console.log("Mint Grapg");
  console.log(MintData);

  // const limitedData = MintData.slice(0, 5);
  // console.log(limitedData);

  // const MintDate = MintData.DATE_LIST;

  // console.log("from mint Graph");
  // console.log(MintData);

  function convertArrayUTCToMonthAndYear(utcDateStrings) {
    const result = [];
    for (const utcDateString of utcDateStrings) {
      const date = new Date(utcDateString);
      if (isNaN(date)) {
        result.push("Invalid date");
      } else {
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getUTCFullYear();
        result.push(`${month} ${year}`);
      }
    }

    return result;
  }

  const chartRef = useRef(null);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const xAxisData = convertArrayUTCToMonthAndYear(
      MintData?.DATE_LIST === undefined ? [] : MintData.DATE_LIST.slice(0, 5)
    );
    const seriesData1 = MintData?.BURNT_LIST.slice(0, 5);
    const seriesData2 = MintData?.MINT_LIST.slice(0, 5);

    const options = {
      xAxis: {
        type: "category",
        data: xAxisData,
        splitLine: { show: true, interval: 6 },
        show: true,
      },
      yAxis: {
        type: "value",
        splitLine: { show: false },
        show: true,
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
          handleIcon: "circle",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
          height: 10, // Custom height of the dataZoom
          // bottom: 20,
          handleSize: 20, // Adjust the handle size
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
            shadowColor: "transparent",
          },
          moveHandleStyle: {
            color: "",
          },
          emphasis: {
            moveHandleStyle: {
              color: "",
            },
          },
        },
        {
          type: "inside",
          xAxisIndex: 0,
          filterMode: "none",
          handleStyle: {
            color: "rgba(101, 54, 163, 1)", // Customize handle color
          },
        },
        {
          type: "inside",
          yAxisIndex: 0,
          filterMode: "none",
          fillerColor: "rgba(101, 54, 163, 1)", // Custom filler color
        },
      ],
      series: [
        {
          type: "line",
          name: "Line 1",
          data: seriesData1,
          symbol: "rect", // Set the symbol to circle (you can use other symbols)
          symbolSize: 8,
          itemStyle: {
            color: "rgba(101, 54, 163, 1)", // Change the color of the symbols
          },
        },
        {
          type: "line",
          name: "Line 2",
          data: seriesData2,
          lineStyle: {
            color: "#48BFE3", // Line color
            shadowColor: "#38BCD7", // Shadow color
            shadowBlur: 10, // Shadow blur radius
            shadowOffsetY: 10, // Vertical shadow offset
          },
          symbol: "rect", // Set the symbol to circle (you can use other symbols)
          symbolSize: 8,
          itemStyle: {
            color: "#38BCD7", // Change the color of the symbols
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#d7f1f7", // Start color
                },
                {
                  offset: 1,
                  color: "#ffffff", // End color
                },
              ],
            },
          },
        },
      ],
    };

    chart.setOption(options);
  }, []);

  return (
    <div>
      <div ref={chartRef} className="TokenbarGraph" />
    </div>
  );
};

export default EUSDMintGraph;
