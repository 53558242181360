import React from "react";
import footerLogo from "../img/footerLogo.png";
import copyright from "../img/copyright.png";
export const Footer = () => {
  return (
    <div className="py-5 text-white bg-gradient-to-r footer">
      <p className="text-center flex gap-2 items-center justify-center">
        <img src={footerLogo} alt="" />
        Securat.ai
      </p>
      <p className="text-center flex items-center footer-caption justify-center gap-2">
        <img src={copyright} alt="" className="copyright" /> 2023 Securat.ai |
        Powered by Securat.ai
      </p>
    </div>
  );
};
export default Footer;
