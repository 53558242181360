import React, { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { motion } from "framer-motion";
import axios from "axios";
import MainLoader from "./MainLoader";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

const style = {
  critical: `critical`,
  moderate: `moderate`,
  minor: `minor`,
  informational: `informational`,
  healthoptimization: `health-Opimization`,
};

const ViewAllProjectAlert = () => {
  const [coinDetails, setCoinDetails] = useState([]);
  const [news, setNews] = useState([]);
  const [latestNewsUpdate, setLatestNewsUpdate] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchNewsData();
    fetchLatestNewsData();
    fetchSecurityData();
  }, []);

  const fetchNewsData = async () => {
    setIsLoading(true);
    axios
      .get("getLastestSpotonchain", {
        params: {
          PAGE_NUMBER: "20",
        },
      })
      .then((res) => {
        console.log("From news page");
        console.log(res.data);
        setNews(res.data);
        setIsLoading(false);
      });
  };

  const fetchLatestNewsData = async () => {
    axios
      .get("getCoinNewsData", {
        params: {
          COIN_NAME: "web3 and crypto",
        },
      })
      .then((res) => {
        // console.log("From Latest news page");
        // console.log(res.data);
        setLatestNewsUpdate(res.data.results);
      });
  };

  const fetchSecurityData = async () => {
    axios
      .get("getAuditReport", {
        params: {
          PROJECTID: "lybra-finance",
        },
      })
      .then((res) => {
        // console.log("From Audit page");
        // console.log(res.data);
        setAuditData(res.data);
        setIsLoading(false);
      });
  };

  function convertTimestampToMonthAndYear(timestamp) {
    const dateObject = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    // Get the month as a string (e.g., "January", "February", etc.)
    const month = dateObject.toLocaleString("default", { month: "long" });

    // Get the year as a 4-digit number
    const year = dateObject.getFullYear();

    // Format the result as "Month Year" (e.g., "August 2023")
    const formattedDate = `${month} ${year}`;

    return formattedDate;
  }

  function convertStringToObject(inputString) {
    try {
      return JSON.parse(inputString);
    } catch (error) {
      console.error("Error converting string to object:", error);
      return null; // Return null in case of an error
    }
  }

  return (
    <div>
      <Header />

      <div className="flex flex-1 px-20 pt-10">
        {isLoading && (
          <motion.div className="fixed z-50 inset-0 bg-lightOverlay backdrop-blur-md flex items-center justify-center w-full">
            <MainLoader />
          </motion.div>
        )}
        <div className="flex flex-1 text-gray-400">
          Project Dashboard
          <MdKeyboardArrowRight className="text-2xl" />
          <span className="project-details-top-title">
            <Link to={"/ProjectDetails"}>Project Details</Link>
          </span>
        </div>
      </div>

      {/* 5th section */}
      <div className="flex gap-10 px-20 py-5 drop-shadow-md">
        <div className=" bg-white px-2 py-4 w-full rounded-lg">
          <div className="mb-5 ml-5 flex">
            <p className="project-details-summary">Project Alert</p>
          </div>
          <div className="project-details-border"></div>

          {/* news start from here */}

          {Object.keys(news).map((key, index) => (
            <div className="flex mb-10" key={index}>
              <div className="w-40">
                <p className="project-alert-date">
                  {news[key].publish_time &&
                    // new Date(news[key].update_time).getFullYear()}
                    convertTimestampToMonthAndYear(news[key].publish_time)}
                  {/* 18 JUN 2023 */}
                </p>
              </div>
              <div className="vertifcal-line"></div>
              <div className="w-full">
                <div className="pl-10">
                  <div className="project-alert-title">
                    {news[key].title && news[key].title}
                  </div>
                  <div className="project-alert-desc">
                    {/* {Object(news[key].content).map((keyy, indexx) => (
                        <div key={indexx}>
                          <p>{keyy}</p>
                        </div>
                      ))} */}

                    {/* {convertStringToObject(news[key].content).map(
                        (itemss) => (
                          <p>{itemss.children.map((hello) => hello.text)}</p>
                        )
                      )} */}

                    {convertStringToObject(news[key].description).map(
                      (itemss) => (
                        <p>{itemss.children.map((hello) => hello.text)}</p>
                      )
                    )}

                    {/* {JSON.stringify(news[key].content)} */}
                  </div>

                  <div className="project-alert-highlight w-full">
                    <div className="flex mt-2">
                      <div className="flex flex-1 project-alert-highlight-title">
                        Highlight
                      </div>
                      <div className="project-alert-datedetails">
                        {/* 18 JUN 2023 10:00AM UTC */}
                        {news[key].update_time &&
                          convertTimestampToMonthAndYear(news[key].update_time)}
                      </div>
                    </div>

                    <div>
                      <div className="mt-5">
                        <span className="project-alert-purchased">
                          {news[key].txn_data_details.title_prefix
                            ? news[key].txn_data_details.title_prefix
                            : ""}
                        </span>
                        <span className="project-alert-dollar">
                          {news[key].txn_data_details.title
                            ? news[key].txn_data_details.title
                            : ""}
                        </span>
                      </div>
                    </div>

                    <div className="project-alert-total-token">
                      Total token Transfter
                    </div>

                    <div className="flex mt-2 gap-10">
                      {/* {news[key].txn_data_details.token_amounts.length} */}
                      {news[key].txn_data_details.token_amounts.length >= 1
                        ? news[key].txn_data_details.token_amounts.map(
                            (items) => (
                              <div className="flex">
                                <img
                                  src={items.url}
                                  alt=""
                                  className="w-5 h-5 flex"
                                />
                                <p className="project-alert-project-name-dollar flex">
                                  {items.amount}
                                  <span className="project-alert-lbr ml-2">
                                    {items.token}
                                  </span>
                                </p>
                              </div>
                            )
                          )
                        : "Can not iterate"}
                    </div>
                  </div>

                  {news[key].tags.map((newsItm) => (
                    <div className="flex">
                      <div className="flex project-alert-invest">
                        <p className="flex flex-1">{newsItm.tag}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {/* end of one news */}
        </div>
      </div>
      {/* end of 5th section */}

      <Footer />
    </div>
  );
};

export default ViewAllProjectAlert;
